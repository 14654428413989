import React from "react";
import { IconsProps } from "./Icons";

export const ClockIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}:IconsProps) => {
  return (
    <svg      
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 25 24"}
      width={width ? width : "25"}
      height={height ? height : "24"}
      fill={fill ? fill : "none"}
    >
      <path d="M12.5 20.5C17.1944 20.5 21 16.6944 21 12C21 7.30558 17.1944 3.5 12.5 3.5C7.80558 3.5 4 7.30558 4 12C4 16.6944 7.80558 20.5 12.5 20.5Z" stroke="#FDA860"/>
      <path d="M12.5 7V12L15.3 14.8" stroke="#FDA860" strokeLinecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
