import React from 'react';
import { IconsProps } from './Icons';

export const SpeakerTableIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 164 77'}
      width={width ? width : '164'}
      height={height ? height : '77'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M78.584 76.7317C91.4596 76.7317 101.897 74.3627 101.897 71.4404C101.897 68.5181 91.4596 66.1491 78.584 66.1491C65.7083 66.1491 55.2705 68.5181 55.2705 71.4404C55.2705 74.3627 65.7083 76.7317 78.584 76.7317Z"
        fill="#263238"
      />
      <path
        opacity="0.3"
        d="M78.584 74.9737C91.4596 74.9737 101.897 72.9982 101.897 70.5614C101.897 68.1245 91.4596 66.1491 78.584 66.1491C65.7083 66.1491 55.2705 68.1245 55.2705 70.5614C55.2705 72.9982 65.7083 74.9737 78.584 74.9737Z"
        fill="white"
      />
      <path
        d="M80.5072 70.5559H76.6608L72.209 27.9801H84.959L80.5072 70.5559Z"
        fill="#263238"
      />
      <path
        d="M84.959 27.9801C84.959 29.22 82.1045 30.2254 78.584 30.2254C75.0636 30.2254 72.209 29.22 72.209 27.9801C72.209 26.7402 75.0629 25.7348 78.584 25.7348C82.1051 25.7348 84.959 26.7402 84.959 27.9801Z"
        fill="#263238"
      />
      <path
        opacity="0.7"
        d="M84.959 27.9801C84.959 29.22 82.1045 30.2254 78.584 30.2254C75.0636 30.2254 72.209 29.22 72.209 27.9801C72.209 26.7402 75.0629 25.7348 78.584 25.7348C82.1051 25.7348 84.959 26.7402 84.959 27.9801Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M0.65332 28.6457C0.65332 28.1954 0.903539 27.7513 1.39703 27.3133C6.78369 32.1247 40.9594 35.8319 82.3151 35.8319C123.671 35.8319 157.847 32.1247 163.24 27.3133C163.734 27.7513 163.984 28.1954 163.984 28.6457C163.984 34.0801 127.417 38.4905 82.3151 38.4905C37.2131 38.4905 0.65332 34.0801 0.65332 28.6457Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M1.39746 29.8738C6.78412 25.0624 40.9599 21.3613 82.3155 21.3613C123.671 21.3613 157.847 25.0624 163.241 29.8738C157.847 34.6851 123.671 38.3924 82.3155 38.3924C40.9599 38.3924 6.78412 34.6851 1.39746 29.8738Z"
        fill="white"
      />
      <path
        opacity="0.7"
        d="M0.65332 28.4544C0.65332 23.0139 37.2131 18.6096 82.3151 18.6096C127.417 18.6096 163.984 23.0139 163.984 28.4544C163.984 28.9047 163.734 29.3489 163.24 29.7806C157.847 24.9693 123.671 21.2682 82.3151 21.2682C40.9594 21.2682 6.78369 24.9693 1.39703 29.7806C0.903539 29.3489 0.65332 28.9047 0.65332 28.4544Z"
        fill="white"
      />
      <path
        d="M113.814 23.6603L79.6478 23.0885C79.2948 23.0824 79.014 22.7727 79.0237 22.3995C79.0334 22.0263 79.3302 21.7259 79.6833 21.7321L113.849 22.3039C114.202 22.3101 114.483 22.6197 114.473 22.9929C114.464 23.3661 114.167 23.6665 113.814 23.6603Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M113.814 23.6603L79.6478 23.0885C79.2948 23.0824 79.014 22.7727 79.0237 22.3995C79.0334 22.0263 79.3302 21.7259 79.6833 21.7321L113.849 22.3039C114.202 22.3101 114.483 22.6197 114.473 22.9929C114.464 23.3661 114.167 23.6665 113.814 23.6603Z"
        fill="white"
      />
      <path
        d="M130.409 21.4477L114.074 23.6388C113.723 23.6856 113.402 23.4216 113.363 23.0509C113.322 22.6808 113.577 22.3397 113.927 22.2928L130.263 20.1012C130.614 20.0543 130.934 20.3183 130.974 20.6884C131.014 21.0585 130.76 21.4008 130.409 21.4477Z"
        fill="#263238"
      />
      <path
        d="M130.263 20.1017H95.7282L79.6836 21.7314L112.918 22.2878L130.263 20.1017Z"
        fill="#263238"
      />
      <path
        opacity="0.6"
        d="M130.263 20.1017H95.7282L79.6836 21.7314L112.918 22.2878L130.263 20.1017Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M130.409 21.4477L114.074 23.6388C113.723 23.6856 113.402 23.4216 113.363 23.0509C113.322 22.6808 113.577 22.3397 113.927 22.2928L130.263 20.1012C130.614 20.0543 130.934 20.3183 130.974 20.6884C131.014 21.0585 130.76 21.4008 130.409 21.4477Z"
        fill="white"
      />
      <path
        d="M112.918 22.2878L81.0599 21.7543C79.9353 21.7358 78.9609 20.747 78.8941 19.5571L77.9343 2.48716C77.8676 1.29789 78.7322 0.339313 79.8568 0.358435L111.715 0.892008C112.839 0.91113 113.814 1.89993 113.881 3.0892L114.841 20.1591C114.908 21.349 114.043 22.3069 112.918 22.2878Z"
        fill="#407BFF"
      />
      <path
        opacity="0.2"
        d="M112.918 22.2878L81.0599 21.7543C79.9353 21.7358 78.9609 20.747 78.8941 19.5571L77.9343 2.48716C77.8676 1.29789 78.7322 0.339313 79.8568 0.358435L111.715 0.892008C112.839 0.91113 113.814 1.89993 113.881 3.0892L114.841 20.1591C114.908 21.349 114.043 22.3069 112.918 22.2878Z"
        fill="white"
      />
      <path
        d="M111.984 22.199L80.1254 21.6655C79.0008 21.6469 78.0263 20.6581 77.9596 19.4683L76.9997 2.39836C76.933 1.20847 77.7976 0.250507 78.9222 0.26963L110.781 0.803202C111.905 0.821707 112.88 1.8105 112.946 3.0004L113.907 20.0703C113.974 21.2602 113.109 22.2181 111.985 22.199H111.984Z"
        fill="#407BFF"
      />
      <path
        opacity="0.6"
        d="M111.984 22.199L80.1254 21.6655C79.0008 21.6469 78.0263 20.6581 77.9596 19.4683L76.9997 2.39836C76.933 1.20847 77.7976 0.250507 78.9222 0.26963L110.781 0.803202C111.905 0.821707 112.88 1.8105 112.946 3.0004L113.907 20.0703C113.974 21.2602 113.109 22.2181 111.985 22.199H111.984Z"
        fill="#B2B2B2"
      />
      <path
        d="M91.2299 10.9521C91.3932 12.6096 93.0398 13.9531 94.9067 13.9531C96.7736 13.9531 98.1554 12.6096 97.9921 10.9521C97.8287 9.29465 96.1822 7.95178 94.3152 7.95178C92.4483 7.95178 91.0666 9.29527 91.2299 10.9521Z"
        fill="white"
      />
    </svg>
  );
};
