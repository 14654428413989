import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/CheckCircle';
import FileIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone';
import {
  AwsIcon,
  CrossIcon,
  ErrorIcon,
  FileUploadIcon,
  GoogleDriveIcon,
} from '../../assets/icons';
import { convertFileSize } from '../../utils/helper';
import CircularWithValueLabel from '../CircularWithValueLabel';
import useUploadedFiles from './hook/useUploadedFiles';
import { IUploadFileHandler } from './interfaces';
import { FileLanguage, getLanguageCode } from './utils';

interface ICustomDropzoneProps extends DropzoneOptions, IUploadFileHandler {
  title?: string;
  onChangeCallback?: (files: File[]) => void;
  handleValidateFile?: (file: File) => Promise<boolean>;
  validationErrorText?: string;
  canUpload?: boolean;
  fileIcon?: JSX.Element;
  crop?: boolean;
  cropMaxWidth?: number;
  cropMaxHeight?: number;
}

export const FileUploader = ({
  accept,
  maxFiles = 3,
  maxSize = 4194304,
  uploadFileHandler,
  canUpload = true,
  handleValidateFile,
  onChangeCallback,
  fileIcon,
  validationErrorText = 'Invalid format',
}: ICustomDropzoneProps) => {
  const [error, setError] = useState('');
  const [language, setLanguage] = useState(FileLanguage.english);
  const [displayError, setDisplayError] = useState(false);

  const allowedFilesTypes = Object.values(accept as Accept)
    .flat()
    .join(',');
  const allowedFilesTypeText = `Only ${allowedFilesTypes}`;
  const maxFileSizeText = `with max size ${convertFileSize(maxSize)}`;

  const {
    selectedFiles,
    handleNewFiles,
    filesUploading,
    handleAbort,
    handleUpload,
    allFilesUploaded,
    handleRemoveFile,
  } = useUploadedFiles({ uploadFileHandler });

  const handleDrop = async (accFiles: File[]) => {
    setDisplayError(false);
    setError('');
    handleNewFiles(accFiles);
  };

  const disabled = Object.keys(selectedFiles).length >= maxFiles;
  const fileUploadSucess = (key: string) => selectedFiles[key].isUploaded;
  const fileUploadFailure = (key: string) => selectedFiles[key].failed;
  const viewProgressBar = (key: string) => {
    return selectedFiles[key].isUploading;
  };

  const isUploadDisabled =
    !Object.keys(selectedFiles).length || filesUploading || !canUpload;

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDropRejected: (rejectedFiles) => {
      setDisplayError(true);
      if (rejectedFiles.length > maxFiles) {
        setError(`maximum ${maxFiles} can be selected`);
      } else {
        setError(
          `only ${allowedFilesTypeText} file type with maximum size ${convertFileSize(
            maxSize,
          )} supported`,
        );
      }
    },
    onDropAccepted: async (accFiles) => {
      let filteredFiles = [];
      if (handleValidateFile) {
        for (const file of accFiles) {
          const isValid = await handleValidateFile(file);
          if (isValid) {
            filteredFiles.push(file);
          }
        }
      } else {
        filteredFiles = [...accFiles];
      }

      if (filteredFiles.length !== accFiles.length) {
        setDisplayError(true);
        setError(validationErrorText);
      } else {
        onChangeCallback && onChangeCallback(filteredFiles);
        handleDrop(filteredFiles);
      }
    },
    maxFiles,
    disabled,
    maxSize,
  });

  const onUploadClick = () => {
    if (Object.keys(selectedFiles).length) {
      handleUpload(selectedFiles, getLanguageCode(language));
    }
  };

  return (
    <div className="flex flex-col gap-2">
      {!(filesUploading || allFilesUploaded) ? (
        <div
          className="w-full border border-dashed border-white-medium flex flex-col items-center justify-center cursor-pointer h-[200px]"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Box className="m-0 py-5 flex items-center flex-col">
            <FileUploadIcon className="mb-2 text-grey-light200 text-[64px]"></FileUploadIcon>
            <Typography className="m-0  ml-1 text-[#89868D] mb-3" variant="h4">
              Drag & Drop or{' '}
              <a className="underline text-[#0B307E] text"> Choose file </a> to
              upload
            </Typography>
            <Typography variant="h5">
              {allowedFilesTypeText} {maxFileSizeText}
            </Typography>
            <Box className="flex gap-3 mt-4">
              <GoogleDriveIcon className="w-10 h-10" />
              <AwsIcon className="w-10 h-10" />
            </Box>
          </Box>
        </div>
      ) : (
        ''
      )}
      {!allFilesUploaded && !filesUploading && (
        <Box className="flex justify-between items-center">
          <Typography>Select Language</Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="options"
              name="options"
              value={language}
              onChange={(e, val) => {
                setLanguage(val as FileLanguage);
              }}
              row
            >
              {Object.values(FileLanguage).map((lang, id) => (
                <FormControlLabel
                  key={`file-language-option-${id}`}
                  value={lang}
                  control={<Radio />}
                  label={lang}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )}
      <div
        className={`${filesUploading || allFilesUploaded ? 'max-h-[400px]' : 'max-h-[140px]'} base-scrollbar  flex flex-col gap-[13px] pr-[13px]`}
      >
        {Object.keys(selectedFiles).map((key, idx) => (
          <div className="border border-solid rounded border-white-medium px-4 py-[11px]">
            <div
              className="grid grid-cols-[auto_1fr_auto] items-center gap-2"
              key={idx}
            >
              {fileIcon ? fileIcon : <FileIcon />}
              <Typography className="m-0 text-[13px] text-[#052364] break-all">
                {selectedFiles[key].file.name}
              </Typography>
              {!viewProgressBar(key) &&
                !selectedFiles[key].isUploaded &&
                !selectedFiles[key].failed && (
                  <Button
                    style={{
                      backgroundColor: 'white',
                      border: 'none',
                      color: 'red',
                      padding: '0',
                      minWidth: 'auto',
                      display: 'inline-block',
                      marginLeft: 'auto',
                    }}
                    onClick={() => {
                      handleRemoveFile(key);
                      setDisplayError(false);
                    }}
                  >
                    <CrossIcon />
                  </Button>
                )}
              {fileUploadSucess(key) && <CheckIcon />}
              {fileUploadFailure(key) && <ErrorIcon />}
              {viewProgressBar(key) && (
                <CircularWithValueLabel
                  progress={selectedFiles[key].progress}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      {displayError && !filesUploading && (
        <div className="text-red-dark text-t-12 mt-2">{error}</div>
      )}
      {!filesUploading && !allFilesUploaded && (
        <Button
          disabled={isUploadDisabled}
          onClick={onUploadClick}
          sx={{
            background: '#1651D0',
            color: '#fff',
            '&.Mui-disabled': {
              background: 'rgba(217, 217, 217, 0.43)',
            },
            '&:hover': {
              background: '#0f3891',
            },
            alignSelf: 'self-end',
          }}
        >
          Upload
        </Button>
      )}
    </div>
  );
};
