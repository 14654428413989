import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import OutlineCallCard from '../components/card/OutlineCallCard';
import OutlineCoachingCard from '../components/card/OutlineCoachingCard';
import OutlineCallOverview from '../components/card/OutlineCallOverview';
import { SelectAutoWidth } from '../components/DropDown-Menu/SelectAutoWidth';
import { Graph1, Graph2, Graph3, Graph4 } from '../assets/images';
import { CustomTable } from '../components/dashboard/CustomTable';
import { CustomAccordian } from '../components/dashboard/CustomAccordian';
import { FirstBadge } from '../assets/icons';
import { useState } from 'react';
import OutlinePieChart from '../components/Graphs-Chart/OutlinePieChart';
import OutlineGraph from '../components/Graphs-Chart/OutlineGraph';
import { callAuditPageService } from '../service/callAuditPage';

interface IGetCallAuditInsights {
  id: string;
  totalCalls: number;
  avgScore: number;
  auditedCalls: {
    manual: number;
    ai: number;
  };
  callQuality: {
    fanatical: number;
    offTrack: number;
    onTrack: number;
  };
  dateWiseAvgScores: [
    {
      date: string;
      avgScore: number;
    },
  ];
  parameterWiseScores: {
    probingScore: number;
    sentimentScore: number;
    callOpeningScore: number;
    callClosingScore: number;
    acknowledgmentScore: number;
  };
}

export function Dashboard() {
  const [loading, setLoading] = useState<Boolean>(false);
  const [data, setData] = useState<IGetCallAuditInsights>();
  // const [startDate, setStartDate] = useState<Date>(new Date());
  // const [endDate, setEndDate] = useState<Date>(new Date());

  const getCallData = async () => {
    try {
      setLoading(true);
      const startDate = new Date(1970, 0, 1);
      const endDate = new Date();
      const data = await callAuditPageService.getCallAuditInsights({
        startDate,
        endDate,
      });
      setData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCallData();
  }, []);

  return (
    <>
      <Box className="base-scrollbar pt-10 px-7 h-[calc(100vh-75px)]">
        <Box className="flex justify-between mb-2.5 pr-20">
          <Typography variant="h2">Overview</Typography>
          <SelectAutoWidth />
        </Box>
        <Box className="w-full">
          <OutlineCallCard
            manualAuditedCalls={data?.auditedCalls?.manual || 0}
            callAuditedToday={data?.totalCalls || 0}
            averageCallScore={data?.avgScore || 0}
            aiAuditedCalls={data?.auditedCalls?.ai || 0}
          />
        </Box>
        <Box className="flex gap-5 mt-5">
          <Box className="xl:basis-3/5 xxl:basis-4/6">
            <Box className="grid grid-cols-2 gap-4 w-full">
              <Box className="flex flex-col gap-6 rounded-radius-100 border border-blue-light border-opacity-10 p-4 xxl:p-6">
                <Box className="flex justify-between items-center">
                  <Typography variant="h3">Average Call Quality</Typography>
                </Box>
                <Box className="w-full mt-auto">
                  <OutlineGraph
                    dateWiseAvgScores={data?.dateWiseAvgScores || []}
                  />
                </Box>
              </Box>
              <Box className="flex flex-col gap-6 rounded-radius-100 border border-blue-light border-opacity-10 p-4 xxl:p-6">
                <Box className="flex flex-col gap-3.5">
                  <Box className="flex justify-between items-center">
                    <Typography variant="h3">Agent Level Delta</Typography>
                  </Box>
                  <Box className="flex gap-6">
                    <Box className="flex items-center gap-2">
                      <Box className="w-[12px] h-[12px] rounded-radius-full bg-[#FE3BAB]"></Box>
                      <Typography variant="h6">With Coaching</Typography>
                    </Box>
                    <Box className="flex items-center gap-2">
                      <Box className="w-[12px] h-[12px] rounded-radius-full bg-[#FF3F3F]"></Box>
                      <Typography variant="h6">Without Coaching</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="w-full">
                  <img src={Graph2} alt="graph2" />
                </Box>
              </Box>
            </Box>
            <Box className="mt-5 flex flex-col gap-3 rounded-radius-100 border-[1px] border-[#1651d017] border-solid py-5 px-6 ">
              <Box className="flex justify-between items-center">
                <Typography variant="h3">Average Call Quality</Typography>
              </Box>
              <Box className="flex gap-6 mb-8">
                <Box className="flex items-center gap-2">
                  <Box className="w-[12px] h-[12px] rounded-radius-full bg-[#D3E2F7]"></Box>
                  <Typography variant="h6">AI Audit</Typography>
                </Box>
                <Box className="flex items-center gap-2">
                  <Box className="w-[12px] h-[12px] rounded-radius-full bg-[#1651D0]"></Box>
                  <Typography variant="h6">Manual Audit</Typography>
                </Box>
              </Box>
              <Box className="mt-auto">
                <img className="w-full" src={Graph3} alt="Bar Graph" />
              </Box>
            </Box>
            <Box className="mt-5">
              <Typography variant="h3" className="mb-3">
                Coaching Overview
              </Typography>
              <OutlineCoachingCard />
            </Box>
            <Box>
              <CustomTable
                totalCalls={data?.totalCalls || 0}
                probingScore={data?.parameterWiseScores?.probingScore || 0}
                sentimentScore={data?.parameterWiseScores?.sentimentScore || 0}
                callClosingScore={
                  data?.parameterWiseScores?.callClosingScore || 0
                }
                callOpeningScore={
                  data?.parameterWiseScores?.callOpeningScore || 0
                }
                acknowledgmentScore={
                  data?.parameterWiseScores?.acknowledgmentScore || 0
                }
              />
            </Box>
          </Box>
          <Box className="xl:basis-2/5 xxl:basis-4/12">
            <Box className="w-full flex flex-col gap-5">
              <Box className="w-full py-5 px-5 border-[1px] border-[#1651d017] border-solid rounded-radius-100 mr-5">
                <Typography variant="subtitle1" className="pb-4 text-[#02194B]">
                  Call Overview
                </Typography>
                <OutlineCallOverview />
                {/* <img src={Graph4} alt="Graph 4" className="pt-5" /> */}

                <OutlinePieChart
                  onTrack={data?.callQuality?.onTrack || 0}
                  offTrack={data?.callQuality?.offTrack || 0}
                  fanatical={data?.callQuality?.fanatical || 0}
                />
              </Box>
              <Box>
                <CustomAccordian
                  probingScore={data?.parameterWiseScores?.probingScore || 0}
                  sentimentScore={
                    data?.parameterWiseScores?.sentimentScore || 0
                  }
                  callClosingScore={
                    data?.parameterWiseScores?.callClosingScore || 0
                  }
                  callOpeningScore={
                    data?.parameterWiseScores?.callOpeningScore || 0
                  }
                  acknowledgmentScore={
                    data?.parameterWiseScores?.acknowledgmentScore || 0
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
