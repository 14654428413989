import React from 'react';
import { TableHeader } from '../../common/TableHeader';
import { Box, Table, TableContainer, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import { CallsListItem } from './CallsListItem';
import { ICallsListItem } from '../../../pages';

const CALL_LIST_COLUMNS = [
  'CALL ID',
  'Emp Name & id',
  'Call date',
  'AUDIT DATE',
  'Status',
  'Type',
  'Feedback',
  'SEE DETAIL',
];

interface IProps {
  data: ICallsListItem[];
  totalCount: number;
  page: number;
  pageSize: number;
  setPageCallback: (page: number) => void;
  setPageSizeCallback: (page: number) => void;
}

export function CallsList({
  data,
  totalCount,
  page,
  pageSize,
  setPageCallback,
  setPageSizeCallback,
}: IProps) {
  return (
    <>
      <TableContainer component={Paper} className='base-scrollbar shadow-none border border-solid border-[#e0e0e0] border-b-0'>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
          <TableHeader columns={CALL_LIST_COLUMNS} />
          {data.map((item, id) => (
            <CallsListItem data={item} key={id} />
          ))}
        </Table>
      </TableContainer>
      <Box className="w-full text-right">
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={(e, page) => setPageCallback(page)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) =>
            setPageSizeCallback(parseInt(e.target.value, 10))
          }
        />
      </Box>
    </>
  );
}
