import React from 'react';
import { Button } from '@mui/material';
import { ExportIcon, SearchIcon, UploadIcon } from '../../assets/icons';
import { IFilterProps } from './header/ListpageHeader';
import { useGlobalContext } from '../../contexts/useGlobalContext';
import { CustomizedInput } from '../inputs/inputWithLabel.styled';
import { FormControl } from '@mui/material';

interface IProps extends IFilterProps {
  setRefetch: (value: boolean) => void;
}

export function ListpageOperations({ value, setValue, setRefetch }: IProps) {
  const { openModal } = useGlobalContext();
  return (
    <div className="grid grid-cols-[1fr_auto_auto] grid-col-3 gap-5 -order-1 xmd:order-none">
      <FormControl className="w-full">
        <CustomizedInput
          className="w-full hover:outline-0 h-full"
          placeholder="Search"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          startAdornment={<SearchIcon />}
        />
      </FormControl>
      <Button id="outlined-basic" variant="outlined" startIcon={<ExportIcon />}>
        Export
      </Button>
      <Button
        component="label"
        variant="contained"
        onClick={() => {
          openModal('Upload', setRefetch);
        }}
        startIcon={<UploadIcon />}
      >
        Upload file    
      </Button>
    </div>
  );
}
