import React from 'react';
import { IconsProps } from './Icons';

export const ArrowDownIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 22 22'}
      width={width ? width : '22'}
      height={height ? height : '22'}
      fill={fill ? fill : 'currentColor'}
    >
      <path
        d="M10.5022 14.6025L3.20099 6.93963C3.0791 6.81177 3.01111 6.6419 3.01111 6.46525C3.01111 6.2886 3.0791 6.11874 3.20099 5.99088L3.20924 5.98263C3.26833 5.92043 3.33945 5.8709 3.41828 5.83706C3.49712 5.80321 3.58201 5.78576 3.6678 5.78576C3.75359 5.78576 3.83849 5.80321 3.91732 5.83706C3.99615 5.8709 4.06728 5.92043 4.12636 5.98263L11.0014 13.1986L17.8736 5.98263C17.9327 5.92043 18.0038 5.8709 18.0827 5.83706C18.1615 5.80321 18.2464 5.78576 18.3322 5.78576C18.418 5.78576 18.5029 5.80321 18.5817 5.83706C18.6605 5.8709 18.7317 5.92043 18.7907 5.98263L18.799 5.99088C18.9209 6.11874 18.9889 6.2886 18.9889 6.46525C18.9889 6.6419 18.9209 6.81177 18.799 6.93963L11.4977 14.6025C11.4335 14.6699 11.3563 14.7235 11.2707 14.7602C11.1852 14.7969 11.0931 14.8158 11 14.8158C10.9069 14.8158 10.8148 14.7969 10.7292 14.7602C10.6437 14.7235 10.5664 14.6699 10.5022 14.6025Z"
        fill="currentColor"
      />
    </svg>
  );
};
