import React from 'react';
import { Provider } from 'react-redux';
import createStore from './store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRoutes } from './routes/AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './appTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { withGlobalContextWrapper } from './contexts/GlobalContextWrapper';
import { Modal } from './components/modal/Modal';

export const store = createStore();
const persistor = persistStore(store);

function Application() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoutes />
            <Modal />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
const App = withGlobalContextWrapper(Application);
export default App;
