import { styled } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';
export const CustomizedInput = styled(OutlinedInput)`
  color: #89868d;
  & .MuiOutlinedInput-notchedOutline {
    border-color: #ced2d8;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #0d56be;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0d56be;
    border-width: 1px;
    
  }
  &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(17, 24, 39, 0.12);
  }
  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #fc5a5a;
  }
  & .MuiSvgIcon-root {
    fill: #89868d;
    width: 18px;
  }
`;
