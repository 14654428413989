import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Stack from '@mui/material/Stack';
import { deepOrange } from '@mui/material/colors';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardActionArea,
} from '@mui/material';
import {
  ArrowDownIcon,
  AverageScoreIcon,
  TotalCallIcon,
} from '../../assets/icons';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserIcon } from '../../assets/icons/UserIcon';
import { Agent2, Agent3, Agent4, AvatarImage } from '../../assets/images';
import { Link } from 'react-router-dom';
import { SelectAutoWidth } from '../DropDown-Menu/SelectAutoWidth';

interface CustomAccordianProps {
  probingScore: number;
  sentimentScore: number;
  callClosingScore: number;
  callOpeningScore: number;
  acknowledgmentScore: number;
}

export function CustomAccordian({
  probingScore,
  sentimentScore,
  callClosingScore,
  callOpeningScore,
  acknowledgmentScore,
}: CustomAccordianProps) {
  const parameters = [
    { name: 'PROBING', score: Math.round(probingScore * 100) },
    { name: 'SENTIMENT', score: Math.round(sentimentScore * 100) },
    { name: 'CLOSING', score: Math.round(callClosingScore * 100) },
    { name: 'OPENING', score: Math.round(callOpeningScore * 100) },
    { name: 'ACKNOWLEDGE', score: Math.round(acknowledgmentScore * 100) },
  ];
  parameters.sort((a, b) => b.score - a.score);
  const topPerformingParameters = parameters.slice(0, 4);
  const HighAlertParameters = parameters.slice(1, 5).reverse();

  return (
    <Box className="base-scrollbar w-full p-4 border border-solid border-[#1651d017] rounded-radius-100 bg-[#edf3ffc7] flex flex-col gap-4 max-h-[900px]">
      <Box className="bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 p-6">
        <Box className="flex flex-col gap-4 mb-6">
          <Typography variant="body1" className="text-[#02194B]">
            Fanatical Reps of the day
          </Typography>
          <AvatarGroup max={6} className=" flex justify-end	 gap-3">
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
              <Typography variant="h6" className="text-primary">
                Brooklyn
              </Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent2}></Avatar>
              <Typography variant="h6">Courtney</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent3}></Avatar>
              <Typography variant="h6">Ralph</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Leslie </Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Leslie </Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
          </AvatarGroup>
        </Box>
        <Box className="flex flex-col gap-4 mb-6">
          <Typography variant="body1" className="text-[#02194B]">
            Leaderboard for Fanatical calls
          </Typography>
          <AvatarGroup max={6} className=" flex justify-end	 gap-3">
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent2}></Avatar>
              <Typography variant="h6">Courtney</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent3}></Avatar>
              <Typography variant="h6">Ralph</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent3}></Avatar>
              <Typography variant="h6">Ralph</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent3}></Avatar>
              <Typography variant="h6">Ralph</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent3}></Avatar>
              <Typography variant="h6">Ralph</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Leslie </Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Leslie </Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
          </AvatarGroup>
        </Box>
        <Box className="flex flex-col gap-4">
          <Typography variant="body1" className="text-[#02194B]">
            Fanatical Reps of Coaching Assessment
          </Typography>
          <AvatarGroup max={6} className=" flex justify-end	 gap-3">
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent2}></Avatar>
              <Typography variant="h6">Courtney</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent3}></Avatar>
              <Typography variant="h6">Ralph</Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Leslie </Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Leslie </Typography>
            </Box>
            <Box className="flex flex-col gap-2 items-center">
              <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
              <Typography variant="h6">Brooklyn</Typography>
            </Box>
          </AvatarGroup>
        </Box>
      </Box>
      <Box className="flex flex-col gap-4">
        <Accordion className="bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none ">
          <AccordionSummary
            expandIcon={<ArrowDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Parameter Level Analytics</Typography>
          </AccordionSummary>
          <AccordionDetails className="flex flex-col gap-6">
            <Box className="border border-solid border-[#CCF9B675] rounded-radius-100 bg-[#F1FFEB33] py-3 px-[14px]">
              <Typography variant="body1" className="mb-8">
                Top Performing Parameters
              </Typography>
              <Box className="flex flex-col gap-3">
                {topPerformingParameters.map((item, index) => (
                  <Box className="flex flex-col gap-3">
                    <Typography variant="caption">{item.name}</Typography>
                    <Box className=" flex items-center">
                      <Box sx={{ width: '100%', mr: 1, color: '#3DAC09' }}>
                        <LinearProgress
                          variant="determinate"
                          value={item.score}
                          color="inherit"
                          className="h-[6px] rounded-radius-100"
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography>{item.score}%</Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Link
                  className="text-end no-underline text-[14px] text-[#0D56BE]"
                  to={''}
                >
                  See All
                </Link>
              </Box>
            </Box>
            <Box className="border border-solid border-[#FEECEC4F] rounded-radius-100 bg-[#FEECEC4F] py-4 px-[17px]">
              <Typography variant="body1" className="mb-8">
                High Alert Parameters
              </Typography>
              <Box className="flex flex-col gap-3">
                {HighAlertParameters?.map((item, indx) => (
                  <Box className="flex flex-col gap-3">
                    <Typography variant="caption">{item?.name}</Typography>
                    <Box className=" flex items-center">
                      <Box sx={{ width: '100%', mr: 1, color: '#FC5A5A' }}>
                        <LinearProgress
                          variant="determinate"
                          value={item?.score}
                          color="inherit"
                          className="h-[6px] rounded-radius-100"
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography>{item?.score}%</Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}

                <Link
                  className="text-end no-underline text-[14px] text-[#0D56BE]"
                  to={''}
                >
                  See All
                </Link>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion className="bg-[#fff] border border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none border-t-0 before:content-[none]">
          <AccordionSummary
            expandIcon={<ArrowDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Manager Level Analytics</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Box className="border border-solid border-[#E5FBF8] rounded-radius-100 py-4 px-[17px] bg-[#E5FBF83D] flex flex-col gap-3">
              <Typography variant="body1" className="text-[#02194B]">
                Top Performing Managers
              </Typography>
              <AvatarGroup max={6} className=" flex justify-end	 gap-3">
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
                  <Typography variant="h6">Brooklyn</Typography>
                </Box>
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
                  <Typography variant="h6">Brooklyn</Typography>
                </Box>
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Brooklyn" src={AvatarImage}></Avatar>
                  <Typography variant="h6">Brooklyn</Typography>
                </Box>
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Remy Sharp" src={Agent2}></Avatar>
                  <Typography variant="h6">Courtney</Typography>
                </Box>
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Remy Sharp" src={Agent3}></Avatar>
                  <Typography variant="h6">Ralph</Typography>
                </Box>
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
                  <Typography variant="h6">Leslie </Typography>
                </Box>
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
                  <Typography variant="h6">Leslie </Typography>
                </Box>
                <Box className="flex flex-col gap-2 items-center">
                  <Avatar alt="Remy Sharp" src={Agent4}></Avatar>
                  <Typography variant="h6">Brooklyn</Typography>
                </Box>
              </AvatarGroup>
            </Box>
            <Typography variant="body1" className="mt-8 mb-4">
              Managerial Call Performance Score
            </Typography>
            <Box className="flex flex-col gap-3">
              <Card className="border border-solid border-[#E5FBF8] rounded-radius-100 py-4  px-3.5 flex flex-col gap-3">
                <CardContent className="flex gap-4 items-center px-0 py-0">
                  <Box className="flex gap-2">
                    <img src={AvatarImage} alt="userImage" />
                    <Box className="flex flex-col gap-0.5">
                      <Typography variant="body1" className="text-[#201F20]">
                        Brooklyn{' '}
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Average Score : 35%{' '}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
                <Box className="flex gap-2">
                  <CardContent className="grid grid-cols-[auto,auto] gap-1 place-items-start border-r border-y-0 border-l-0 border-solid border-white-medium p-2 ">
                    <UserIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Agents
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent className="grid grid-cols-[auto,auto] gap-2 place-items-start	border-r border-y-0 border-l-0 border-solid border-white-medium p-2 ">
                    <TotalCallIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Calls
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent className="grid grid-cols-[auto,auto] gap-2 place-items-start p-2">
                    <AverageScoreIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Score
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
              <Card className="border border-solid border-[#E5FBF8] rounded-radius-100 py-4  px-3.5 flex flex-col gap-3">
                <CardContent className="flex gap-4 items-center px-0 py-0">
                  <Box className="flex gap-2">
                    <img src={AvatarImage} alt="userImage" />
                    <Box className="flex flex-col gap-0.5">
                      <Typography variant="body1" className="text-[#201F20]">
                        Brooklyn{' '}
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Average Score : 35%{' '}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
                <Box className="flex gap-2">
                  <CardContent className="grid grid-cols-[auto,auto] gap-1 place-items-start border-r border-y-0 border-l-0 border-solid border-white-medium p-2 ">
                    <UserIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Agents
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent className="grid grid-cols-[auto,auto] gap-2 place-items-start	border-r border-y-0 border-l-0 border-solid border-white-medium p-2 ">
                    <TotalCallIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Calls
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent className="grid grid-cols-[auto,auto] gap-2 place-items-start p-2">
                    <AverageScoreIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Score
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
              <Card className="border border-solid border-[#E5FBF8] rounded-radius-100 py-4  px-3.5 flex flex-col gap-3">
                <CardContent className="flex gap-4 items-center px-0 py-0">
                  <Box className="flex gap-2">
                    <img src={AvatarImage} alt="userImage" />
                    <Box className="flex flex-col gap-0.5">
                      <Typography variant="body1" className="text-[#201F20]">
                        Brooklyn{' '}
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Average Score : 35%{' '}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
                <Box className="flex gap-2">
                  <CardContent className="grid grid-cols-[auto,auto] gap-1 place-items-start border-r border-y-0 border-l-0 border-solid border-white-medium p-2 ">
                    <UserIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Agents
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent className="grid grid-cols-[auto,auto] gap-2 place-items-start	border-r border-y-0 border-l-0 border-solid border-white-medium p-2 ">
                    <TotalCallIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Calls
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardContent className="grid grid-cols-[auto,auto] gap-2 place-items-start p-2">
                    <AverageScoreIcon />
                    <Box>
                      <Typography variant="body1" className="text-[#201F20]">
                        342
                      </Typography>
                      <Typography variant="h6" className="text-[#89868D]">
                        Total Score
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion className="bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none]">
          <AccordionSummary
            expandIcon={<ArrowDownIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>Team Level Analytics</Typography>
          </AccordionSummary>
          <AccordionDetails className="flex flex-col gap-6">
            <Box className="border border-solid border-[#1651D017] rounded-radius-100 py-4 px-[17px]">
              <Box className="flex justify-between">
                <Typography variant="body1" className="mb-8">
                  Top Performing Teams
                </Typography>
              </Box>
              <Box className="flex flex-col gap-3">
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#3DAC09' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#3DAC09' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#3DAC09' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#3DAC09' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Link
                  className="text-end no-underline text-[14px] text-[#0D56BE]"
                  to={''}
                >
                  See All
                </Link>
              </Box>
            </Box>
            <Box className="border border-solid border-[#1651D017] rounded-radius-100 py-4 px-[17px]">
              <Box className="flex justify-between">
                <Typography variant="body1" className="mb-8">
                  Need Attention Teams
                </Typography>
              </Box>

              <Box className="flex flex-col gap-3">
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#FC5A5A' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#FC5A5A' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#FC5A5A' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-3">
                  <Typography variant="caption">Did agent actively</Typography>
                  <Box className="flex items-center">
                    <Box sx={{ width: '100%', mr: 1, color: '#FC5A5A' }}>
                      <LinearProgress
                        variant="determinate"
                        value={35}
                        color="inherit"
                        className="h-[6px] rounded-radius-100"
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography>35%</Typography>
                    </Box>
                  </Box>
                </Box>
                <Link
                  className="text-end no-underline text-[14px] text-[#0D56BE]"
                  to={''}
                >
                  See All
                </Link>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
