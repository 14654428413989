import React from 'react';
import { HomeTestimonials } from '../Home/HomeTestimonials';
import { HomeFeature } from '../Home/HomeFeature';
import LandingSubHeader from '../../../components/common/LandingSubHeader';
import ListsIcon from '../../../assets/images/LandingPage/lists.svg';
import ChatRobotIcon from '../../../assets/images/LandingPage/chat-robot.svg';
import FourDotsIcon from '../../../assets/images/LandingPage/four-dots.svg';

export function IntuitiveDashboard() {
  return (
    <>
      <LandingSubHeader
        title="Intuitive"
        colortitle="Dashboard"
        description=""
        showCTA={true}
      />
      <section className="py-12 lg:py-20">
        <div className="container">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <figure className="shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] f-ic-jc flex-col gap-6 px-5 py-6 bg-white-light rounded-xl">
              <img className="size-[86px]" src={ListsIcon} />
              <figcaption>
                <p className="body-l text-grey-dark-200 text-center">
                  Streamline Your Operations with User-Friendly Dashboards
                  Experience the simplicity of managing your operations through
                  our intuitive and user-friendly dashboard. Effortlessly
                  monitor and oversee your entire workflow in one centralised
                  place.
                </p>
              </figcaption>
            </figure>
            <figure className="shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] f-ic-jc flex-col gap-6 px-5 py-6 bg-white-light rounded-xl">
              <img className="size-[86px]" src={ChatRobotIcon} />
              <figcaption>
                <p className="body-l text-grey-dark-200 text-center">
                  At Skilldify.ai, we offer innovative solutions that
                  revolutionise the management of AI analysis and agent
                  performance. By choosing us, you gain access to a
                  comprehensive database that enables analysis of customer
                  interactions across calls, chats, and emails.
                </p>
              </figcaption>
            </figure>
            <figure className="shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] f-ic flex-col gap-6 px-5 py-6 bg-white-light rounded-3xl">
              <img className="size-[86px]" src={FourDotsIcon} />
              <figcaption>
                <p className="body-l text-grey-dark-200 text-center">
                  Say goodbye to cumbersome spreadsheets and embrace our web
                  portal, which offers manual auditing capabilities as well.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <HomeFeature name="featureIntutiveDetail" />
      <HomeTestimonials />
    </>
  );
}
