import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  IFilterProps,
  ListpageHeader,
} from '../../components/list-page/header/ListpageHeader';
import { CallsList } from '../../components/list-page/list/CallsList';
import { PAGINATION_CONSTANTS } from '../../utils';
import { debounce } from 'lodash';
import {
  IGetCallsListRequest,
  callAuditPageService,
} from '../../service/callAuditPage';
import { CircularProgress, Typography } from '@mui/material';
import { NoData } from '../../assets/images';

export interface ICallsListItem {
  id: string;
  employee: {
    id: string;
    name: string;
  };
  name: string;
  auditDatetime: string;
  callDatetime: string;
  status: string;
  callType: string;
  score: number;
}

export function CallAuditListPage() {
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.DEFAULT.PAGE);
  const [pageSize, setPageSize] = useState<number>(
    PAGINATION_CONSTANTS.DEFAULT.PAGESIZE,
  );
  const [loading, setLoading] = useState<number>(0);
  const [data, setData] = useState<ICallsListItem[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [hardHit, setHardHit] = useState<boolean>(false);

  const handleFilterChange = (value: string) => {
    setFilter(value);
    if (page === 0) {
      setHardHit(!hardHit);
    } else {
      setPage(0);
    }
  };
  const handleSearchChange = (value: string) => {
    setSearch(value);
    debounceEffect(value);
  };

  const debounceEffect = debounce((term) => {
    if (page === 0) {
      setHardHit(!hardHit);
    } else {
      setPage(0);
    }
  }, 500);

  const searchProps: IFilterProps = {
    value: search,
    setValue: handleSearchChange,
  };
  const filterProps: IFilterProps = {
    value: filter,
    setValue: handleFilterChange,
  };

  const fetchData = async (props: IGetCallsListRequest) => {
    try {
      setLoading((v) => v + 1);
      const data = await callAuditPageService.getCallsList(props);
      setData(data.data);
      setTotalCount(data.pagination.total);
      setLoading((v) => v - 1);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    if (page === 0) {
      setHardHit(!hardHit);
    } else {
      setPage(0);
    }
  };

  const refetchCallback = () => {
    setHardHit(!hardHit);
  };

  useEffect(() => {
    fetchData({
      offset: page * pageSize,
      limit: pageSize,
      q: search,
      reviewed:
        filter !== '' ? (filter === 'reviewed' ? true : false) : undefined,
    });
  }, [page, hardHit]);

  return (
    <>
      <Box className="base-scrollbar w-full py-8 px-6 flex flex-col gap-y-11 h-[calc(100vh-75px)]">
        <ListpageHeader
          search={searchProps}
          filter={filterProps}
          setRefetch={refetchCallback}
        />
        {loading !== 0 ? (
          <Box className="flex flex-col h-96 gap-8 justify-center items-center">
            <CircularProgress variant="indeterminate" />
            <Typography>Loading Data ...</Typography>
          </Box>
        ) : totalCount ? (
          <CallsList
            page={page}
            pageSize={pageSize}
            setPageCallback={setPage}
            data={data}
            totalCount={totalCount}
            setPageSizeCallback={handlePageSizeChange}
          />
        ) : (
          <Box className="flex flex-col justify-center items-center h-full">
            <img className="max-w-[239px]" src={NoData} />
            <Typography variant="h4" color="ext.primary">
              "<strong>Oops!</strong> No data found. Let's fix that – please
              upload your calls."
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
