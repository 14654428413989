import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Dashboard,
  CallAuditDetailPage,
  CallAuditListPage,
  Login,
  Home,
  WhyUs,
  SkilldifyAnalysis,
  SkilldifyLearning,
  SkilldifyQA,
  AssessingAgentPerformance,
  ElevatingCustomerExperiences,
  ExpandQa,
  IntuitiveDashboard,
} from '../pages';
import { GuestLayout, PrivateLayout } from '.';
import { PAGE_URL } from '../utils';

export function AppRoutes() {
  return (
    <>
      <Routes>
        <Route element={<GuestLayout />}>
          <Route path={PAGE_URL.HOME} element={<Home />} />
          <Route path={PAGE_URL.WHYUS} element={<WhyUs />} />
          <Route path={PAGE_URL.SKILLDIFYANALYSIS} element={<SkilldifyAnalysis />} />
          <Route path={PAGE_URL.SKILLDIFYLEARNING} element={<SkilldifyLearning />} />
          <Route path={PAGE_URL.SKILLDIFYQA} element={<SkilldifyQA />} />
          <Route path={PAGE_URL.AGENTPERFORMANCE} element={<AssessingAgentPerformance />} />
          <Route path={PAGE_URL.ELEVATINGCUSTOMEREXPERIENCES} element={<ElevatingCustomerExperiences />} />
          <Route path={PAGE_URL.EXPANDQA} element={<ExpandQa />} />
          <Route path={PAGE_URL.INTUITIVEDASHBOARD} element={<IntuitiveDashboard />} />
          <Route path={PAGE_URL.LOGIN} element={<Login />} />
        </Route>
        <Route element={<PrivateLayout />}>
          <Route path={PAGE_URL.DASHBOARD} element={<Dashboard />} />
          <Route
            path={PAGE_URL.CALL_AUDIT.LIST_PAGE}
            element={<CallAuditListPage />}
          />
          <Route
            path={PAGE_URL.CALL_AUDIT.DETAIL_PAGE}
            element={<CallAuditDetailPage />}
          />
        </Route>
      </Routes>
    </>
  );
}
