import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CallOverviewIcon } from '../../assets/icons';

const card = (
  <>
    <Card variant="outlined" className="bg-[#fff] py-2 px-2 rounded-radius-100 border-[#E2F8FF]">
      <CardContent className="grid grid-cols-[auto_1fr] gap-2 py-0 px-0 items-center">
        <CallOverviewIcon className='fill-[#EAF6E4]'/>
        <Typography className="flex flex-col gap-2">
          <Typography variant="h4">250</Typography>
          <Typography variant="h6" className='font-[Inter-Medium] text-[#89868D]'>
            INBOUND CALLS
          </Typography>
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined" className="bg-[#fff] py-2 px-2 rounded-radius-100 border-[#E2F8FF]">
      <CardContent className="grid grid-cols-[auto_1fr] gap-2 py-0 px-0 items-center">
        <CallOverviewIcon/>
        <Typography className="flex flex-col gap-2">
          <Typography variant="h4">250</Typography>
          <Typography variant="h6" className='font-[Inter-Medium] text-[#89868D]'>
            OUTBOUND CALLS
          </Typography>
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined" className="bg-[#fff] py-2 px-2 rounded-radius-100 border-[#E2F8FF]">
      <CardContent className="grid grid-cols-[auto_1fr] gap-2 py-0 px-0 items-center">
        <CallOverviewIcon/>
        <Typography className="flex flex-col gap-2">
          <Typography variant="h4">250</Typography>
          <Typography variant="h6" className='font-[Inter-Medium] text-[#89868D]'>
          DIALED CALLS
          </Typography>
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined" className="bg-[#fff] py-2 px-2 rounded-radius-100 border-[#E2F8FF]">
      <CardContent className="grid grid-cols-[auto_1fr] gap-2 py-0 px-0 items-center">
        <CallOverviewIcon/>
        <Typography className="flex flex-col gap-2">
          <Typography variant="h4">250</Typography>
          <Typography variant="h6" className='font-[Inter-Medium] text-[#89868D]'>
            CONNECTED CALLS
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  </>
);

export default function OutlineCallOverview() {
  return (
    <Box className="grid grid-cols-2 gap-[6px] p-3 bg-[#e2f8ff57] border border-solid border-[#E2F8FF] rounded-radius-100">
      {card}
    </Box>
  );
}
