import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography } from '@mui/material';

export function SelectAutoWidth() {
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <div>
      <FormControl>
        <InputLabel id="demo-simple-select-autowidth-label"></InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={age}
          onChange={handleChange}
          autoWidth
          displayEmpty
          sx={{
            color: '#000',
            width: '120px',
            height: '35px',
            padding: '6px 10px',
            borderRadius: '8px',
            '& .MuiSelect-select': {
              paddingRight: '16px !important',
              paddingLeft: 0,
              paddingTop: 1,
              paddingBottom: 1,
            },
            '&.select-placeholder': {
              color: 'rgba(0,0,0,.5)',
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#D7E3FF',
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(17, 24, 39, 0.12)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 135, 181, 0.4)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 135, 181, 0.4)',
            },
            '&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(17, 24, 39, 0.12)',
            },
            '&.error .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ff0000',
            },
            '.MuiSvgIcon-root ': {
              fill: 'rgba(0, 0, 0, 0.7)',
              width: '18px',
            },
            '.MuiMenuItem-root ': {
              fontSize: '14px',
              color: '#5C5E64',
            },
          }}
        >
          <MenuItem value={''}>This Week</MenuItem>
          <MenuItem value={21}>Monthly</MenuItem>
          <MenuItem value={22}>Yearly</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
