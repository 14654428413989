import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoneIcon from '@mui/icons-material/Done';
import BgAbstract from '../../../assets/images/LandingPage/feature-abstract-bg.svg';
import FeatureData from '../../../data/feature.json';

interface IHomeFeatureProp {
  name: string;
}
export function HomeFeature({ name }: IHomeFeatureProp) {
  const data = FeatureData.filter((item) => item.name === name);
  return (
    <section className="alternate-image-section py-12 lg:py-20 relative bg-black-dark-100">
      <div className="container space-y-14 md:space-y-20 lg:space-y-28">
        <div className="f-ic flex-col gap-4">
          <h2 className="h3-b xl:h2-b text-white-light text-center">
            {data[0].title}
          </h2>
          {data[0].buttonText && (
            <a
              href={undefined}
              className="lp-btn cursor-pointer bg-white-light text-pink-dark"
            >
              {data[0].buttonText}
              <i className="size-6">
                <ArrowRightAltIcon />
              </i>
            </a>
          )}
        </div>
        <div className="space-y-14 md:space-y-20 lg:space-y-28 *:grid *:md:grid-cols-2 *:gap-10 *:items-center">
          {data[0].featureDetails.map((data, itemIndex) => {
            return (
              <figure key={itemIndex}>
                <img
                  src={data.imageUrl}
                  alt="userImage"
                  className="w-full"
                  loading="lazy"
                />
                <figcaption className="space-y-5">
                  <h2 className="text-white-light subtitle-b xxl:title-b text-center md:text-left">
                    {data.blockTitle}
                  </h2>
                  <ul className="space-y-4">
                    {data.blockList.map((item, index) => (
                      <li
                        key={index}
                        className="flex items-start gap-5 text-white-light para-l"
                      >
                        <i className="bg-gradient-purple f-ic-jc size-8 rounded-full shrink-0">
                          <DoneIcon className="size-6" />
                        </i>
                        {item}
                      </li>
                    ))}
                  </ul>
                </figcaption>
              </figure>
            );
          })}
          <div className="absolute top-[35%] left-0 !inline-block">
            <img src={BgAbstract} alt="" />
          </div>
          <div
            className="absolute bottom-0 right-0 !inline-block"
            style={{ transform: 'rotateY(180deg)' }}
          >
            <img src={BgAbstract} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
