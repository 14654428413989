import { red } from '@mui/material/colors';
import { ThemeOptions, createTheme } from '@mui/material/styles';


export const theme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#1651D0',
    },
    secondary: {
      main: '#7DA3F6',
    },
    error: {
      main: red[900],
    },
    warning: {
      main: red[900],
    },
    info: {
      main: '#89868D',
    },
    success: {
      main: '#fff',
    },
    text: {
      primary: '#4E4E4E',
      secondary: '#052364',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          borderRadius: '8px',
          padding: "11px 16px",
          fontFamily: '"Inter-Regular", Arial',
          backgroundImage: '1651D0',
          color: '#fff',
          
        },
        outlined: {
          border: '1px solid #052364',
          color: '#052364',
          backgroundColor: 'transparent',
          backgroundImage: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          border: ' 1px solid #D9D9D9',
          borderRadius: '8px',
          fontSize: '14px',
          color: '#052364',
          marginRight: '16px',
          fontFamily: '"Inter-Medium", Arial',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow:"none",
          "&.Mui-expanded": {
            marginBottom:'0 !important',
            marginTop:'0 !important',
          }
        }
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight:'42px',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          width:'54px !important',
          height:'54px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '9px 16px',
          borderRadius: '4px',
          fontSize:'14px',
          fontFamily: '"Inter-Regular", Arial',
          color: '#89868D',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: '#D7E3FF70',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontFamily: '"Inter-Medium", Arial',
          borderRadius: '4px',
        },
        head: {
          color: '#052364',
          fontWeight: '500',
          borderRadius:"0",
          textTransform:"uppercase",
          background:'#EDF3FF',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          boxShadow: 'none',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily:'"Inter-Regular",Arial',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding:'27px 32px',
          borderWidth:'2px',
          borderColor:'#1651d017',
          borderRadius:'16px',
          outline:'none',
          boxShadow: '0px 3px 4px 0px rgba(210, 208, 208, 0.09)',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height:'47px',
          width:'47px',
          marginLeft:'0 !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding:'10px 12px',
          fontFamily:'Inter-Regular',
          color:'#201F20',
          textAlign:'center',
          minWidth:'120px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding:'0'
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent", 
          },
        },
      },
    },
  },
  typography: {
    fontFamily: '"Inter-Regular", Arial',
    h1: {
      fontSize: 32,
      fontFamily: '"Inter-Bold", Arial'
    },
    h2: {
      fontSize: 24,
      fontFamily: '"Inter-Medium", Arial',
    },
    h3: {
      fontSize: 20,
      fontFamily: '"Inter-Medium", Arial',
    },
    h4: {
      fontSize: 16,      
      fontFamily: '"Inter-Medium", Arial',
    },
    h5: {
      fontSize: 14,
      fontFamily: '"Inter-Regular", Arial',
    },
    h6: {
      fontSize: 12,
      fontFamily: '"Inter-Regular", Arial',
    },
    subtitle1: {
      fontSize: 18,
      fontFamily: '"Inter-Bold", Arial',
    },
    subtitle2: {
      fontSize: 18,
      fontFamily: '"Inter-Medium", Arial',
      color:"#201F20",
    },
    body1: {
      fontSize: 14,
      color: '#052364',
      fontFamily: '"Inter-Bold", Arial',
    },
    body2: {
      fontSize: 14,      
      color: '#89868D',
      fontFamily: '"Inter-Regular", Arial',
    },
    button: {
      fontSize: 50,
    },
    caption: {
      fontSize: 14,
      color: '#89868D',
      fontFamily: '"Inter-Regular", Arial',
    },

    overline: {
      fontSize: 12,
      color: '#89868D',
      lineHeight:"1.2",
      fontFamily: '"Inter-Regular", Arial',
      textTransform:"capitalize"
    }
  },
});
