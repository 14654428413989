import bluegradient from '../../../assets/images/LandingPage/blue-background.png';

export function HomeCta() {
  return (
    <section className="-mb-20 pt-20">
      <div className="container">
        <div className="relative p-6 md:py-20 flex items-center justify-center flex-col  shadow-[0_4px_4px_0_rgba(0_0_0_0.25)] rounded-3xl bg-[#F3F7FF] ">
          <div className="text-balance text-white-light space-y-6 text-center">
            <h2 className="h3-b xl:h2-b text-blue-dark">
              Enhancing Enterprise Conversations with{' '}
              <span className="text-pink-500">Conversation Intelligence</span>
            </h2>
            <p className="body-l text-grey-dark-100">
              Skilldify.ai’s AI technology enhances conversations by
              understanding and generating human interactions, <br />
              allowing you to achieve your company goals more effectively
            </p>
            <div className="flex flex-col gap-4 md:flex-row justify-center items-center">
              <a href={undefined} className="lp-btn cursor-pointer">
                Schedule Demo
              </a>
              <a href={undefined} className="lp-btn lp-btn-o cursor-pointer">
                Contact Sales
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
