import React from 'react';
import { IconsProps } from './Icons';
export const GraphIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox={viewBox ? viewBox : '0 0 73 73'}
    width={width ? width : '73'}
    height={height ? height : '73'}
    fill={fill ? fill : 'none'}
     >
    <circle cx="36.5" cy="36.5" r="36" fill="#1651D0"/>
    <path d="M30.25 25.5H30.2206L30.1912 25.5017C29.1406 25.5636 28.1321 25.9369 27.2944 26.574C26.724 27.0079 26.2503 27.5508 25.8986 28.1675L25.4333 28.011C25.6223 27.6724 25.8797 27.2751 26.2158 26.882C27.044 25.9131 28.3171 25 30.25 25C32.5091 25 34.1165 26.2267 35.4388 28.2505C36.7855 30.3119 37.7257 33.0383 38.654 35.7467L38.6774 35.815C39.4159 37.9708 40.1732 40.1813 41.1003 41.8509C42.0116 43.4922 43.3021 45 45.25 45H45.2794L45.3087 44.9983C46.3592 44.9365 47.3675 44.5634 48.2051 43.9266C48.7757 43.4928 49.2495 42.9501 49.6013 42.3334L50.0659 42.4895C49.8772 42.8277 49.6201 43.2249 49.284 43.6181C48.456 44.5869 47.1829 45.5 45.25 45.5C43.4791 45.5 42.177 44.5084 41.0476 42.7242C39.8979 40.908 39.0554 38.4649 38.1798 35.9085L38.1613 35.8545C37.3579 33.5066 36.4965 30.9895 35.3573 29.0629C34.2137 27.1288 32.6151 25.5 30.25 25.5Z" stroke="white" strokeWidth="2"/>
    <path d="M21.5 51.5V37.75H24V35.25H21.5V19H19V51.5C19 52.163 19.2634 52.7989 19.7322 53.2678C20.2011 53.7366 20.837 54 21.5 54H54V51.5H21.5Z" fill="white"/>
    <path d="M26.5 35.25H29V37.75H26.5V35.25ZM31.5 35.25H34V37.75H31.5V35.25ZM41.5 35.25H44V37.75H41.5V35.25ZM46.5 35.25H49V37.75H46.5V35.25ZM51.5 35.25H54V37.75H51.5V35.25Z" fill="white"/>
</svg>
  );
};
