import React from "react";
import { IconsProps } from "./Icons";

export const Reload = ({
  className,
  fill,
  width,
  height,
  viewBox,
}:IconsProps) => {
  return (
    <svg      
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 36 36"}
      width={width ? width : "36"}
      height={height ? height : "36"}
      fill={fill ? fill : "none"}
    >
        <path d="M3 18C3 21.5804 4.42232 25.0142 6.95406 27.5459C9.4858 30.0777 12.9196 31.5 16.5 31.5C20.085 31.5 23.52 30.09 26.1 27.6L23.85 25.35C22.9053 26.3497 21.7657 27.145 20.5015 27.6868C19.2373 28.2286 17.8754 28.5053 16.5 28.5C7.14 28.5 2.46 17.19 9.075 10.575C15.69 3.96 27 8.655 27 18H22.5L28.5 24H28.65L34.5 18H30C30 14.4196 28.5777 10.9858 26.0459 8.45406C23.5142 5.92232 20.0804 4.5 16.5 4.5C12.9196 4.5 9.4858 5.92232 6.95406 8.45406C4.42232 10.9858 3 14.4196 3 18Z" fill="#201F20"/>
    </svg>
  );
};
