import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

export interface IOutlineGraphProps {
  date: string;
  avgScore: number;
}

const OutlineGraph = ({
  dateWiseAvgScores,
}: {
  dateWiseAvgScores: IOutlineGraphProps[];
}) => {
  const filteredData = dateWiseAvgScores.filter(
    (entry: IOutlineGraphProps) => entry.avgScore !== null,
  );

  const options = {
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    Tooltip: false,

    tension: 0.3,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Days',
          color: 'black',
          font: {
            size: 7,
            family: 'Arial',
          },
        },
        ticks: {
          color: 'black',
          font: {
            size: 7,
            family: 'Arial',
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: true,

        title: {
          display: true,
          text: 'Quality score',
          color: 'black',
          font: {
            size: 7,
            family: 'Arial',
          },
        },
        ticks: {
          color: 'black',
          callback: function (value: any, index: any, values: any) {
            return Math.round(value * 100) + '%';
          },
          stepSize: 0.1,
          min: 0,
          max: 1,
          font: {
            size: 7,
            family: 'Arial',
          },
        },
        grid: {
          color: '#ebe8e8',
        },
        border: {
          dash: [2, 4],
        },
      },
    },
  };
  const dates = filteredData.map((entry) => {
    const parts = entry.date.split('/');
    const day = parseInt(parts[1]);
    const month = parseInt(parts[0]) - 1;
    const year = parseInt(parts[2]);
    const date = new Date(year, month, day);
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
    return dayOfWeek;
  });

  const datas = {
    labels: dates,
    datasets: [
      {
        label: '',
        labelcolor: 'white',
        data: filteredData.map((entry) => entry.avgScore),
        borderColor: 'rgb(54, 162, 235,0.8)',
        borderWidth: 2,
        backgroundColor: 'rgba(54, 162, 235, 0.3)',
        fill: {
          target: 'origin',
          above: 'rgb(54, 162, 235,0.1)',
        },
      },
    ],
  };
  return (
    <Line
      width={'null'}
      height={'null'}
      color="blue"
      options={options}
      data={datas}
    />
  );
};

export default OutlineGraph;
