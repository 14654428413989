import React from 'react';
import { IconsProps } from './Icons';

export const DashboardIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
      width={width ? width : '24'}
      height={height ? height : '24'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M13.5 9V4H20V9H13.5ZM4 12V4H10.5V12H4ZM13.5 20V12H20V20H13.5ZM4 20V15H10.5V20H4ZM5 11H9.5V5H5V11ZM14.5 19H19V13H14.5V19ZM14.5 8H19V5H14.5V8ZM5 19H9.5V16H5V19Z"
        fill="currentColor"
      />
    </svg>
  );
};
