import * as React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { ArrowDownIcon } from '../../assets/icons';
import faqBG from '../../assets/images/LandingPage/feature-right-arrow.svg';

export function AccordianLanding() {
  return (
    <section className="py-10 lg:py-20 relative bg-white-light">
      <div className="absolute inset-0 z-[-1] w-full h-full">
        <img src={faqBG} className="w-full h-full" />
      </div>
      <Box className="container space-y-10 md:space-y-14">
        <h3 className="text-center mb-4 h3-b xl:h2-b text-blue-dark">
          Frequently Asked Questions
        </h3>
        <Box className="flex flex-col gap-4 md:max-w-[80%] md:mx-auto">
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                1. What is Skilldify.ai, and what does it do?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Skilldify.ai is an AI-powered tool designed for quality
                assurance in customer service interactions. We focus on
                evaluating and improving soft skills in customer-agent
                conversations. Our platform streamlines communication, provides
                feedback, and offers learning modules to enhance the overall
                customer experience.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                2. How does Skilldify.ai assess soft skills?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Skilldify.ai employs advanced AI algorithms to assess 17-18 key
                soft skills parameters. It evaluates these parameters based on
                real customer-agent interactions, providing a comprehensive
                understanding of agent performance.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                3. What sets Skilldify.ai apart from traditional call recording
                tools ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Unlike traditional call recording tools, Skilldify.ai is
                dedicated to call auditing and analysis. We provide actionable
                feedback, simplify communication, and offer targeted learning
                modules to improve agent skills.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                4. How does Skilldify.ai help reduce layers of communication?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Our platform eliminates unnecessary intermediaries by providing
                instant feedback directly to managers and employees' emails.
                This direct communication ensures rapid improvements and reduces
                complexity in the feedback process.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                5. What kind of feedback do managers and employees receive after
                each call?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Both managers and employees receive specific feedback
                highlighting areas for improvement. The feedback is actionable
                and designed to help agents enhance their soft skills and
                overall performance.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                6. Can Skilldify.ai integrate with existing systems and
                workflows?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Yes, Skilldify.ai is designed to be adaptable and can integrate
                seamlessly with existing customer service systems and workflows,
                ensuring minimal disruption to your operations.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                7. Are there any additional services or features offered by
                Skilldify.ai?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                In addition to soft skills assessment and feedback, Skilldify.ai
                provides Learning Management System (LMS) based modules to
                support continuous learning and skill development for your
                agents.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                8. How can I get started with Skilldify.ai for my customer
                service team?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Getting started with Skilldify.ai is easy. Simply contact our
                team, and we'll guide you through the setup and implementation
                process tailored to your specific needs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                9. Is Skilldify.ai suitable for both small and large customer
                service teams?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                Yes, Skilldify.ai is scalable and can be customized to meet the
                needs of both small and large customer service teams, ensuring
                that every agent can benefit from our solutions.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className="lp-accordin bg-[#fff] border border-solid border-[#D7E3FF9E] border-opacity-[62%] rounded-radius-100 shadow-none before:content-[none] ">
            <AccordionSummary
              expandIcon={<ArrowDownIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h4" className="text-grey-dark-200">
                10. What kind of results can I expect from implementing
                Skilldify.ai?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                className="text-black-light leading-relaxed"
              >
                By implementing Skilldify.ai, you can expect improved soft
                skills, enhanced customer experiences, streamlined
                communication, and a culture of continuous improvement, all of
                which contribute to increased customer satisfaction and loyalty.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </section>
  );
}
