import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DetailsPageHeader } from '../../components/details-page/DetailsPageHeader';
import { ICallData } from './staticData';
import { CallResultContainer } from '../../components/details-page/CallsResultContainer';
import { CallDetailsContainer } from '../../components/details-page/CallDetailsContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { callsDetailsPageService } from '../../service/callDetailsPage';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { PAGE_URL } from '../../utils';

export function CallAuditDetailPage() {
  const location = useLocation();
  const router = useNavigate()
  const [callData, setCallData] = useState<ICallData | undefined>(undefined);

  const fetchData = async (id: string) => {
    try {
      const data = await callsDetailsPageService.getCallDetails(id)
        setCallData(data);
    } catch (err:any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const idx = location.pathname.lastIndexOf('/');
    const callId = location.pathname.slice(idx + 1);
    fetchData(callId);
  }, [location.pathname]);

  return (
    <>
      <Box className="base-scrollbar p-6 flex flex-col gap-8 h-[calc(100vh-75px)]">
        <Typography variant="h2" className="text-secondary font-medium flex gap-5 items-center">
        <a className='cursor-pointer' onClick={()=>router(PAGE_URL.CALL_AUDIT.LIST_PAGE)}><ArrowBackIosNewIcon/></a>
          Calls Recording
        </Typography>
        {callData && (
          <>
            <DetailsPageHeader
              callDate={callData.callDatetime.split('T')[0]}
              auditDate={callData.auditDatetime.split('T')[0]}

            />
            <Box className="grid grid-cols-[1fr_1fr] gap-10 ">
              <CallDetailsContainer
                script={callData.auditResult.transcript}
                src={callData.presignedUrl.url}
              />
              <CallResultContainer
                scores={callData.auditResult.scores}
                totalScore={callData.auditResult.total_score}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
