import { apiClient } from './apiClient';
import { ICallData } from '../pages/call-audit/staticData';
import { API_ROUTES } from '../utils';

const getCallDetails = async (id: string): Promise<ICallData> => {
  return apiClient
    .get(`${API_ROUTES.CALLS_LIST}/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const callsDetailsPageService = { getCallDetails };
