import React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

import { Box, Typography } from '@mui/material';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
Chart.register(ArcElement, Tooltip, Legend, Title);
Chart.defaults.plugins.tooltip.backgroundColor = 'rgb(0, 0, 156)';
Chart.defaults.plugins.legend.position = 'right';
Chart.defaults.plugins.legend.title.display = true;

export interface IOutineProps {
  fanatical: number;
  offTrack: number;
  onTrack: number;
}

const OutlinePieChart = ({ fanatical, offTrack, onTrack }: IOutineProps) => {
  const data = {
    labels: [],
    datasets: [
      {
        label: 'Total Calls',
        data: [fanatical, offTrack, onTrack],
        backgroundColor: ['#39e75f', '#ff6868', '#FFA53F'],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '1rem',
        paddingLeft: '1rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          width: 200,

          height: 170,
        }}
      >
        <Doughnut data={data} options={{ cutout: '75%', responsive: false }} />
        <div
          style={{
            position: 'absolute',
            top: '48%',
            left: '46%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            fontFamily: 'Arial',
            fontSize: '16px',
            color: 'black',
          }}
        >
          <Typography className="text-2xl">
            {fanatical + offTrack + onTrack}
          </Typography>

          <Typography className="text-grey-lighter">Total Calls</Typography>
        </div>
      </Box>

      <div className="flex flex-col gap-2 justify-center w-52">
        <div className="flex flex-row gap-3 h-10">
          <div className="h-full bg-green-400 w-1 rounded-md" />
          <div className="flex flex-col">
            <Typography className="text-grey-lighter">Fanatical</Typography>
            <Typography>
              than 85% |{fanatical}{' '}
              <span className="text-grey-lighter">Calls</span>
            </Typography>
          </div>
        </div>
        <div className="flex flex-row gap-3 h-10">
          <div className="h-full bg-orange-400 w-1 rounded-md" />
          <div className="flex flex-col">
            <Typography className="text-grey-lighter">On Track </Typography>
            <Typography>
              {'>50% but <85%'}| {onTrack}{' '}
              <span className="text-grey-lighter">Calls</span>
            </Typography>
          </div>
        </div>
        <div className="flex flex-row gap-3 h-10">
          <div className="h-full bg-red-400 w-1 rounded-md" />
          <div className="flex flex-col">
            <Typography className="text-grey-lighter">Off Track</Typography>
            <Typography>
              {'<50%'} | {offTrack}{' '}
              <span className="text-grey-lighter">Calls</span>
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default OutlinePieChart;
