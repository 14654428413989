import { Typography } from '@mui/material';
import React from 'react';
import { ListpageFilter } from '../ListpageFilter';
import { ListpageOperations } from '../ListpageOperations';


interface IProps {
  search: IFilterProps;
  filter: IFilterProps;
  setRefetch: () => void;
}

export interface IFilterProps {
  value: string;
  setValue: (value: string) => void;
}

export function ListpageHeader({ search, filter, setRefetch }: IProps) {
  return (
    <>
      <Typography variant="h1" className='flex items-center gap-5'>Calls Audits</Typography>
      <div className="grid xmd:grid-cols-[auto_1fr] gap-4 xmd:gap-6 xl:gap-[180px] items-center">
        <ListpageFilter {...filter} setRefetch={setRefetch}/>
        <ListpageOperations {...search} setRefetch={setRefetch} />
      </div>
    </>
  );
}
