import React from 'react';
import { HomeFeature } from '../Home/HomeFeature';
import LandingSubHeader from '../../../components/common/LandingSubHeader';

export function SkilldifyAnalysis() {
  return (
    <>
      <LandingSubHeader
        title="AI-Powered"
        colortitle="Analysis"
        description="Gain access to a comprehensive 100% conversation analysis, offering unparalleled insights into every interaction."
      />
      <HomeFeature name="featureAnalysisDetail" />
    </>
  );
}
