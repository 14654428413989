import { createContext, useContext, useState } from 'react';

export type ModalType = 'Upload' | 'Success';
export interface IState {
  defaultUsername: string;
  defaultPassword: string;
  isUserLoggedIn: boolean;
  toggleLogin: (param: boolean) => void;
  modalType: ModalType | undefined;
  isModalOpen: boolean;
  openModal: (type: ModalType, onCloseCallback?: any) => void;
  closeModal: () => void;
}
const initialState: IState = {
  isUserLoggedIn: false,
  defaultPassword: 'Abc@1234',  defaultUsername: 'snarula@ex2india.com',
  openModal: () => {},
  closeModal: () => {},
  toggleLogin: () => {},
  modalType: undefined,
  isModalOpen: false,
};

export const GlobalContext = createContext<IState>(initialState);

export const useAppGlobalContext = () => {
  const { defaultPassword, defaultUsername } = initialState;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    initialState.isUserLoggedIn,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined);
  const [closeModalCallback, setCloseModalCallback] = useState<() => void>(
    () => () => {},
  );

  const toggleLogin = (value: boolean) => {
    setIsLoggedIn(value);
    localStorage.setItem('isUserLoggedIn', value ? "true" : "false");
  };

  const openModal = (type: ModalType, onCloseCallback?: () => void) => {
    setModalType(type);
    setIsModalOpen(true);
    if (onCloseCallback) {
      setCloseModalCallback(() => () => {
        onCloseCallback();
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalType(undefined);
    closeModalCallback();
  };

  return {
    isUserLoggedIn: isLoggedIn,
    defaultUsername,
    defaultPassword,
    toggleLogin,
    modalType,
    isModalOpen,
    openModal,
    closeModal,
  };
};

// ... (rest of the code)

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
