import React from 'react';
import { IconsProps } from './Icons';
export const CrossIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 14 15'}
      width={width ? width : '14'}
      height={height ? height : '15'}
      fill={fill ? fill : 'none'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.470072 0.96641C0.610698 0.82596 0.801322 0.74707 1.00007 0.74707C1.19882 0.74707 1.38945 0.82596 1.53007 0.96641L13.5301 12.9664C13.6038 13.0351 13.6629 13.1179 13.7039 13.2099C13.7448 13.3019 13.7669 13.4012 13.7687 13.5019C13.7704 13.6026 13.7519 13.7026 13.7142 13.796C13.6765 13.8894 13.6203 13.9742 13.5491 14.0454C13.4779 14.1167 13.3931 14.1728 13.2997 14.2105C13.2063 14.2483 13.1063 14.2668 13.0056 14.265C12.9048 14.2632 12.8055 14.2412 12.7135 14.2002C12.6215 14.1592 12.5387 14.1001 12.4701 14.0264L0.470072 2.02641C0.329622 1.88579 0.250732 1.69516 0.250732 1.49641C0.250732 1.29766 0.329622 1.10704 0.470072 0.96641Z"
        fill="#201F20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5301 0.96641C13.6705 1.10704 13.7494 1.29766 13.7494 1.49641C13.7494 1.69516 13.6705 1.88579 13.5301 2.02641L1.53009 14.0264C1.38792 14.1589 1.19987 14.231 1.00557 14.2276C0.811267 14.2242 0.625882 14.1454 0.488469 14.008C0.351056 13.8706 0.272344 13.6852 0.268916 13.4909C0.265487 13.2966 0.33761 13.1086 0.47009 12.9664L12.4701 0.96641C12.6107 0.82596 12.8013 0.74707 13.0001 0.74707C13.1988 0.74707 13.3895 0.82596 13.5301 0.96641Z"
        fill="#201F20"
      />
    </svg>
  );
};
