import { apiClient } from './apiClient';

interface IUserLoginRequest {
  email: string;
  password: string;
}

interface IUserLoginResponse {
  email: string;
  name: string;
  access_token: string;
}

const login = async (requestObject: IUserLoginRequest) => {
  const res = await apiClient.post<IUserLoginResponse>(
    `/auth/login`,
    requestObject,
  );
  return res;
};

export const authService = {
  login,
};
