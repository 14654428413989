import React from 'react';
import { HomeFeature } from '../Home/HomeFeature';
import LandingSubHeader from '../../../components/common/LandingSubHeader';

export function SkilldifyQA() {
  return (
    <>
      <LandingSubHeader
        title="Skilldify"
        colortitle="QA"
        description="Expand Your Quality Assurance Capabilities with Skilldify.Ai"
      />
      <HomeFeature name="featureQaDetail" />
    </>
  );
}
