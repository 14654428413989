import React from 'react';
import { IconsProps } from './Icons';

export const SpeakerTableShadowIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 198 25'}
      width={width ? width : '198'}
      height={height ? height : '25'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M98.7715 24.969C153.045 24.969 197.043 19.4335 197.043 12.6052C197.043 5.77681 153.045 0.241333 98.7715 0.241333C44.4976 0.241333 0.5 5.77681 0.5 12.6052C0.5 19.4335 44.4976 24.969 98.7715 24.969Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};
