import React from 'react';
import { IconsProps } from './Icons';

export const UploadIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
      width={width ? width : '24'}
      height={height ? height : '24'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M18.2199 20.75H5.77994C5.43316 20.7359 5.09256 20.6535 4.77765 20.5076C4.46274 20.3616 4.17969 20.1551 3.9447 19.8996C3.70971 19.6442 3.52739 19.345 3.40818 19.019C3.28896 18.693 3.23519 18.3468 3.24994 18V15C3.24994 14.8011 3.32896 14.6103 3.46961 14.4697C3.61027 14.329 3.80103 14.25 3.99994 14.25C4.19886 14.25 4.38962 14.329 4.53027 14.4697C4.67093 14.6103 4.74994 14.8011 4.74994 15V18C4.72412 18.2969 4.81359 18.5924 4.99977 18.8251C5.18596 19.0579 5.45459 19.21 5.74994 19.25H18.2199C18.5153 19.21 18.7839 19.0579 18.9701 18.8251C19.1563 18.5924 19.2458 18.2969 19.2199 18V15C19.2199 14.8011 19.299 14.6103 19.4396 14.4697C19.5803 14.329 19.771 14.25 19.9699 14.25C20.1689 14.25 20.3596 14.329 20.5003 14.4697C20.6409 14.6103 20.7199 14.8011 20.7199 15V18C20.7499 18.6954 20.504 19.3744 20.0358 19.8895C19.5676 20.4045 18.915 20.7137 18.2199 20.75ZM15.9999 8.75001C15.9014 8.75048 15.8038 8.73126 15.7128 8.69348C15.6217 8.6557 15.5392 8.60012 15.4699 8.53001L11.9999 5.06001L8.52994 8.53001C8.38777 8.66249 8.19972 8.73461 8.00542 8.73119C7.81112 8.72776 7.62573 8.64905 7.48832 8.51163C7.35091 8.37422 7.2722 8.18884 7.26877 7.99453C7.26534 7.80023 7.33746 7.61219 7.46994 7.47001L11.4699 3.47001C11.6106 3.32956 11.8012 3.25067 11.9999 3.25067C12.1987 3.25067 12.3893 3.32956 12.5299 3.47001L16.5299 7.47001C16.6704 7.61064 16.7493 7.80126 16.7493 8.00001C16.7493 8.19876 16.6704 8.38939 16.5299 8.53001C16.4607 8.60012 16.3781 8.6557 16.2871 8.69348C16.1961 8.73126 16.0985 8.75048 15.9999 8.75001Z"
        fill="white"
      />
      <path
        d="M12 15.75C11.8019 15.7474 11.6126 15.6676 11.4725 15.5275C11.3324 15.3874 11.2526 15.1981 11.25 15V4C11.25 3.80109 11.329 3.61032 11.4697 3.46967C11.6103 3.32902 11.8011 3.25 12 3.25C12.1989 3.25 12.3897 3.32902 12.5303 3.46967C12.671 3.61032 12.75 3.80109 12.75 4V15C12.7474 15.1981 12.6676 15.3874 12.5275 15.5275C12.3874 15.6676 12.1981 15.7474 12 15.75Z"
        fill="white"
      />
    </svg>
  );
};
