import React from 'react';

import { useGlobalContext } from "../../contexts/useGlobalContext";
import { UploadModal } from "./UploadModal";
export interface ICallsListItem {
  id: string;
  name: string;
  callDate: string;
  auditDate: string;
  type: string;
  feedback: number;
}

export function Modal() {
  const { isModalOpen, modalType } = useGlobalContext();
  return (
    <>
      {isModalOpen && modalType ? (
        <>{modalType === "Upload" && <UploadModal />}</>
      ) : null}
    </>
  );
}
