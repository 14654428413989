import React from 'react';
import { IconsProps } from './Icons';

export const SpeakerBgIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 569 251'}
      width={width ? width : '569'}
      height={height ? height : '251'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M149.796 20.0676H10.1006V242.438H149.796V20.0676Z"
        fill="#EBEBEB"
      />
      <path
        d="M142.774 26.5158H15.6826V235.814H142.774V26.5158Z"
        fill="#FAFAFA"
      />
      <path
        d="M155.704 244.443L149.796 242.438V16.296H155.704V244.443Z"
        fill="#E0E0E0"
      />
      <path
        d="M46.8398 161.711C57.842 152.649 68.8442 143.588 79.8463 134.526C84.0286 131.082 89.495 127.371 94.8749 129.069C97.4621 129.886 99.4088 131.842 101.945 132.779C107.012 134.649 112.801 131.949 116.697 128.522C120.593 125.095 123.668 120.809 128.388 118.325C133.108 115.841 140.442 116.156 142.776 120.559V150.146L94.8083 168.344L46.8408 161.71L46.8398 161.711Z"
        fill="#F5F5F5"
      />
      <path
        d="M15.6826 144.706C27.8454 140.317 39.9824 144.047 48.6881 152.76C50.5207 154.595 52.1889 156.713 54.7068 157.725C58.1163 159.095 62.0552 158.069 65.7745 157.696C75.9412 156.675 86.5603 161.236 91.9544 168.94L51.3467 186.639L15.6826 186.409V144.706Z"
        fill="#E0E0E0"
      />
      <path
        d="M142.774 144.853C134.764 141.425 127.712 145.572 120.321 149.96C112.931 154.348 106.064 159.975 97.3942 161.743C91.6018 162.924 85.3949 162.269 79.7955 164.036C68.3875 167.635 61.7909 180.472 49.6995 181.085C43.1343 181.417 37.2459 177.891 31.3869 175.245C25.528 172.6 20.9066 170.088 15.6816 173.626V235.813H142.773V144.852L142.774 144.853Z"
        fill="#EBEBEB"
      />
      <path
        d="M145.535 86.7252H15.6826V88.9538H145.535V86.7252Z"
        fill="#E0E0E0"
      />
      <path
        d="M145.535 136.567H15.6826V138.796H145.535V136.567Z"
        fill="#E0E0E0"
      />
      <path
        d="M145.535 186.409H15.6826V188.638H145.535V186.409Z"
        fill="#E0E0E0"
      />
      <path
        d="M76.0166 87.8395L76.0166 241.749H82.4382L82.4382 87.8395H76.0166Z"
        fill="#E0E0E0"
      />
      <path
        d="M43.0629 26.5158H40.1543V87.8395H43.0629V26.5158Z"
        fill="#E0E0E0"
      />
      <path
        d="M68.1517 26.5158H65.2432V87.8395H68.1517V26.5158Z"
        fill="#E0E0E0"
      />
      <path
        d="M93.2416 26.5158H90.333V87.8395H93.2416V26.5158Z"
        fill="#E0E0E0"
      />
      <path
        d="M118.33 26.5158H115.422V87.8395H118.33V26.5158Z"
        fill="#E0E0E0"
      />
      <path
        d="M10.1008 16.296H4.19238V243.815H10.1008V16.296Z"
        fill="#E0E0E0"
      />
      <path d="M155.705 14H4.19238V20.0676H155.705V14Z" fill="#E0E0E0" />
      <path d="M159.704 245.191H0V250.844H159.704V245.191Z" fill="#E0E0E0" />
      <path
        d="M149.796 242.439L159.704 245.191H0L10.1001 242.439H149.796Z"
        fill="#EBEBEB"
      />
      <path
        d="M390.131 172.897L382.461 172.933L382.665 206.752L390.336 206.715L390.131 172.897Z"
        fill="#E0E0E0"
      />
      <path
        d="M335.875 172.98L316.013 173.075L316.126 191.745L335.988 191.65L335.875 172.98Z"
        fill="#EBEBEB"
      />
      <path
        d="M355.644 172.887L335.781 172.981L335.894 191.651L355.757 191.556L355.644 172.887Z"
        fill="#F5F5F5"
      />
      <path
        d="M316.044 178.233C329.244 178.33 342.477 178.261 355.674 178.071C355.946 178.068 355.944 177.698 355.672 177.697C342.473 177.632 329.24 177.69 316.042 177.912C315.809 177.916 315.811 178.231 316.044 178.233Z"
        fill="#E0E0E0"
      />
      <path
        d="M339.716 191.341L303.63 191.512L303.722 206.727L339.808 206.556L339.716 191.341Z"
        fill="#EBEBEB"
      />
      <path
        d="M375.632 191.171L339.546 191.342L339.638 206.557L375.724 206.386L375.632 191.171Z"
        fill="#F5F5F5"
      />
      <path
        d="M303.656 195.744C327.63 195.984 351.687 195.768 375.659 195.429C375.931 195.425 375.929 195.056 375.657 195.055C351.683 194.943 327.623 194.956 303.654 195.424C303.421 195.428 303.423 195.741 303.656 195.744Z"
        fill="#E0E0E0"
      />
      <path
        d="M429.973 206.728H290.16V212.179H429.973V206.728Z"
        fill="#E0E0E0"
      />
      <path
        d="M318.726 209.348H313.051V227.948H318.726V209.348Z"
        fill="#E0E0E0"
      />
      <path
        d="M403.451 209.348H397.775V227.948H403.451V209.348Z"
        fill="#E0E0E0"
      />
      <path
        d="M397.617 160.282L389.946 160.318L390.228 206.831L397.898 206.794L397.617 160.282Z"
        fill="#EBEBEB"
      />
      <path
        d="M409.601 168.938L397.683 168.995L397.911 206.689L409.83 206.632L409.601 168.938Z"
        fill="#F5F5F5"
      />
      <path
        d="M409.52 176.204C409.52 176.305 406.852 176.386 403.561 176.386C400.271 176.386 397.602 176.305 397.602 176.204C397.602 176.103 400.269 176.021 403.561 176.021C406.854 176.021 409.52 176.103 409.52 176.204Z"
        fill="#E0E0E0"
      />
      <path
        d="M409.52 174.62C409.52 174.721 406.852 174.803 403.561 174.803C400.271 174.803 397.602 174.721 397.602 174.62C397.602 174.519 400.269 174.438 403.561 174.438C406.854 174.438 409.52 174.519 409.52 174.62Z"
        fill="#E0E0E0"
      />
      <path
        d="M409.52 200.963C409.52 201.064 406.852 201.146 403.561 201.146C400.271 201.146 397.602 201.064 397.602 200.963C397.602 200.862 400.269 200.781 403.561 200.781C406.854 200.781 409.52 200.862 409.52 200.963Z"
        fill="#E0E0E0"
      />
      <path
        d="M409.52 199.38C409.52 199.481 406.852 199.563 403.561 199.563C400.271 199.563 397.602 199.481 397.602 199.38C397.602 199.279 400.269 199.197 403.561 199.197C406.854 199.197 409.52 199.279 409.52 199.38Z"
        fill="#E0E0E0"
      />
      <path
        d="M403.753 195.925C403.639 195.925 403.547 192.122 403.547 187.432C403.547 182.741 403.639 178.938 403.753 178.938C403.867 178.938 403.959 182.74 403.959 187.432C403.959 192.123 403.867 195.925 403.753 195.925Z"
        fill="#E0E0E0"
      />
      <path
        d="M422.622 178.198H409.649V206.649H422.622V178.198Z"
        fill="#EBEBEB"
      />
      <path
        d="M395.872 168.224C395.872 169.303 394.886 170.177 393.668 170.177C392.45 170.177 391.464 169.303 391.464 168.224C391.464 167.145 392.45 166.271 393.668 166.271C394.886 166.271 395.872 167.145 395.872 168.224Z"
        fill="#E0E0E0"
      />
      <path
        d="M394.349 173.597H392.986V201.267H394.349V173.597Z"
        fill="#E0E0E0"
      />
      <path
        d="M568.691 0.481995H486.495V141.863H568.691V0.481995Z"
        fill="#E0E0E0"
      />
      <path
        d="M563.122 0.481995H480.927V141.863H563.122V0.481995Z"
        fill="#FAFAFA"
      />
      <path
        d="M563.148 141.904H480.902V0.438965H563.148V141.904ZM480.953 141.82H563.096V0.52484H480.953V141.82Z"
        fill="#E0E0E0"
      />
      <path
        d="M552.073 19.4864H491.977V122.856H552.073V19.4864Z"
        fill="#FAFAFA"
      />
      <path
        d="M491.977 123.543C492.023 123.636 491.453 124.716 490.493 126.389C489.533 128.058 488.184 130.32 486.674 132.779C485.162 135.238 483.772 137.427 482.748 138.98C481.723 140.535 481.063 141.452 481.011 141.366C480.959 141.28 481.526 140.205 482.486 138.532C483.446 136.862 484.798 134.594 486.315 132.131C487.831 129.667 489.224 127.471 490.248 125.919C491.273 124.364 491.93 123.451 491.978 123.543H491.977Z"
        fill="#E0E0E0"
      />
      <path
        d="M552.074 123.29C552.13 123.211 552.786 124.154 553.798 125.738C554.811 127.322 556.182 129.548 557.671 132.04C559.161 134.535 560.486 136.829 561.429 138.519C562.371 140.209 562.927 141.298 562.875 141.384C562.823 141.47 562.171 140.535 561.157 138.951C560.145 137.366 558.77 135.134 557.276 132.632C555.784 130.131 554.454 127.833 553.512 126.142C552.569 124.452 552.016 123.367 552.072 123.29H552.074Z"
        fill="#E0E0E0"
      />
      <path
        d="M551.91 19.7621C551.863 19.6694 552.433 18.587 553.393 16.9171C554.353 15.2448 555.701 12.9828 557.213 10.5265C558.724 8.06786 560.115 5.88042 561.139 4.32571C562.163 2.771 562.823 1.85354 562.875 1.93941C562.927 2.02528 562.36 3.10093 561.4 4.77315C560.44 6.44537 559.089 8.7119 557.571 11.1773C556.055 13.6404 554.663 15.8346 553.638 17.3893C552.614 18.9441 551.956 19.8547 551.91 19.7621Z"
        fill="#E0E0E0"
      />
      <path
        d="M491.813 20.0174C491.756 20.0965 491.102 19.1542 490.088 17.5701C489.075 15.986 487.705 13.7602 486.215 11.2677C484.725 8.77294 483.398 6.47928 482.458 4.78898C481.515 3.09869 480.958 2.0095 481.011 1.92362C481.063 1.83775 481.715 2.77328 482.729 4.35737C483.742 5.94145 485.116 8.17184 486.608 10.6756C488.103 13.1772 489.431 15.4753 490.373 17.1656C491.315 18.8559 491.869 19.9406 491.813 20.0174Z"
        fill="#E0E0E0"
      />
      <path
        d="M551.91 122.856C551.91 122.856 551.901 122.68 551.897 122.355C551.895 122.002 551.89 121.528 551.884 120.913C551.877 119.616 551.869 117.754 551.856 115.334C551.843 110.437 551.822 103.312 551.796 94.2661C551.767 76.161 551.727 50.3704 551.678 19.4887L551.91 19.8706C534.414 19.8864 513.869 19.9067 491.983 19.927H491.978L492.244 19.4887C492.23 57.4299 492.216 92.9238 492.207 122.856L491.978 122.479C509.936 122.558 524.89 122.626 535.373 122.671C540.61 122.714 544.731 122.746 547.562 122.768C548.96 122.786 550.036 122.802 550.787 122.811C551.141 122.82 551.415 122.827 551.619 122.834C551.807 122.841 551.906 122.85 551.91 122.856C551.914 122.863 551.825 122.868 551.645 122.877C551.447 122.881 551.181 122.888 550.836 122.897C550.096 122.906 549.035 122.922 547.655 122.94C544.835 122.963 540.733 122.997 535.517 123.037C524.998 123.085 509.995 123.15 491.977 123.229H491.748V122.852C491.737 92.917 491.725 57.4231 491.711 19.4841V19.0457H491.977H491.982C513.868 19.0661 534.413 19.0842 551.908 19.1022H552.141V19.4841C552.092 50.4676 552.051 76.3418 552.023 94.5057C551.997 103.515 551.977 110.611 551.962 115.487C551.951 117.876 551.941 119.715 551.934 120.994C551.929 121.593 551.925 122.054 551.922 122.395C551.918 122.707 551.914 122.861 551.908 122.854L551.91 122.856Z"
        fill="#E0E0E0"
      />
      <path
        d="M532.052 54.6279H511.999V87.7174H532.052V54.6279Z"
        fill="#EBEBEB"
      />
    </svg>
  );
};
