import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthUser {
  access_token: string;
  name: string;
  email: string;
}

interface IAuthUserSlice {
  user: IAuthUser;
}

export const authUserInitialState: IAuthUserSlice = {
  user: {} as IAuthUser,
};

export const authUserSlice = createSlice({
  name: 'auth',
  initialState: authUserInitialState,
  reducers: {
    loginUser: (
      state: IAuthUserSlice,
      { payload }: PayloadAction<IAuthUser>,
    ) => {
      state.user = { ...payload };
    },
    logoutUser: (state: IAuthUserSlice) => {
      state.user = {} as IAuthUser;
    },
  },
});

export const { loginUser, logoutUser } = authUserSlice.actions;

export default authUserSlice.reducer;
