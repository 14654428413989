import React from 'react';
import { IconsProps } from './Icons';
export const BookIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox={viewBox ? viewBox : '0 0 54 54'}
    width={width ? width : '54'}
    height={height ? height : '54'}
    fill={fill ? fill : 'none'}
    >
      <ellipse cx="27" cy="27" rx="27" ry="26.5" fill="#FF9C27" />
      <path
        d="M27.0001 19.5523C29.5391 18.1694 32.0801 17.4761 34.6191 17.4761C37.1582 17.4761 39.6991 18.1694 42.2382 19.5523V38.4285C39.6991 37.158 37.1582 36.5237 34.6191 36.5237C32.0801 36.5237 29.5391 37.158 27.0001 38.4285M27.0001 19.5523V38.4285M27.0001 19.5523C24.461 18.1694 21.9201 17.4761 19.381 17.4761C16.842 17.4761 14.301 18.1694 11.762 19.5523V38.4285C14.301 37.158 16.842 36.5237 19.381 36.5237C21.9201 36.5237 24.461 37.158 27.0001 38.4285"
        stroke="white"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
