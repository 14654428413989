import React from 'react';
import { IconsProps } from './Icons';
export const FileUploadIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 46 41'}
      width={width ? width : '46'}
      height={height ? height : '41'}
      fill={fill ? fill : 'none'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 25.5038H38C39.9891 25.5038 41.8968 24.7136 43.3033 23.3071C44.7098 21.9005 45.5 19.9929 45.5 18.0038C45.5 16.0146 44.7098 14.107 43.3033 12.7005C41.8968 11.2939 39.9891 10.5038 38 10.5038C37.3 10.5038 36.95 10.5038 36.7075 10.4538C35.9625 10.3038 35.6075 10.0763 35.1675 9.45625C35.0225 9.25625 34.8175 8.80125 34.41 7.89125C33.4247 5.68897 31.823 3.81891 29.7984 2.50673C27.7738 1.19456 25.4127 0.496338 23 0.496338C20.5873 0.496338 18.2262 1.19456 16.2016 2.50673C14.177 3.81891 12.5753 5.68897 11.59 7.89125C11.1825 8.80125 10.9775 9.25375 10.8325 9.45625C10.3925 10.0763 10.0375 10.3063 9.2925 10.4563C9.0475 10.5038 8.7 10.5038 8 10.5038C6.01088 10.5038 4.10322 11.2939 2.6967 12.7005C1.29018 14.107 0.5 16.0146 0.5 18.0038C0.5 19.9929 1.29018 21.9005 2.6967 23.3071C4.10322 24.7136 6.01088 25.5038 8 25.5038Z"
        fill="#1651D0"
        fill-opacity="0.13"
      />
      <path
        d="M16.75 21.7537L23 15.5037M23 15.5037L29.25 21.7537M23 15.5037V40.5037"
        stroke="#1651D0"
        strokeLinecap="round"
      />
    </svg>
  );
};
