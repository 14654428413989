import React from 'react';
import { HomeTestimonials } from '../Home/HomeTestimonials';
import { HomeFeature } from '../Home/HomeFeature';
import LandingSubHeader from '../../../components/common/LandingSubHeader';

export function AssessingAgentPerformance() {
  return (
    <>
      <LandingSubHeader
        title="Assessing"
        colortitle="Agent Performance"
        description="Engage Your Agents Effectively with Skilldify.ai"
        showCTA={true}
      />
      <HomeFeature name="featureAgentDetail" />
      <HomeTestimonials />
    </>
  );
}
