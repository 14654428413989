import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

interface IProps {
  columns: string[];
}

export function TableHeader(props: IProps) {
  return (
    <TableHead className='table-last-cell-center'>
      <TableRow className="text-[#052364]">
        {/* <TableCell padding="checkbox">
          <Checkbox color="primary" />
        </TableCell> */}
        <TableCell>{props.columns[0]}</TableCell>
        {props.columns.slice(1).map((column, id) => (
          <TableCell key={id}>
            {column}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
