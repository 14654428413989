import React from "react";
import { IconsProps } from "./Icons";

export const PlusIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}:IconsProps) => {
  return (
    <svg      
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 25 24"}
      width={width ? width : "25"}
      height={height ? height : "24"}
      fill={fill ? fill : "none"}
    >
        <path d="M18.5 12.998H13.5V17.998C13.5 18.2633 13.3946 18.5176 13.2071 18.7052C13.0196 18.8927 12.7652 18.998 12.5 18.998C12.2348 18.998 11.9804 18.8927 11.7929 18.7052C11.6054 18.5176 11.5 18.2633 11.5 17.998V12.998H6.5C6.23478 12.998 5.98043 12.8927 5.79289 12.7052C5.60536 12.5176 5.5 12.2633 5.5 11.998C5.5 11.7328 5.60536 11.4785 5.79289 11.2909C5.98043 11.1034 6.23478 10.998 6.5 10.998H11.5V5.99805C11.5 5.73283 11.6054 5.47848 11.7929 5.29094C11.9804 5.1034 12.2348 4.99805 12.5 4.99805C12.7652 4.99805 13.0196 5.1034 13.2071 5.29094C13.3946 5.47848 13.5 5.73283 13.5 5.99805V10.998H18.5C18.7652 10.998 19.0196 11.1034 19.2071 11.2909C19.3946 11.4785 19.5 11.7328 19.5 11.998C19.5 12.2633 19.3946 12.5176 19.2071 12.7052C19.0196 12.8927 18.7652 12.998 18.5 12.998Z" fill="white"/>
    </svg>
  );
};
