export function msToTime(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const padStartFunc = (num: number, padLength = 2) =>
    num.toString().padStart(padLength, '0');

  return (
    padStartFunc(hours) +
    ':' +
    padStartFunc(minutes) +
    ':' +
    padStartFunc(seconds)
  );
}

export function getFormattedDateTimeFromISO(isoDate: string | undefined) {
  let formattedDate = '-';
  let formattedTime = '';
  if (!isoDate) return { formattedDate, formattedTime };
  const date = new Date(isoDate);
  formattedDate = date.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return { formattedDate, formattedTime };
}
