import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const useAuthUser = () => {
  const { user } = useSelector((state: RootState) => state.authUser);
  const { access_token, name, email } = user;

  return {
    name,
    accessToken: access_token,
    email,
  };
};
