import React from 'react';
import { HomeTestimonials } from '../Home/HomeTestimonials';
import { HomeFeature } from '../Home/HomeFeature';
import LandingSubHeader from '../../../components/common/LandingSubHeader';

export function ElevatingCustomerExperiences() {
  return (
    <>
      <LandingSubHeader
        title="Elevating Customer"
        colortitle="Experiences with Skilldify.AI"
        description=""
        showCTA={true}
      />
      <HomeFeature name="featureCustomerDetail" />
      <HomeTestimonials />
    </>
  );
}
