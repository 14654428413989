import React from 'react';
import { IconsProps } from './Icons';

export const UserIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 22 22'}
      width={width ? width : '22'}
      height={height ? height : '22'}
      fill={fill ? fill : 'none'}
    >
      <circle cx="11" cy="11" r="11" fill="#D7E3FF" fill-opacity="0.62" />
      <path
        d="M7 15.5V15C7 13.3432 8.34315 12 10 12H12C13.6568 12 15 13.3432 15 15V15.5"
        fill="#D7E3FF"
        fill-opacity="0.62"
      />
      <path
        d="M7 15.5V15C7 13.3432 8.34315 12 10 12H12C13.6568 12 15 13.3432 15 15V15.5"
        stroke="#0D56BE"
        strokeLinecap="round"
      />
      <path
        d="M11 10.5C9.89543 10.5 9 9.60457 9 8.5C9 7.39543 9.89543 6.5 11 6.5C12.1046 6.5 13 7.39543 13 8.5C13 9.60457 12.1046 10.5 11 10.5Z"
        stroke="#0D56BE"
        strokeLinecap="round"
      />
    </svg>
  );
};
