import { configureStore } from '@reduxjs/toolkit';
import { Store as ReduxStore } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createReducer, { initialState } from './reducers';
export * from './actions';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['authUser'],
};

export type Store = ReduxStore<typeof initialState>;
const createStore = (): Store => {
  const persistedReducer = persistReducer(persistConfig, createReducer());
  const customStore = configureStore({
    reducer: persistedReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });
  return customStore;
};

const getState = createStore().getState;
export type RootState = ReturnType<typeof getState>;

export default createStore;
