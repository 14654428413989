/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import {
  LoginLogoIcon,
  ArrowDownIcon,
  CrossIcon,
  HamburgerIcon,
} from '../../assets/icons';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate, useLocation } from 'react-router-dom';
import { PAGE_URL } from '../../utils';

const ACTIVE_URL = {
  HOME: '/',
  WHYUS: '/whyus',
  SKILLDIFYANALYSIS: '/skilldify-analysis',
  SKILLDIFYLEARNING: '/skilldify-learning',
  SKILLDIFYQA: '/skilldify-qa',
  AGENTPERFORMANCE: '/assessing-agent-performance',
  ELEVATINGCUSTOMEREXPERIENCES: '/elevating-customer-experiences',
  EXPANDQA: '/expand-qa',
  INTUITIVEDASHBOARD: '/intuitive-dashboard',
};
export function LandingHeader() {
  const [toggle, setToggle] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const [toggleProducts, setToggleProducts] = useState(false);
  const [toggleUseCases, setToggleUseCases] = useState(false);

  const goPageTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const handleMenuOpen = () => {
    setToggle((toggle) => !toggle);
    document.body.classList.toggle('scroll-hidden');
  };

  const setToggleProduct = () => {
    setToggleProducts((toggleProducts) => !toggleProducts);
  };
  const setToggleUseCase = () => {
    setToggleUseCases((toggleUseCases) => !toggleUseCases);
  };
  const navigat = useNavigate();
  const navigate = (url: string) => {
    navigat(url);
    goPageTop();
  };
  return (
    <header className="py-[11px] z-50 bg-white-light sticky top-0 border-b border-solid border-[rgb(215_227_255_/_0.42)]">
      <div className="container f-ic-jb flex-wrap">
        <a
          onClick={() => navigate(PAGE_URL.HOME)}
          className="f-ic cursor-pointer"
        >
          <LoginLogoIcon />
        </a>
        <div className="f-ic gap-3 lg:order-2 lg:*:min-w-[132px]">
          <a href="/login" className="lg:lp-btn">
            <LoginIcon className=" lg:hidden" />
            <span className="hidden lg:inline-block">Login</span>
          </a>
          <button
            onClick={handleMenuOpen}
            data-collapse-toggle="mobile-menu-2"
            type="button"
            className="inline-flex items-center justify-center w-10 h-10 p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
          >
            {toggle ? (
              <CrossIcon className="w-4 h-4 fill-grey-darker" />
            ) : (
              <HamburgerIcon className="w-6 h-6 fill-grey-darker" />
            )}
          </button>
        </div>
        <div
          ref={ref}
          className={`${toggle ? 'visible' : 'hidden'} absolute bg-white-light top-full left-0 h-screen lg:h-auto lg:static justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
          id="mobile-menu-2"
        >
          <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:gap-4 lg:mt-0 body-l">
            <li>
              <a
                onClick={() => {
                  navigate(PAGE_URL.HOME);
                  setToggle(!toggle);
                  document.body.classList.remove('scroll-hidden');
                }}
                className={`block cursor-pointer py-2 px-4 text-black-light hover:text-blue-light  hover:bg-gray-50 rounded-full ${window.location.pathname === ACTIVE_URL.HOME ? 'text-blue-light bg-gray-50' : ''} `}
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li className="">
              <a
                onClick={setToggleProduct}
                // eslint-disable-next-line no-script-url
                href={undefined}
                className="flex cursor-pointer justify-between gap-1 items-center py-2 px-4 text-black-light lg:hover:bg-gray-50 rounded-full group-hover:text-blue-light group-hover:bg-gray-50"
              >
                <span>Products</span>
                <i className="w-4 h-4 mt-1 group-hover:mt-0 group-hover:rotate-180  transition-transform">
                  <ArrowDownIcon className="w-full h-full" />
                </i>
              </a>
              <div
                id="dropdown"
                className={`z-10 ${toggleProducts ? '' : 'hidden'} group-hover:block bg-white divide-y divide-gray-100 lg:rounded-lg lg:shadow-lp-card lg:absolute bg-gray-50 lg:bg-white-light pl-5 lg:pl-0`}
              >
                <ul
                  className="py-2 text-sm text-black-light"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <a
                      onClick={() => {
                        setToggleProducts((toggleProducts) => !toggleProducts);
                        navigate(PAGE_URL.SKILLDIFYQA);
                        setToggle(!toggle);
                        document.body.classList.remove('scroll-hidden');
                      }}
                      className={`block cursor-pointer px-4 py-2 hover:bg-gray-100 hover:text-blue-light ${window.location.pathname === ACTIVE_URL.SKILLDIFYQA ? 'bg-gray-100 text-blue-light' : ''}`}
                    >
                      Skilldify QA
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setToggleProducts((toggleProducts) => !toggleProducts);
                        navigate(PAGE_URL.SKILLDIFYLEARNING);
                        setToggle(!toggle);
                        document.body.classList.remove('scroll-hidden');
                      }}
                      className={`block cursor-pointer px-4 py-2 hover:bg-gray-100 hover:text-blue-light ${window.location.pathname === ACTIVE_URL.SKILLDIFYLEARNING ? 'bg-gray-100 text-blue-light' : ''}`}
                    >
                      Skilldify Learning
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setToggleProducts((toggleProducts) => !toggleProducts);
                        navigate(PAGE_URL.SKILLDIFYANALYSIS);
                        setToggle(!toggle);
                        document.body.classList.remove('scroll-hidden');
                      }}
                      className={`block cursor-pointer px-4 py-2 hover:bg-gray-100 hover:text-blue-light ${window.location.pathname === ACTIVE_URL.SKILLDIFYANALYSIS ? 'bg-gray-100 text-blue-light' : ''}`}
                    >
                      Skilldify Analysis
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="">
              <a
                onClick={setToggleUseCase}
                // eslint-disable-next-line no-script-url
                href={undefined}
                className="flex cursor-pointer justify-between gap-1 items-center py-2 px-4 text-black-light lg:hover:bg-gray-50 rounded-full group-hover:text-blue-light group-hover:bg-gray-50"
              >
                <span>Use Cases</span>
                <i className="w-4 h-4 mt-1 group-hover:mt-0 group-hover:rotate-180 transition-transform">
                  <ArrowDownIcon className="w-full h-full" />
                </i>
              </a>
              <div
                id="dropdown"
                className={`z-10 ${toggleUseCases ? '' : 'hidden'} lg:group-hover:block bg-white divide-y divide-gray-100 lg:rounded-lg lg:shadow-lp-card lg:absolute bg-gray-50 lg:bg-white-light pl-5 lg:pl-0`}
              >
                <ul
                  className="py-2 text-sm text-black-light"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <a
                      onClick={() => {
                        setToggleUseCases((toggleUseCases) => !toggleUseCases);
                        navigate(PAGE_URL.EXPANDQA);
                        setToggle(!toggle);
                        document.body.classList.remove('scroll-hidden');
                      }}
                      className={`block cursor-pointer px-4 py-2 hover:bg-gray-100 hover:text-blue-light ${window.location.pathname === ACTIVE_URL.EXPANDQA ? 'bg-gray-100 text-blue-light' : ''}`}
                    >
                      Expand QA with Skilldify.AI
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setToggleUseCases((toggleUseCases) => !toggleUseCases);
                        navigate(PAGE_URL.INTUITIVEDASHBOARD);
                        setToggle(!toggle);
                        document.body.classList.remove('scroll-hidden');
                      }}
                      className={`block cursor-pointer px-4 py-2 hover:bg-gray-100 hover:text-blue-light ${window.location.pathname === ACTIVE_URL.INTUITIVEDASHBOARD ? 'bg-gray-100 text-blue-light' : ''}`}
                    >
                      Intuitive Dashboard
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setToggleUseCases((toggleUseCases) => !toggleUseCases);
                        navigate(PAGE_URL.AGENTPERFORMANCE);
                        setToggle(!toggle);
                        document.body.classList.remove('scroll-hidden');
                      }}
                      className={`block cursor-pointer px-4 py-2 hover:bg-gray-100 hover:text-blue-light ${window.location.pathname === ACTIVE_URL.AGENTPERFORMANCE ? 'bg-gray-100 text-blue-light' : ''}`}
                    >
                      Assessing Agent Performance
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setToggleUseCases((toggleUseCases) => !toggleUseCases);
                        navigate(PAGE_URL.ELEVATINGCUSTOMEREXPERIENCES);
                        setToggle(!toggle);
                        document.body.classList.remove('scroll-hidden');
                      }}
                      className={`block cursor-pointer px-4 py-2 hover:bg-gray-100 hover:text-blue-light ${window.location.pathname === ACTIVE_URL.ELEVATINGCUSTOMEREXPERIENCES ? 'bg-gray-100 text-blue-light' : ''}`}
                    >
                      Elevating Customer Experiences with Skilldify.Ai
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a
                onClick={() => {
                  navigate(PAGE_URL.WHYUS);
                  setToggle(!toggle);
                  document.body.classList.remove('scroll-hidden');
                }}
                className={`block cursor-pointer py-2 px-4 text-black-light hover:text-blue-light  hover:bg-gray-50 rounded-full ${window.location.pathname === ACTIVE_URL.WHYUS ? 'text-blue-light bg-gray-50' : ''}`}
              >
                Why Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
