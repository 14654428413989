import axios, { HttpStatusCode } from 'axios';
import { store } from '../App';
import { PAGE_URL } from '../utils';
import { logoutUser } from '../store';

const baseURL = 'https://api.skilldify.ai/api/v1';
// const baseURL = 'http://localhost:3001/api/v1';

export const authHeaders = (headers: any, token: string): any => {
  const namespace = 'Authorization';
  headers[namespace] = `Bearer ${token}`;
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().authUser.user.access_token;
  return authHeaders(headers, token || '');
};

const defaultOptions = {
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const apiClient = axios.create(defaultOptions);

apiClient.interceptors.request.use(
  (config: any) => {
    //   set user token here
    config.headers = withAuthHeaders({});
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (res) => res,
  (error: any) => {
    // handle user session refresh here
    const pathname = window.location.pathname;
    if (
      pathname !== PAGE_URL.LOGIN &&
      error.response.status === HttpStatusCode.Unauthorized
    ) {
      store.dispatch(logoutUser());
      // window.location.replace(PAGE_URL.LOGIN);
    }
    if (error.response.status >= HttpStatusCode.InternalServerError) {
      error.response.message = 'Something went wrong!';
    }
    return Promise.reject(error);
  },
);

export { apiClient };
