import React from 'react';
import { LandingHeader } from '../../../components/landing-header';
import { LandingFooter } from '../../../components/landing-footer';
import { HomeHeroBlock } from './HomeHeroBlock';
import { HomeFeature } from './HomeFeature';
import { AccordianLanding } from '../../../components/landing-accordian';
import { HomeTestimonials } from './HomeTestimonials';
import { HomeBrands } from './HomeBrands';
import { HomeSupport } from './HomeSupport';

export function Home() {
  return (
    <>
      <HomeHeroBlock />
      <HomeBrands />
      <HomeFeature name="featureHomeDetail"/>
      <HomeSupport />
      <AccordianLanding />
      {/* <HomeTestimonials/> */}
    </>
  );
}
