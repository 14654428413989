import React from "react";
import { IconsProps } from "./Icons";

export const CalenderIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}:IconsProps) => {
  return (
    <svg      
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 25 24"}
      width={width ? width : "25"}
      height={height ? height : "24"}
      fill={fill ? fill : "none"}
    >
        <path d="M20 4H17V2.5C17 2.36739 16.9473 2.24021 16.8536 2.14645C16.7598 2.05268 16.6326 2 16.5 2C16.3674 2 16.2402 2.05268 16.1464 2.14645C16.0527 2.24021 16 2.36739 16 2.5V4H9V2.5C9 2.36739 8.94732 2.24021 8.85355 2.14645C8.75979 2.05268 8.63261 2 8.5 2C8.36739 2 8.24021 2.05268 8.14645 2.14645C8.05268 2.24021 8 2.36739 8 2.5V4H5C4.3372 4.00079 3.70178 4.26444 3.23311 4.73311C2.76444 5.20178 2.50079 5.8372 2.5 6.5V19.5C2.50079 20.1628 2.76444 20.7982 3.23311 21.2669C3.70178 21.7356 4.3372 21.9992 5 22H20C20.6629 21.9995 21.2985 21.7359 21.7672 21.2672C22.2359 20.7985 22.4995 20.1629 22.5 19.5V6.5C22.4995 5.83712 22.2359 5.20154 21.7672 4.73282C21.2985 4.26409 20.6629 4.00053 20 4ZM21.5 19.5C21.5 19.8978 21.342 20.2794 21.0607 20.5607C20.7794 20.842 20.3978 21 20 21H5C4.60218 21 4.22064 20.842 3.93934 20.5607C3.65804 20.2794 3.5 19.8978 3.5 19.5V11H21.5V19.5ZM21.5 10H3.5V6.5C3.5 5.672 4.17 5 5 5H8V6.5C8 6.63261 8.05268 6.75979 8.14645 6.85355C8.24021 6.94732 8.36739 7 8.5 7C8.63261 7 8.75979 6.94732 8.85355 6.85355C8.94732 6.75979 9 6.63261 9 6.5V5H16V6.5C16 6.63261 16.0527 6.75979 16.1464 6.85355C16.2402 6.94732 16.3674 7 16.5 7C16.6326 7 16.7598 6.94732 16.8536 6.85355C16.9473 6.75979 17 6.63261 17 6.5V5H20C20.3978 5 20.7794 5.15804 21.0607 5.43934C21.342 5.72064 21.5 6.10218 21.5 6.5V10Z" fill="#0D56BE"/>
    </svg>
  );
};
