import React from 'react';
import { HomeFeature } from '../Home/HomeFeature';
import LandingSubHeader from '../../../components/common/LandingSubHeader';

export function SkilldifyLearning() {
  return (
    <>
      <LandingSubHeader
        title="Skilldify"
        colortitle="Learning"
        description="Master Quality Assurance with a Comprehensive Learning Management System"
      />
      <HomeFeature name="featureLearningDetail" />
    </>
  );
}
