import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SelectAutoWidth } from '../../components/DropDown-Menu/SelectAutoWidth';
import { CallsList } from '../list-page/list/CallsList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { CircularProgress, LinearProgress, TextField } from '@mui/material';
import Chip from '@mui/material/Chip';
import {
  YellowNumberBadge,
  WhiteNumberBadge,
  OrangeNumberBadge,
  BadgeFirst,
  BadgeSecond,
  BadgeThird,
} from '../../assets/images';
import { Link } from '@mui/material';
import CircularProgressCustom from './CircularProgressCustom';

interface CustomTableProps {
  probingScore: number;
  sentimentScore: number;
  callClosingScore: number;
  callOpeningScore: number;
  acknowledgmentScore: number;
  totalCalls: number;
}

export function CustomTable({
  probingScore,
  sentimentScore,
  callClosingScore,
  callOpeningScore,
  acknowledgmentScore,
  totalCalls,
}: CustomTableProps) {
  const parameters = [
    { name: 'PROBING', score: Math.round(probingScore * 100) },
    { name: 'SENTIMENT', score: Math.round(sentimentScore * 100) },
    { name: 'CLOSING', score: Math.round(callClosingScore * 100) },
    { name: 'OPENING', score: Math.round(callOpeningScore * 100) },
    { name: 'ACKNOWLEDGE', score: Math.round(acknowledgmentScore * 100) },
  ];
  parameters.sort((a, b) => b.score - a.score);

  const topParameters = parameters.slice(0, 3);

  const getBadgeImage = (rank: number) => {
    switch (rank) {
      case 1:
        return BadgeFirst;
      case 2:
        return BadgeSecond;
      case 3:
        return BadgeThird;
      default:
        return BadgeThird;
    }
  };

  return (
    <Box className="w-full rounded-radius-100  border-[1px] border-[#1651d017] border-solid p-5 flex flex-col gap-5 mt-6">
      <Box className="grid grid-cols-[1fr_auto] items-center">
        <Typography variant="h3">Parameter Overview</Typography>
        <FormGroup className="flex flex-row">
          <FormControlLabel control={<Checkbox defaultChecked />} label="All" />
          <FormControlLabel control={<Checkbox />} label="Manual " />
          <FormControlLabel control={<Checkbox />} label="AI Audit" />
        </FormGroup>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="text-[#052364]">
              {/* <TableCell padding="checkbox">
                <Checkbox color="primary" />
              </TableCell> */}

              <TableCell>PARAMETER NAME</TableCell>
              <TableCell>TOTAL CALLS</TableCell>
              <TableCell>AVERAGE SCORE</TableCell>
              <TableCell>RANK</TableCell>
              <TableCell className="text-center">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topParameters.map((parameter, index) => (
              <TableRow key={parameter.name}>
                <TableCell>{parameter.name}</TableCell>
                <TableCell>{totalCalls}</TableCell>
                <TableCell>
                  <CircularProgressCustom value={parameter.score} />
                </TableCell>
                <TableCell>
                  <img
                    src={getBadgeImage(index + 1)}
                    alt={`Rank ${index + 1}`}
                    className="max-w-[30px]"
                  />
                </TableCell>
                <TableCell className="w-[180px] text-center">
                  <Button
                    sx={{
                      background: '#1651D0',
                      color: '#fff',
                      padding: '6px 10px',
                      textTransform: 'capitalize',
                      '&.Mui-disabled': {
                        background: 'rgba(217, 217, 217, 0.43)',
                      },
                      '&:hover': {
                        background: '#0f3891',
                      },
                    }}
                  >
                    See Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="flex justify-end">
        <Link
          href="#"
          variant="subtitle1"
          color="primary"
          className="font-light text-base	 text-left"
        >
          View All
        </Link>
      </Box>
    </Box>
  );
}
