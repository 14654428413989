import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { ICallsListItem } from '../../../pages';
import { useNavigate } from 'react-router';
import { PAGE_URL, getFormattedDateTimeFromISO } from '../../../utils';
import CircularWithValueLabel from '../../CircularWithValueLabel';
import { convertTo12HourFormat } from '../../../utils/helper';

interface IProps {
  data: ICallsListItem;
}

export function CallsListItem({ data }: IProps) {
  const router = useNavigate();
  const callDatetime = getFormattedDateTimeFromISO(data.callDatetime);
  const auditDatetime = getFormattedDateTimeFromISO(data.auditDatetime);
  
  return (
    <TableBody>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox color="primary" />
        </TableCell> */}
        <TableCell component="td" scope="row" className="w-[250px]">
          <Typography variant="body1">{data.id}</Typography>
        </TableCell>
        <TableCell className="w-[250px]">
          <Typography variant="body1">{data.employee.name}</Typography>
          <Typography variant="body2">Emp ID: {data.employee.id}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{callDatetime.formattedDate}</Typography>
          <Typography variant="body2">{callDatetime.formattedTime}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{auditDatetime.formattedDate}</Typography>
          <Typography variant="body2">{auditDatetime.formattedTime}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1">
            {data.status === 'SUCCESS' ? 'REVIEWED' : data.status === "ERROR" ? 'ERROR' : ' NOT-REVIEWED'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            className={`${data.score >= 0.85 ? 'text-[#25D366]' : data.score >= 0.5 ? 'text-[#E68008]' : 'text-[#ff5252]'}`}
          >
            {`${typeof data.score === 'number' ? (data.score < 0 ? 'NA' : data.score >= 0.85 ? 'Fanatical' : data.score >= 0.5 ? 'On Track' : 'Off Track') : '-'}`}
          </Typography>
        </TableCell>
        <TableCell>
          <Box className="flex justify-center">
            {data.score && data.score >= 0 ? (
              <CircularWithValueLabel
                progress={Math.max(0, data.score) * 100}
                color={`${data.score >= 0.85 ? 'rgb(88,190,125)' : data.score >= 0.5 ? '#E68008' : '#FC5A5A'}`}
              />
            ) : (
              <Typography>-</Typography>
            )}
          </Box>
        </TableCell>
        <TableCell className="w-[180px] text-center">
          <Button
            disabled={data.status !== 'SUCCESS'}
            onClick={() => {
              router(`${PAGE_URL.CALL_AUDIT.LIST_PAGE}/${data.id}`);
            }}
            sx={{
              background: '#1651D0',
              color: '#fff',
              padding: '6px 10px',
              textTransform: 'capitalize',
              '&.Mui-disabled': {
                background: 'rgba(217, 217, 217, 0.43)',
              },
              '&:hover': {
                background: '#0f3891',
              },
            }}
          >
            See Details
          </Button>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
