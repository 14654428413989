import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BookIcon, CheckIcon, DangerIcon } from '../../assets/icons';

const card = (
  <>
    <Card variant="outlined" className="bg-[#FFFFFF] p-6">
      <CardContent className="flex gap-6 py-0 px-0 items-center">
        <CheckIcon className="size-8 xxl:size-[54px] shrink-0" />
        <Typography className="flex-1 space-y-1.5">
          <Typography variant="h2" className="font-[Inter-Bold]">
            3792
          </Typography>
          <Typography variant="h4" color="text.secondary">
            Coaching Accepted
          </Typography>
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined" className="bg-[#FFFFFF] p-6">
      <CardContent className="flex gap-6 py-0 px-0 items-center">
        <BookIcon className="size-8 xxl:size-[54px] shrink-0" />
        <Typography className="flex-1 space-y-1.5">
          <Typography variant="h2" className="font-[Inter-Bold]">
            35%
          </Typography>
          <Typography variant="h4" color="text.secondary">
            Avg. Coaching Completion Rate
          </Typography>
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined" className="bg-[#FFFFFF] p-6">
      <CardContent className="flex gap-6 py-0 px-0 items-center">
        <DangerIcon className="size-8 xxl:size-[54px] shrink-0" />
        <Typography className="flex flex-col gap-1.5">
          <Typography variant="h2" className="font-[Inter-Bold]">
            3792
          </Typography>
          <Typography variant="h4" color="text.secondary">
            Coaching Pending
          </Typography>
        </Typography>
      </CardContent>
    </Card>
  </>
);

export default function OutlineCoachingCard() {
  return <Box className="grid grid-cols-3 gap-4">{card}</Box>;
}
