import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import WithStyles from '@mui/material/styles/withStyles';
import BlackGreenLogo from '../../../assets/images/LandingPage/brandslogo/black-green.png';
import BritishCouncilLogo from '../../../assets/images/LandingPage/brandslogo/british-council.png';
import ClovityLogo from '../../../assets/images/LandingPage/brandslogo/clovity.png';
import DamcoLogo from '../../../assets/images/LandingPage/brandslogo/damco.png';
import EsperionLogo from '../../../assets/images/LandingPage/brandslogo/esperion.png';
import ExsquaredLogo from '../../../assets/images/LandingPage/brandslogo/ex-squared.png';
import GoogleLogo from '../../../assets/images/LandingPage/brandslogo/google.png';
import JslLogo from '../../../assets/images/LandingPage/brandslogo/jsl.png';
import SliceLogo from '../../../assets/images/LandingPage/brandslogo/slice.png';
import UnacademyLogo from '../../../assets/images/LandingPage/brandslogo/unacademy.png';
import WellversedLogo from '../../../assets/images/LandingPage/brandslogo/wellversed.png';

export function HomeBrands() {
  const [images] = useState([
    SliceLogo,
    DamcoLogo,
    GoogleLogo,
    UnacademyLogo,
    BlackGreenLogo,
    BritishCouncilLogo,
    ClovityLogo,
    EsperionLogo,
    ExsquaredLogo,
    JslLogo,
    WellversedLogo,
  ]);

  return (
    <section className="py-12 lg:pb-20">
      <div className="container">
        <h3 className="text-center subtitle-b text-blue-dark mb-10">
          Trusted by 152,000+ Customers Worldwide
        </h3>
        <div className="f-ic-jc flex-wrap gap-5 xl:gap-10 mb-14">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={2000}
            className=""
            containerClass="container px-0"
            dotListClass=""
            focusOnSelect={false}
            infinite={true}
            itemClass="px-4 f-ic-jc"
            keyBoardControl
            minimumTouchDrag={80}
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 5,
              },
              mobile: {
                breakpoint: {
                  max: 767,
                  min: 0,
                },
                items: 2,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 768,
                },
                items: 3,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass="items-center"
            slidesToSlide={1}
            swipeable
          >
            {images.map((image, index) => (
              <img
                className=""
                loading="lazy"
                key={index}
                src={image}
                alt={`Image ${index}`}
              />
            ))}
          </Carousel>
        </div>
        <div className="grid grid-cols-[repeat(auto-fit,minmax(198px,1fr))]  gap-5 *:text-center *:h-full *:border *:p-6 *:rounded-lg *:shadow-[8px_0_15px_0_rgb(30_95_188/17%)] *:border-blue-light">
          <div>
            <h5 className="h3-b xl:h2-b bg-gradient-purple text-transparent bg-clip-text">
              20%
            </h5>
            <span className="body-b">Increase in Sales</span>
          </div>
          <div>
            <h5 className="h3-b xl:h2-b bg-gradient-purple text-transparent bg-clip-text">
              26%
            </h5>
            <span className="body-b">Increase in CSAT</span>
          </div>
          <div>
            <h5 className="h3-b xl:h2-b bg-gradient-purple text-transparent bg-clip-text">
              95%
            </h5>
            <span className="body-b">Compliance monitoring</span>
          </div>
          <div>
            <h5 className="h3-b xl:h2-b bg-gradient-purple text-transparent bg-clip-text">
              12%
            </h5>
            <span className="body-b">Increase in collection rates</span>
          </div>
          <div>
            <h5 className="h3-b xl:h2-b bg-gradient-purple text-transparent bg-clip-text">
              54 sec
            </h5>
            <span className="body-b">Lower AHT</span>
          </div>
          <div>
            <h5 className="h3-b xl:h2-b bg-gradient-purple text-transparent bg-clip-text">
              57%
            </h5>
            <span className="body-b">Decrease in Ramp up time</span>
          </div>
        </div>
      </div>
    </section>
  );
}
