import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Logo } from '../../assets/images';
import { ArrowDownIcon, UserIcon } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../utils';
import { useAuthUser } from '../../hooks';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store';

export default function AccountMenu() {
  const { name } = useAuthUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = React.useCallback(() => {
    dispatch(logoutUser());
    navigate(PAGE_URL.LOGIN);
  }, [dispatch, navigate]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          border: '1px solid #D9D9D9',
          borderRadius: '20px',
          padding: '6px 12px',
          height: '45px',
          position: 'relative',
          '&:hover': {
            background: '#fff',
          },
        }}
      >
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableRipple={true}
          >
            <Box
              sx={{
                display: 'flex',
                minWidth: '165px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '10px',
              }}
            >
              {/* <Avatar sx={{ width: 32, height: 32 }} src={Logo} /> */}
              <UserIcon className='w-8 h-8' />
              <Typography>{name}</Typography>
              <ArrowDownIcon className='ml-auto'/>
            </Box>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(1px 1px 2.4px 0px rgba(5, 35, 100, 0.04))',
            borderRadius: '8px',
            border: '1px solid rgba(217, 217, 217, 0.6)',
            boxShadow: ' 0.5px solid rgba(217, 217, 217, 0.60)',
            mt: 1.5,
            padding: '20px 25px',
            width: '227px',
            height: '130px',
            left: 'auto !important',
            right: '24px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={handleClose}
          sx={{
            '&.MuiMenuItem-root ': {
              fontSize: '14px',
              color: '#201F20',
              display: 'block',
              textAlign: 'center',
              fontFamily: 'Inter-Regular',
            },
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
        >
          Profile
        </MenuItem>
        <Divider
          sx={{
            '&.MuiDivider-root': {
              borderColor: '#7070c945',
            },
          }}
        />
        <MenuItem
          onClick={handleLogout}
          sx={{
            '&.MuiMenuItem-root ': {
              fontSize: '14px',
              color: '#FC5A5A',
              display: 'block',
              textAlign: 'center',
              fontFamily: 'Inter-Regular',
            },
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
