import React from 'react';
import { IconsProps } from './Icons';

export const CallIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
      width={width ? width : '24'}
      height={height ? height : '24'}
      fill={fill ? fill : 'none'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.718 3.092C5.944 1.801 7.972 2.042 8.986 3.476L10.246 5.26C11.057 6.407 10.989 8 10.021 9.02L9.776 9.277C9.77531 9.27899 9.77464 9.28099 9.774 9.283C9.761 9.319 9.729 9.435 9.761 9.655C9.828 10.11 10.179 11.036 11.607 12.539C13.039 14.047 13.907 14.402 14.31 14.468C14.4071 14.4898 14.508 14.4874 14.604 14.461L15.012 14.031C15.886 13.111 17.248 12.93 18.347 13.562L20.257 14.662C21.89 15.602 22.27 17.901 20.965 19.275L19.545 20.77C19.102 21.237 18.497 21.636 17.75 21.71C15.926 21.89 11.701 21.655 7.272 16.991C3.138 12.64 2.353 8.855 2.254 7.006C2.205 6.092 2.612 5.309 3.148 4.744L4.718 3.092ZM7.761 4.342C7.249 3.618 6.328 3.574 5.805 4.125L4.235 5.777C3.905 6.127 3.73 6.527 3.752 6.926C3.832 8.436 4.483 11.878 8.359 15.958C12.423 20.238 16.168 20.358 17.603 20.217C17.886 20.189 18.178 20.031 18.457 19.737L19.877 18.242C20.491 17.596 20.33 16.434 19.509 15.962L17.599 14.862C17.086 14.567 16.485 14.658 16.1 15.064L15.644 15.544L15.117 15.043C15.644 15.543 15.644 15.544 15.643 15.545L15.642 15.546L15.639 15.55L15.632 15.556L15.618 15.57C15.576 15.6112 15.5305 15.6487 15.482 15.682C15.402 15.738 15.296 15.801 15.161 15.854C14.885 15.963 14.521 16.021 14.07 15.948C13.192 15.806 12.042 15.175 10.52 13.572C8.992 11.964 8.407 10.765 8.277 9.872C8.21 9.418 8.263 9.055 8.361 8.78C8.4154 8.62675 8.49296 8.48274 8.591 8.353L8.621 8.316L8.635 8.301L8.641 8.294L8.644 8.291L8.646 8.29C8.646 8.29 8.646 8.288 9.179 8.793L8.647 8.288L8.934 7.986C9.379 7.517 9.444 6.723 9.022 6.126L7.761 4.342Z"
        fill="currentColor"
      />
      <path
        d="M13.2599 1.88C13.2921 1.6837 13.4008 1.50817 13.5622 1.39193C13.7236 1.2757 13.9246 1.22825 14.1209 1.26C14.1459 1.265 14.2279 1.28 14.2709 1.29C14.3559 1.308 14.4749 1.338 14.6229 1.38C14.9199 1.467 15.3349 1.61 15.8329 1.838C16.8289 2.295 18.1539 3.094 19.5299 4.469C20.9059 5.845 21.7049 7.171 22.1619 8.167C22.3899 8.665 22.5319 9.079 22.6189 9.377C22.6628 9.52673 22.7005 9.67819 22.7319 9.831L22.7369 9.862C22.7699 10.06 22.7236 10.2631 22.6082 10.4273C22.4928 10.5916 22.3174 10.7039 22.1199 10.74C21.9241 10.7718 21.7237 10.7247 21.5625 10.6091C21.4014 10.4934 21.2926 10.3187 21.2599 10.123C21.2395 10.0125 21.2124 9.90329 21.1789 9.796C21.0768 9.45257 20.9498 9.11702 20.7989 8.792C20.4089 7.942 19.7069 6.768 18.4689 5.53C17.2319 4.292 16.0579 3.591 15.2069 3.201C14.8823 3.05022 14.547 2.92322 14.2039 2.821C14.0987 2.79131 13.9927 2.76464 13.8859 2.741C13.6895 2.7083 13.5138 2.5997 13.3967 2.43864C13.2796 2.27758 13.2305 2.07694 13.2599 1.88Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.486 5.32999C13.513 5.23523 13.5584 5.1467 13.6195 5.06948C13.6807 4.99226 13.7565 4.92785 13.8426 4.87993C13.9287 4.83201 14.0234 4.80152 14.1213 4.79021C14.2191 4.7789 14.3183 4.78698 14.413 4.81399L14.207 5.53499L14.414 4.81499H14.416L14.42 4.81599L14.427 4.81799L14.447 4.82499C14.5259 4.85141 14.6036 4.8811 14.68 4.91399C14.826 4.97599 15.025 5.07199 15.27 5.21699C15.76 5.50699 16.427 5.98699 17.212 6.77299C17.997 7.55799 18.479 8.22599 18.768 8.71499C18.913 8.95999 19.009 9.15899 19.072 9.30499C19.1049 9.38137 19.1346 9.45911 19.161 9.53799L19.167 9.55799L19.169 9.56599L19.17 9.56899V9.57099L18.45 9.77799L19.171 9.57199C19.227 9.76294 19.2048 9.96831 19.1093 10.1429C19.0139 10.3175 18.853 10.447 18.662 10.503C18.4711 10.5589 18.2657 10.5368 18.0911 10.4413C17.9165 10.3458 17.787 10.1849 17.731 9.99399L17.728 9.98399C17.6577 9.80984 17.5741 9.64134 17.478 9.47999C17.254 9.10299 16.852 8.53299 16.152 7.83299C15.452 7.13299 14.882 6.73099 14.506 6.50799C14.3447 6.4119 14.1762 6.32832 14.002 6.25799L13.992 6.25399C13.8029 6.1974 13.6438 6.06856 13.549 5.89541C13.4543 5.72225 13.4317 5.51973 13.486 5.32999Z"
        fill="currentColor"
      />
    </svg>
  );
};
