import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { IFilterProps } from './header/ListpageHeader';
import { Reload } from '../../assets/icons';

interface IProps extends IFilterProps {
  setRefetch: () => void;
}

export function ListpageFilter({ value, setValue, setRefetch }: IProps) {
  return (
    <Tabs
      className="flex items-center"
      value={value}
      onChange={(e, value) => setValue(value)}
      sx={{
        "& .MuiTab-root.Mui-selected": { borderColor:"rgba(22, 81, 208, 0.24)", background:"rgba(215, 227, 255, 0.87)" },
        "& .MuiTabs-indicator": { bgcolor: "transparent" }
       }}
    >
      <Tab
        label="All"
        value={''}
        href="#"
      />
      <Tab
        label="Reviewed"
        value={'reviewed'}
        href="#"
      />
      <Tab
        label="Not Reviewed"
        value={'not-reviewed'}
        href="#"
      />
      <a onClick={()=>setRefetch()}><Reload/></a>
    </Tabs>
  );
}
