import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { LoginLogoIcon } from '../../assets/icons/LoginLogoIcon';
import AccountMenu from '../AccountMenu/AccountMenu';

export function AppHeader() {

  return (
    <Box>
      <AppBar
        position="static"
        color="success"
        className="border-b border-solid border-x-0 border-t-0 bor border-white-medium rounded-none"
      >
        <Toolbar variant="dense" className="w-full min-h-[73px]">
          <Box className="max-w-[107px] h-[32px] ">
            <LoginLogoIcon className="w-[107px] h-[30px]" />
          </Box>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className="ml-auto"
          >                        
            <AccountMenu/>
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
