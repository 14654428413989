import { combineReducers } from 'redux';
import authUserReducer, { authUserInitialState } from './auth/slice';

export const initialState = {
  authUser: authUserInitialState,
};

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    authUser: authUserReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
