export const addRestQueryParams = (
  url: string,
  params: Record<string, string | number>,
) => {
  if (!Object.keys(params).length) {
    return url;
  }
  url = `${url}?`;
  Object.keys(params).forEach((key) => {
    if (params[key] !== undefined) {
      url += `${key}=${params[key]}&`;
    }
  });
  return url.slice(0, url.length - 1);
};

export const fileKey = (file: File) => {
  return `${file.name}_${file.lastModified}_${file.size}`;
};

export const convertFileSize = (bytes: number) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
};

export const convertToBytes = (size: number, unit: string) => {
  const units: Record<string, number> = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  const sizeInBytes = size * units[unit];
  return sizeInBytes;
};

export const getFileExtension = (filename: string) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1 || dotIndex === filename.length - 1) {
    return '';
  }
  const extension = filename.substring(dotIndex + 1);
  return extension;
};

export const removeFileExtension = (fileName: string) => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return fileName; // No extension found
  }
  return fileName.substring(0, lastDotIndex);
};

export const convertTo12HourFormat = (time24h: string): string => {
  try {
    const [hoursStr, minutesStr] = time24h.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const period = hours < 12 ? 'AM' : 'PM';
    let hours12h = hours % 12;
    hours12h = hours12h || 12;
    return `${hours12h.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
  } catch (e) {
    return '';
  }
};
