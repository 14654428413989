import React from 'react';
import QualityManagementImg from '../../../assets/images/LandingPage/Feature01.png';
import { HomeTestimonials } from '../Home/HomeTestimonials';
import { HomeFeature } from '../Home/HomeFeature';
import LandingSubHeader from '../../../components/common/LandingSubHeader';

export function ExpandQa() {
  return (
    <>
      <LandingSubHeader
        title="Expand QA with"
        colortitle="Skilldify.AI"
        description=""
        showCTA={true}
      />
      <HomeFeature name="featureExpandQaDetail" />
      <HomeTestimonials />
    </>
  );
}
