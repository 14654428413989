import React from 'react';
import { MeetOurTeam } from './WhyUsTeam';
import LandingSubHeader from '../../../components/common/LandingSubHeader';
import WhyUsImage from '../../../assets/images/LandingPage/agent03.png';
import DoneIcon from '@mui/icons-material/Done';

export function WhyUs() {
  return (
    <>
      <LandingSubHeader
        title="Why"
        colortitle="Us"
        description="Experience Actionable Insights and Trusted Auditing"
      />
      <section className="py-12 lg:py-20 relative bg-black-dark-100">
        <div className="container space-y-14 md:space-y-20 lg:space-y-28">
          <div className="space-y-14 md:space-y-20 lg:space-y-28 *:grid *:md:grid-cols-2 *:gap-10 *:items-center">
            <figure>
              <img
                src={WhyUsImage}
                loading="lazy"
                alt="userImage"
                className="w-full rounded-xl order-1 md:order-2"
              />
              <figcaption className="space-y-5  order-2 md:order-1">
                <ul className="space-y-5">
                  <li className="flex items-start gap-5 text-white-light para-l">
                    <i className="bg-gradient-purple f-ic-jc size-8 rounded-full shrink-0">
                      <DoneIcon className="size-6" />
                    </i>
                    <div className="space-y-4">
                      <h5 className="text-[18px] font-[Inter-Bold]">
                        Actionable Insights Across Channels
                      </h5>
                      <p>
                        Access actionable results through our user-friendly
                        dashboard, covering calls, chats, and emails. Dive into
                        detailed analysis backed by 25 unique behavioural
                        metrics.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start gap-5 text-white-light para-l">
                    <i className="bg-gradient-purple f-ic-jc size-8 rounded-full shrink-0">
                      <DoneIcon className="size-6" />
                    </i>
                    <div className="space-y-4">
                      <h5 className="text-[18px] font-[Inter-Bold]">
                        Customizable Parameters
                      </h5>
                      <p>
                        We put you in control. Tailor your quality assessment
                        with the freedom to choose parameters that align with
                        your specific goals and needs.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start gap-5 text-white-light para-l">
                    <i className="bg-gradient-purple f-ic-jc size-8 rounded-full shrink-0">
                      <DoneIcon className="size-6" />
                    </i>
                    <div className="space-y-4">
                      <h5 className="text-[18px] font-[Inter-Bold]">
                        Comprehensive Analysis
                      </h5>
                      <p>
                        Our offerings encompass quality assessment, sentiment
                        analysis, and behavioural analysis. Gain a holistic
                        understanding of your customer interactions to enhance
                        service quality.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start gap-5 text-white-light para-l">
                    <i className="bg-gradient-purple f-ic-jc size-8 rounded-full shrink-0">
                      <DoneIcon className="size-6" />
                    </i>
                    <div className="space-y-4">
                      <h5 className="text-[18px] font-[Inter-Bold]">
                        Third-Party Expert Auditing
                      </h5>
                      <p>
                        Our offerings encompass quality assessment, sentiment
                        analysis, and behavioural analysis. Gain a holistic
                        understanding of your customer interactions to enhance
                        service quality.
                      </p>
                    </div>
                  </li>
                </ul>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      {/* <MeetOurTeam /> */}
    </>
  );
}
