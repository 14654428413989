import {
  IAcceptedFiles,
  IAcceptedFilesData,
  IUploadFileHandler,
} from '../interfaces/index';
import { fileKey } from '../../../utils/helper';
import { useMemo, useRef, useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';

interface IUseUploadedProps extends DropzoneOptions, IUploadFileHandler {}

interface IAbortManager {
  [key: string]: AbortController;
}

const calculateProgressPercentage = (loaded: number, total: number) => {
  return Math.round((loaded * 100) / total);
};

export const useUploadedFiles = (props: IUseUploadedProps) => {
  const [selectedFiles, setSelectedFiles] = useState<IAcceptedFilesData>({});
  const abortManagerRef = useRef<IAbortManager>({});
  const { uploadFileHandler } = props;

  const filesUploading = useMemo(() => {
    for (const key of Object.keys(selectedFiles)) {
      if (selectedFiles[key].isUploading) {
        return true;
      }
    }
    return false;
  }, [selectedFiles]);

  const allFilesUploaded = useMemo(() => {
    if (!Object.keys(selectedFiles).length) {
      return false;
    }
    for (const key of Object.keys(selectedFiles)) {
      if (!(selectedFiles[key].isUploaded || selectedFiles[key].failed)) {
        return false;
      }
    }
    return true;
  }, [selectedFiles]);

  const updateFileProps = (file: File, updatedProps: IAcceptedFiles) => {
    setSelectedFiles((prevAcceptedFiles) => {
      const newSelectedFiles = { ...prevAcceptedFiles };
      const updatedFileData = { ...updatedProps };
      newSelectedFiles[fileKey(file)] = updatedFileData;
      return newSelectedFiles;
    });
  };

  const uploadFile = async (file: File, language: string) => {
    if (uploadFileHandler) {
      updateFileProps(file, {
        ...selectedFiles[fileKey(file)],
        isUploading: true,
      });
      const response = await uploadFileHandler.init(file, language ?? "en");
      const url = response.url || "";
      try {
        const res = await uploadFileHandler.process({
          file,
          url,
          config: {
            signal: abortManagerRef.current[fileKey(file)].signal,
            onUploadProgress: (progressEvent: {
              loaded: number;
              total: number;
            }) => {
              const percentage = calculateProgressPercentage(
                progressEvent.loaded,
                progressEvent.total as number,
              );
              updateFileProps(file, {
                ...selectedFiles[fileKey(file)],
                progress: percentage,
                isUploading: true,
                fileSize: progressEvent.total as number,
                filePath: '',
              });
            },
          },
          filePath: '',
        });
        updateFileProps(file, {
          ...selectedFiles[fileKey(file)],
          isUploading: false,
          failed: false,
          isUploaded: true,
          filePath: String(file?.name),
        });
      } catch (e) {
        updateFileProps(file, {
          ...selectedFiles[fileKey(file)],
          isUploading: false,
          failed: true,
          isUploaded: false,
          filePath: String(file?.name),
        });
      }
    }
  };
  const handleUpload = (
    newAcceptedFiles: IAcceptedFilesData = selectedFiles, language: string
  ) => {
    Object.keys(newAcceptedFiles).forEach((key) => {
      if (
        newAcceptedFiles[key].isUploading === false &&
        newAcceptedFiles[key].failed === false &&
        newAcceptedFiles[key].isUploaded === false
      ) {
        uploadFile(newAcceptedFiles[key].file, language);
      }
    });
  };

  const handleAbort = (key: string) => {
    abortManagerRef.current[key].abort();
  };

  const handleNewFiles = async (accFiles: File[]) => {
    const newAbortManager = {} as IAbortManager;
    const newAcceptedFiles = {} as IAcceptedFilesData;

    accFiles.forEach((file) => {
      newAbortManager[fileKey(file)] = new AbortController();
      newAcceptedFiles[fileKey(file)] = {
        file,
        isUploading: false,
        progress: 0,
        failed: false,
        fileSize: 0,
        isUploaded: false,
      };
    });
    abortManagerRef.current = {
      ...abortManagerRef.current,
      ...newAbortManager,
    };
    setSelectedFiles((prevState) => ({ ...prevState, ...newAcceptedFiles }));
  };

  const handleRemoveFile = (key: string) => {
    const newFiles = { ...selectedFiles };
    delete newFiles[key];
    setSelectedFiles({ ...newFiles });
    const newAbortManager = { ...abortManagerRef.current };
    delete newAbortManager[key];
    abortManagerRef.current = { ...newAbortManager };
  };

  return {
    selectedFiles,
    handleUpload,
    handleAbort,
    setSelectedFiles,
    uploadFile,
    handleNewFiles,
    filesUploading,
    allFilesUploaded,
    handleRemoveFile,
  };
};

export default useUploadedFiles;
