import React from 'react';
import { IconsProps } from './Icons';

export const ArrowRightIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 22 22'}
      width={width ? width : '22'}
      height={height ? height : '22'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M14.6025 10.5022L6.93966 3.20099C6.8118 3.0791 6.64193 3.01111 6.46528 3.01111C6.28864 3.01111 6.11877 3.0791 5.99091 3.20099L5.98266 3.20924C5.92046 3.26833 5.87093 3.33945 5.83709 3.41828C5.80324 3.49712 5.78579 3.58201 5.78579 3.6678C5.78579 3.75359 5.80324 3.83849 5.83709 3.91732C5.87093 3.99615 5.92046 4.06728 5.98266 4.12636L13.1987 11.0014L5.98266 17.8736C5.92046 17.9327 5.87093 18.0038 5.83709 18.0827C5.80325 18.1615 5.78579 18.2464 5.78579 18.3322C5.78579 18.418 5.80325 18.5029 5.83709 18.5817C5.87093 18.6605 5.92046 18.7317 5.98266 18.7907L5.99091 18.799C6.11877 18.9209 6.28864 18.9889 6.46528 18.9889C6.64193 18.9889 6.8118 18.9209 6.93966 18.799L14.6025 11.4977C14.6699 11.4335 14.7236 11.3563 14.7602 11.2707C14.7969 11.1852 14.8158 11.0931 14.8158 11C14.8158 10.9069 14.7969 10.8148 14.7602 10.7292C14.7236 10.6437 14.6699 10.5664 14.6025 10.5022Z"
        fill="currentColor"
      />
    </svg>
  );
};
