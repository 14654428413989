import React from 'react';
import { IconsProps } from './Icons';

export const ArrowLeftIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 22 22'}
      width={width ? width : '22'}
      height={height ? height : '22'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M7.39747 10.5022L15.0603 3.20099C15.1882 3.0791 15.3581 3.01111 15.5347 3.01111C15.7114 3.01111 15.8812 3.0791 16.0091 3.20099L16.0173 3.20924C16.0795 3.26833 16.1291 3.33945 16.1629 3.41828C16.1968 3.49712 16.2142 3.58201 16.2142 3.6678C16.2142 3.75359 16.1968 3.83849 16.1629 3.91732C16.1291 3.99615 16.0795 4.06728 16.0173 4.12636L8.80134 11.0014L16.0173 17.8736C16.0795 17.9327 16.1291 18.0038 16.1629 18.0827C16.1968 18.1615 16.2142 18.2464 16.2142 18.3322C16.2142 18.418 16.1968 18.5029 16.1629 18.5817C16.1291 18.6605 16.0795 18.7317 16.0173 18.7907L16.0091 18.799C15.8812 18.9209 15.7114 18.9889 15.5347 18.9889C15.3581 18.9889 15.1882 18.9209 15.0603 18.799L7.39747 11.4977C7.33007 11.4335 7.27642 11.3563 7.23976 11.2707C7.20311 11.1852 7.1842 11.0931 7.1842 11C7.1842 10.9069 7.20311 10.8148 7.23976 10.7292C7.27642 10.6437 7.33007 10.5664 7.39747 10.5022Z"
        fill="currentColor"
      />
    </svg>
  );
};
