/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { LoginImage } from '../assets/images';
import {
  Typography,
  Box,
  FormControl,
  Link,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { LoginLogoIcon } from '../assets/icons/index';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../utils';
import { CustomizedInput } from '../components/inputs/inputWithLabel.styled';
import { VisibilityOff } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { authService } from '../service';
import { useDispatch } from 'react-redux';
import { loginUser } from '../store';

export function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const onFieldChange = () => {
    setError('');
  };

  const handleUsernameChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onFieldChange();
    setUsername(e.target.value);
  };
  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onFieldChange();
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const { data } = await authService.login({
        email: username,
        password,
      });
      dispatch(loginUser(data));
      navigate(PAGE_URL.DASHBOARD);
    } catch (err: any) {
      console.log(err);
      setError(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <main className="bg-gradient-to-r from-white-dark/60 from-50% to-white to-50%">
        <Box className="login-page grid grid-cols-2 h-screen">
          <Box className="flex justify-center items-center px-16 xxl:px-20 py-3">
            <img className="max-w-full " src={LoginImage} alt="Login-image" />
          </Box>
          <Box className="px-16 py-3 self-center justify-self-center max-w-[535px] xxl:max-w-none">
            <Box className="flex justify-center	gap-3 mb-7">
              <LoginLogoIcon className="w-[120px] h-auto " />
            </Box>
            <Typography
              variant="h1"
              color="text.secondary"
              className="text-center"
            >
              Welcome !
            </Typography>
            <Typography
              variant="h4"
              color="info.main"
              className="text-center mt-4 mx-auto px-5 max-w-[425px]"
            >
              To keep connected with us, Please login with your personal Info.
            </Typography>
            <Box className="relative">
              <FormControl className="gap-y-4 w-full mt-6">
                <CustomizedInput
                  className="w-full hover:outline-0"
                  id="outlined-basic"
                  placeholder="Enter/Number"
                  onChange={handleUsernameChange}
                  sx={{
                    '& .MuiOutlinedInput-input ': {
                      padding: '13px 15px',
                    },
                  }}
                />
              </FormControl>
              <FormControl className="gap-y-4 w-full mt-4">
                <CustomizedInput
                  className="w-full hover:outline-0"
                  id="outlined-adornment-password"
                  placeholder="password"
                  onChange={handlePasswordChange}
                  type={showPassword ? 'text' : 'password'}
                  sx={{
                    '& .MuiOutlinedInput-input ': {
                      padding: '13px 15px',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {error && (
                <Typography
                  variant="overline"
                  color="red"
                  className="text-start w-full absolute left-0 top-[calc(100%+5px)]"
                >
                  {error}
                </Typography>
              )}
            </Box>
            <Box className="mt-5">
              <Link
                href="#"
                variant="h6"
                color="text.secondary"
                className="font-light text-left"
              >
                Forgot Password ?
              </Link>
              <LoadingButton
                variant="contained"
                className="w-full capitalize shadow-none mt-6"
                onClick={handleLogin}
                loading={loading}
                loadingPosition="center"
              >
                Sign in
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </main>
    </>
  );
}
