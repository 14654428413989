// import React from "react";
// import { RecordingImage } from "../../../assets/images";
import { Typography } from '@mui/material';
import {
  SpeakerBgIcon,
  SpeakerOneIcon,
  SpeakerTableIcon,
  SpeakerTwoIcon,
  SpeakerTableShadowIcon,
} from '../../../assets/icons';
import { RecordingImage } from '../../../assets/images';

interface IProps {
  primary?: boolean;
  sentence?: string;
  charLength: number;
}

export const CallImage = ({ primary, sentence, charLength }: IProps) => {
  return (
    <div className="transcript relative">
      <SpeakerBgIcon className="transcript-bg -z-[1]" />
      <Typography component="div" className="transcript-speaker-one relative">
        <Typography
          component="div"
          className={`arrow arrow-left shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] bg-[#FEFEFE] absolute bottom-[calc(100%+30px)] left-20 rounded-t-lg rounded-br-lg  text-[12px] xxl:text-[14px] font-[Inter-Regular]  ${charLength > 200 ? "w-[400px]": charLength < 30 ? "w-auto" : "w-[200px]"} capitalize transition duration-300  ${primary === true ? 'opacity-1' : 'opacity-0'}`}
        >
          <div className="max-h-40 xxl:max-h-48 p-4 base-scrollbar overflow-auto">
            {sentence}
          </div>
        </Typography>
        <SpeakerOneIcon className="transcript-speaker-one w-[235px] h-[160px]" />
      </Typography>
      <Typography component="div" className="transcript-speaker-two relative">
        <Typography
          component="div"
          className={`arrow arrow-right shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] bg-[#FEFEFE] absolute bottom-[calc(100%+24px)] right-0 rounded-t-lg rounded-bl-lg  text-[12px] xxl:text-[14px] font-[Inter-Regular] ${charLength > 200 ? "w-[400px]": charLength < 30 ? "w-auto" : "w-[200px]"} capitalize transition duration-300 ${primary === false ? 'opacity-1' : 'opacity-0'}`}
        >
          <div className="max-h-40 xxl:max-h-48  p-4 base-scrollbar overflow-auto">
            {sentence}
          </div>
        </Typography>
        <SpeakerTwoIcon className="w-[145px] h-[148px]" />
      </Typography>
      <SpeakerTableIcon className="transcript-speaker-table w-[160px] h-[75px]" />
      <SpeakerTableShadowIcon className="transcript-speaker-table-shadow w-[190px] h-[25px]" />
    </div>
  );
};
