import Overview from '../../../assets/images/LandingPage/overview.png';
import LanguageSupport from '../../../assets/images/LandingPage/language-support.png';
import bluegradient from '../../../assets/images/LandingPage/blue-background.png';
import pinkgradient from '../../../assets/images/LandingPage/pink-background.png';

export function HomeSupport() {
  return (
    <section className="pt-12 lg:pt-20">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-10 items-center">
          <div className="relative">
            <img
              className="absolute z-[-1] size-full "
              src={bluegradient}
              alt="Skilldify Overview"
              loading="lazy"
            />
            <div className="px-10 pt-10 text-center space-y-10">
              <h2 className="h3-b xl:h2-b">Skilldify Overview</h2>
              <img
                className="mx-auto"
                src={Overview}
                alt="overview logo"
                loading="lazy"
              />
            </div>
          </div>
          <div className="relative">
            <img
              className="absolute z-[-1] size-full"
              src={pinkgradient}
              loading="lazy"
            />
            <div className="px-10 pt-10 text-center space-y-10">
              <h2 className="h3-b xl:h2-b">Language Support</h2>
              <img
                className="mx-auto"
                src={LanguageSupport}
                alt="overview logo"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
