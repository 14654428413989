import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { ITranscriptItem } from '../../../pages/call-audit/staticData';
import { Logo } from '../../../assets/images';
import { msToTime } from '../../../utils';

interface IProps {
  isActive: boolean;
  script: IItem;
  primary: boolean;
}

interface IItem {
  start: number;
  end: number;
  sentence: string;
  speaker: string;
}

export function TranscriptItem({ isActive, script, primary }: IProps) {
  return (
    <Box
      className={`flex flex-col gap-[10px] ${primary ? '' : 'self-end'} transcriptItemm`}
    >
      <Box
        className={`flex items-center gap-[10px] ${primary ? 'justify-start' : 'justify-end'}`}
      >
        <img className="w-8 h-8s" src={Logo} alt="Logo-image" />
        <Typography className="text-[#0C0C3E] font-[Inter-Bold] gap-2">
          {script.speaker}
          <span className="text-[#1F1F4DE5] font-[Inter-Regular] ml-2">
            {msToTime(script.start)}
          </span>
        </Typography>
      </Box>
      <Typography
        variant="body2"
        className={`p-4  max-w-[80%] ${primary ? 'rounded-msg-top bg-[#3c7aff] text-white-light self-start' : 'rounded-msg-top-right bg-white-light text-blue-dark shadow-[0_0_15px_rgb(30_95_188/17%)] self-end'}  ${isActive ? 'underline font-[Inter-Medium]' : ''}`}
      >
        {script.sentence}
      </Typography>
    </Box>
  );
}
