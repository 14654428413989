import React from 'react';
import { IconsProps } from './Icons';

export const SearchIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 24 24'}
      width={width ? width : '24'}
      height={height ? height : '24'}
      fill={fill ? fill : 'none'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.385 15.446C13.0371 16.5229 11.3281 17.0429 9.60885 16.8991C7.88962 16.7552 6.29072 15.9586 5.14051 14.6727C3.99031 13.3869 3.37614 11.7094 3.42412 9.9848C3.47211 8.26024 4.17862 6.6195 5.39854 5.39958C6.61846 4.17965 8.2592 3.47315 9.98376 3.42516C11.7083 3.37717 13.3858 3.99135 14.6717 5.14155C15.9576 6.29175 16.7542 7.89066 16.898 9.60989C17.0418 11.3291 16.5219 13.0382 15.445 14.386L20.601 19.541C20.6747 19.6097 20.7338 19.6925 20.7747 19.7845C20.8157 19.8765 20.8378 19.9758 20.8396 20.0765C20.8413 20.1772 20.8228 20.2772 20.7851 20.3706C20.7474 20.464 20.6912 20.5488 20.62 20.62C20.5488 20.6913 20.464 20.7474 20.3706 20.7851C20.2772 20.8228 20.1771 20.8414 20.0764 20.8396C19.9757 20.8378 19.8764 20.8158 19.7844 20.7748C19.6924 20.7338 19.6096 20.6747 19.541 20.601L14.385 15.446ZM6.45897 13.884C5.72501 13.15 5.22512 12.2148 5.02248 11.1968C4.81984 10.1787 4.92354 9.12344 5.32048 8.1643C5.71742 7.20517 6.38977 6.38523 7.25258 5.8081C8.11538 5.23098 9.12991 4.92258 10.1679 4.92189C11.206 4.92119 12.2209 5.22822 13.0845 5.80418C13.9481 6.38014 14.6215 7.19917 15.0198 8.15778C15.418 9.11638 15.5231 10.1715 15.3218 11.1898C15.1206 12.2082 14.6219 13.144 13.889 13.879L13.884 13.884L13.879 13.888C12.8941 14.8706 11.5594 15.4221 10.1681 15.4214C8.77688 15.4206 7.44281 14.8677 6.45897 13.884Z"
        fill="black"
      />
    </svg>
  );
};
