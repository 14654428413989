import React from 'react';
import { IconsProps } from './Icons';
export const CheckIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : "0 0 97 97"}
      width={width ? width : "97"}
      height={height ? height : "97"}
      fill={fill ? fill : "none"}
    >
      <circle
        cx="48.5002"
        cy="48.4999"
        r="48.4963"
        fill="url(#paint0_linear_487_7126)"
      />
      <path
        d="M40.7692 59.2464L31.8267 50.3038C31.3448 49.822 30.6913 49.5513 30.0098 49.5513C29.3284 49.5513 28.6749 49.822 28.193 50.3038C27.7111 50.7857 27.4404 51.4392 27.4404 52.1207C27.4404 52.4581 27.5069 52.7922 27.636 53.104C27.7651 53.4157 27.9544 53.699 28.193 53.9375L38.9653 64.7098C39.9703 65.7149 41.5939 65.7149 42.599 64.7098L69.8647 37.4441C70.3465 36.9623 70.6172 36.3087 70.6172 35.6273C70.6172 34.9458 70.3465 34.2923 69.8647 33.8104C69.3828 33.3286 68.7292 33.0579 68.0478 33.0579C67.3663 33.0579 66.7128 33.3286 66.2309 33.8104L40.7692 59.2464Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_487_7126"
          x1="48.5002"
          y1="0.00366211"
          x2="48.5002"
          y2="96.9962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#25D366" />
          <stop offset="1" stop-color="#55D554" />
        </linearGradient>
      </defs>
    </svg>
  );
};
