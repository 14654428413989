import React from 'react';
import { IconsProps } from './Icons';

export const AverageScoreIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 22 22'}
      width={width ? width : '22'}
      height={height ? height : '22'}
      fill={fill ? fill : 'none'}
    >
      <circle cx="11" cy="11" r="11" fill="#FFC798" fill-opacity="0.27" />
      <g clip-path="url(#clip0_802_5439)">
        <path
          d="M13.6248 14C12.2748 14 11.7386 12.434 11.2199 10.9198C10.7287 9.48425 10.2198 8 9.1248 8C8.86908 8.01506 8.62364 8.10592 8.41974 8.261C8.21585 8.41607 8.06274 8.62834 7.97993 8.87075L7.26855 8.6315C7.28768 8.57525 7.74518 7.25 9.1248 7.25C10.7561 7.25 11.3793 9.07062 11.9298 10.6767C12.3832 12.0001 12.8111 13.25 13.6248 13.25C13.8805 13.235 14.1259 13.1442 14.3298 12.9892C14.5337 12.8342 14.6868 12.622 14.7697 12.3796L15.4807 12.6185C15.4619 12.6747 15.0044 14 13.6248 14Z"
          fill="#D8920B"
        />
        <path
          d="M6.5 15.5V11.375H7.25V10.625H6.5V5.75H5.75V15.5C5.75 15.6989 5.82902 15.8897 5.96967 16.0303C6.11032 16.171 6.30109 16.25 6.5 16.25H16.25V15.5H6.5Z"
          fill="#D8920B"
        />
        <path
          d="M8 10.625H8.75V11.375H8V10.625ZM9.5 10.625H10.25V11.375H9.5V10.625ZM12.5 10.625H13.25V11.375H12.5V10.625ZM14 10.625H14.75V11.375H14V10.625ZM15.5 10.625H16.25V11.375H15.5V10.625Z"
          fill="#D8920B"
        />
      </g>
      <defs>
        <clipPath id="clip0_802_5439">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
