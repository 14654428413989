import React, { useMemo, useEffect, useRef, useState } from 'react';
import { ITranscriptItem } from '../../pages/call-audit/staticData';
import { Box } from '@mui/system';
import { TranscriptItem } from '../details-page/details/TranscriptItem';
import { CallImage } from '../details-page/details/CallImage';
import { AudioPlayer } from './AudioPlayer';
import { Tab, Tabs } from '@mui/material';

interface TranscriptProps {
  script: ITranscriptItem[];
  src: string;
}

export function Transcript({ script, src }: TranscriptProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [speakers, setSpeakers] = useState<string[]>([]);
  const [time, setTime] = useState<number>(0);

  const data = useMemo(
    () =>
      script.map(({ start, end, speaker, ...item }) => {
        setSpeakers((speakers) => Array.from(new Set(speakers).add(speaker)));
        return {
          ...item,
          speaker,
          start: start,
          end: end,
        };
      }),
    [script],
  );
  const activeItem = data.findIndex(
    (item) => time >= item.start && time <= item.end + 10,
  );

  useEffect(() => {
    const selectedItem = document.querySelectorAll('.transcriptItemm');
    const items: HTMLElement[] = [];
    selectedItem.forEach((s) => {
      const e = s as HTMLElement;
      items.push(e);
    });
    const factor = items[0]?.offsetTop;
    if (items[activeItem] && containerRef.current) {
      containerRef.current.scrollTop = Math.max(
        0,
        items[activeItem].offsetTop - factor,
      );
    }
  }, [time, data]);

  return (
    <Box className="flex flex-col gap-6">
      <CallImage
        primary={
          data[activeItem]
            ? data[activeItem].speaker === speakers[0]
            : undefined
        }
        sentence={data[activeItem]?.sentence ?? ''}
        charLength = {data[activeItem]?.sentence?.length ?? 0}
      />
      <AudioPlayer src={src} setTimeCallBack={setTime} />
      <Tabs value={0}>
        <Tab className="border-none px-0" label="Transcript" value={0} />
        <Tab className="border-none" label="Overview" value={1} />
      </Tabs>
      <Box
        ref={containerRef}
        className="base-scrollbar max-h-[calc(100vh-724px)] min-h-[229px] flex flex-col gap-4 w-full pr-3 bg-[rgb(245_245_245_/_0.38)] p-4 rounded-2xl"
      >
        {data.map((item, id) => (
          <TranscriptItem
            key={id}
            script={item}
            isActive={time >= item.start && time <= item.end + 10}
            primary={item.speaker === speakers[0]}
          />
        ))}
      </Box>
    </Box>
  );
}
