import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LandingHeader } from '../components/landing-header';
import { LandingFooter } from '../components/landing-footer';
import { useAuthUser } from '../hooks';
import { PAGE_URL } from '../utils';

export function GuestLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { accessToken } = useAuthUser();

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      navigate(PAGE_URL.DASHBOARD);
    }
  }, [accessToken, navigate]);

  return (
    <>
      <main className={`${location.pathname !== '/login' ? '' : 'overflow-auto h-screen scrollbar-thin'}`}>
        {location.pathname !== '/login' ? <LandingHeader /> : null} <Outlet />
        {location.pathname !== '/login' ? <LandingFooter /> : null}{' '}
      </main>
    </>
  );
}
