import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  RingIcon,
  TimeIcon,
  YellowIcon,
  GraphIcon,
  GreenCallIcon,
  OrangeCallIcon,
} from '../../assets/icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Button } from '@mui/material';
import { SelectAutoWidth } from '../DropDown-Menu/SelectAutoWidth';

export interface IOutlineProps {
  callAuditedToday: number;
  averageCallScore: number;
  aiAuditedCalls: number;
  manualAuditedCalls: number;
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="flex gap-4 justify-end items-center absolute right-[10px] -top-[37px] *:text-black-dark-200">
      <Button
        className="p-0 min-w-max hover:bg-[transparent] "
        onClick={() => previous()}
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        className="p-0 min-w-max hover:bg-[transparent] "
        onClick={() => next()}
      >
        <ArrowRightIcon />
      </Button>
    </div>
  );
};

export default function OutlineCallCard({
  callAuditedToday,
  averageCallScore,
  aiAuditedCalls,
  manualAuditedCalls,
}: IOutlineProps) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 4,
    },
    laptop: {
      breakpoint: { max: 1499, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <div className="h-full flex flex-col-reverse -mx-[10px] relative">
      <Carousel
        ssr={false}
        partialVisbile={false}
        responsive={responsive}
        renderButtonGroupOutside={true}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
      >
        <Card
          variant="outlined"
          className="bg-[#FFEBF7] border-[#fe3bab38] mx-[10px] h-full"
        >
          <CardContent className="flex flex-start py-0 px-0 items-center gap-5">
            <RingIcon className="fill-[#FE3BAB]" />
            <Typography className="flex flex-col gap-1.5">
              <Typography variant="h1">{callAuditedToday}</Typography>
              <Typography variant="subtitle2">Total Calls Audits</Typography>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          className="bg-[#EEF5FF] border-[#D4E6FF] mx-[10px] h-full"
        >
          <CardContent className="flex flex-start py-0 px-0 items-center gap-5">
            <GraphIcon />
            <Typography className="flex flex-col gap-1.5">
              <Typography variant="h1">
                {Math.round(averageCallScore)}%
              </Typography>
              <Typography variant="subtitle2">
                Avg Call Quality Score
              </Typography>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          className="bg-[#E5FBF8] border-[#CBFFF8] mx-[10px] h-full"
        >
          <CardContent className="flex flex-start py-0 px-0 items-center gap-5">
            <GreenCallIcon className="fill-[#06BCC1]" />
            <Typography className="flex flex-col gap-1.5">
              <Typography variant="h1">{aiAuditedCalls}</Typography>
              <Typography variant="subtitle2">AI Audited Calls</Typography>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          className="bg-[#FFF7EC] border-[#FFE7C6] mx-[10px] h-full"
        >
          <CardContent className="flex flex-start py-0 px-0 items-center gap-5">
            <RingIcon className="fill-[#EFAD2B]" />
            <Typography className="flex flex-col gap-1.5">
              <Typography variant="h1">{manualAuditedCalls}</Typography>
              <Typography variant="subtitle2">Manual Audited Calls</Typography>
            </Typography>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          className="bg-[#FFF7EC] border-[#FFE7C6] mx-[10px] h-full"
        >
          <CardContent className="flex flex-start py-0 px-0 items-center gap-5">
            <RingIcon className="fill-[#EFAD2B]" />
            <Typography className="flex flex-col gap-1.5">
              <Typography variant="h1">200</Typography>
              <Typography variant="subtitle2">No Of Auditors</Typography>
            </Typography>
          </CardContent>
        </Card>
      </Carousel>
    </div>
  );
}
