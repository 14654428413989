import React from 'react';
import { LoginLogoIcon } from '../../assets/icons';
import SkilldifyWhiteLogo from '../../assets/images/LandingPage/skilldify-white-logo.svg';
import { useLocation } from 'react-router-dom';
import {
  Instagram,
  LinkedIn,
  WhatsApp,
  Phone,
  Mail,
} from '@mui/icons-material';
import { HomeCta } from '../../pages/landing-page/Home/HomeCta';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../utils';

export function LandingFooter() {
  const goPageTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const navigat = useNavigate();
  const location = useLocation();
  const navigate = (url: string) => {
    navigat(url);
    goPageTop();
  };
  return (
    <>
      {location.pathname !== '/whyus' ? <HomeCta /> : null}
      {/* <HomeCta /> */}
      <footer className="bg-black-dark-100 py-6 pt-40">
        <div className="container">
          <div className="md:justify-between grid md:grid-cols-[30%_1fr] pb-20">
            <div className="mb-6 md:mb-0">
              <a
                onClick={() => navigate(PAGE_URL.HOME)}
                className="flex cursor-pointer items-center mb-3"
              >
                <img width="105" height="27" src={SkilldifyWhiteLogo} />
              </a>
              <p className="text-white-light body-l lg:max-w-56 text-balance">
                Skilldify.Ai: Transforming Customer Service Excellence
              </p>
            </div>
            <div className="flex flex-col md:flex-row gap-10 md:ml-auto *:text-white-light *:space-y-6">
              <div>
                <h2 className="body-b">Quick Links</h2>
                <ul className="space-y-4 body-l">
                  <li>
                    <a
                      onClick={() => navigate(PAGE_URL.HOME)}
                      className="cursor-pointer hover:underline"
                    >
                      Homepage
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => navigate(PAGE_URL.EXPANDQA)}
                      className="cursor-pointer hover:underline"
                    >
                      Use Cases
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => navigate(PAGE_URL.WHYUS)}
                      className="cursor-pointer hover:underline"
                    >
                      Why Us
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="body-b">Our Products</h2>
                <ul className="space-y-4 body-l">
                  <li>
                    <a
                      onClick={() => navigate(PAGE_URL.SKILLDIFYQA)}
                      className="cursor-pointer hover:underline"
                    >
                      Skilldify QA{' '}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => navigate(PAGE_URL.SKILLDIFYLEARNING)}
                      className="cursor-pointer hover:underline"
                    >
                      Skilldify Learning
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => navigate(PAGE_URL.SKILLDIFYANALYSIS)}
                      className="cursor-pointer hover:underline"
                    >
                      Skilldify Analysis
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="body-b">Contact Us</h2>
                <ul className="space-y-4 body-l">
                  <li>
                    <a
                      href="tel:+91 99332 08943"
                      className="hover:underline space-x-2.5"
                    >
                      <i>
                        <Phone />
                      </i>
                      <span>+91 99332 08943</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:support@skilldify.ai"
                      className="hover:underline space-x-2.5"
                    >
                      <i>
                        <Mail />
                      </i>
                      <span>support@skilldify.ai</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="body-l text-white-light sm:text-center dark:text-gray-400">
              ©2024 @skilldify.Ai. All Rights Reserved.
            </span>
            <div className="flex mt-4 sm:justify-center sm:mt-0 gap-5 *:text-white-light hover:*:opacity-50">
              <a href="https://www.instagram.com/skilldify/" target="_blank">
                <Instagram />
              </a>
              <a
                href="https://www.linkedin.com/company/skilldify/"
                target="_blank"
              >
                <LinkedIn />
              </a>
              <a href="https://wa.me/+9199332008943" target="_blank">
                <WhatsApp />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
