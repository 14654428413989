/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppHeader } from '../components';
import { PAGE_URL } from '../utils';
import { Navbar } from '../components/navbar/Navbar';
import { Box } from '@mui/material';
import { useAuthUser } from '../hooks';

export function PrivateLayout() {
  const { accessToken } = useAuthUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!accessToken) {
      navigate(PAGE_URL.LOGIN);
    }
  }, [accessToken]);

  return (
    <>
      <AppHeader />
      <Box className="grid grid-cols-[auto_1fr]">
        <Navbar />
        <Outlet />
      </Box>
      {/* <AppFooter /> */}
    </>
  );
}
