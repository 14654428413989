import React from 'react';
import { IconsProps } from './Icons';

export const SpeakerOneIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={className}
      viewBox={viewBox ? viewBox : '0 0 235 161'}
      width={width ? width : '235'}
      height={height ? height : '161'}
      fill={fill ? fill : 'none'}
    >
      <path
        d="M189.712 61.0502H134.99L139.452 21.0724H194.893L189.712 61.0502Z"
        fill="#728686"
      />
      <path
        d="M183.096 69.262C182.863 69.262 182.672 69.0714 182.672 68.8377V58.6597C182.672 58.4266 182.863 58.2353 183.096 58.2353C183.329 58.2353 183.52 58.426 183.52 58.6597V68.8377C183.52 69.0707 183.329 69.262 183.096 69.262Z"
        fill="#FDA860"
      />
      <path
        d="M185.641 69.262C185.408 69.262 185.217 69.0713 185.217 68.8376V58.6596C185.217 58.4265 185.407 58.2352 185.641 58.2352C185.874 58.2352 186.065 58.4259 186.065 58.6596V68.8376C186.065 69.0707 185.874 69.262 185.641 69.262Z"
        fill="#FDA860"
      />
      <path
        d="M139.451 20.9011L134.989 61.0502L132.907 60.4558L137.15 20.2286L139.451 20.9011Z"
        fill="#052364"
      />
      <path
        d="M139.451 20.9011L194.892 21.0724L191.706 20.2098H137.071L139.451 20.9011Z"
        fill="white"
      />
      <path
        d="M141.999 59.5664C141.815 59.5331 141.692 59.3564 141.725 59.1723L142.206 56.5015C142.24 56.318 142.416 56.1945 142.601 56.2278C142.784 56.2611 142.907 56.4379 142.874 56.6219L142.393 59.2934C142.36 59.4768 142.183 59.6003 141.999 59.567V59.5664Z"
        fill="#052364"
      />
      <path
        d="M143.228 59.5664C143.045 59.5331 142.921 59.3558 142.955 59.1723L143.436 56.5015C143.469 56.318 143.647 56.1945 143.83 56.2278C144.013 56.2611 144.137 56.4379 144.104 56.6219L143.622 59.2934C143.589 59.4768 143.412 59.6003 143.228 59.567V59.5664Z"
        fill="#052364"
      />
      <path
        d="M144.459 59.5664C144.275 59.5331 144.152 59.3564 144.185 59.1723L144.666 56.5015C144.7 56.318 144.877 56.1945 145.06 56.2278C145.244 56.2611 145.367 56.4379 145.334 56.6219L144.853 59.2934C144.82 59.4768 144.642 59.6003 144.459 59.567V59.5664Z"
        fill="#052364"
      />
      <path
        d="M145.688 59.5664C145.505 59.5331 145.381 59.3564 145.415 59.1723L145.896 56.5015C145.929 56.318 146.107 56.1945 146.29 56.2278C146.473 56.2611 146.597 56.4379 146.564 56.6219L146.082 59.2934C146.049 59.4768 145.872 59.6003 145.688 59.567V59.5664Z"
        fill="#052364"
      />
      <path
        d="M146.918 59.5664C146.734 59.5331 146.611 59.3564 146.644 59.1723L147.125 56.5015C147.159 56.318 147.336 56.1945 147.519 56.2278C147.703 56.2611 147.826 56.4379 147.793 56.6219L147.312 59.2934C147.279 59.4768 147.101 59.6003 146.918 59.567V59.5664Z"
        fill="#052364"
      />
      <path
        d="M148.147 59.5664C147.964 59.5331 147.84 59.3564 147.874 59.1723L148.355 56.5015C148.388 56.318 148.566 56.1945 148.749 56.2278C148.932 56.2611 149.056 56.4379 149.023 56.6219L148.542 59.2934C148.509 59.4768 148.331 59.6003 148.148 59.567L148.147 59.5664Z"
        fill="#052364"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
        <path
          d="M177.703 52.4123L155.381 52.5466C151.74 52.5684 149.071 49.6657 149.475 46.0825L150.695 35.2695C151.106 31.6252 154.477 28.6843 158.159 28.7473L180.724 29.1335C184.222 29.1934 186.68 32.1313 186.211 35.6491L184.817 46.091C184.355 49.5525 181.164 52.3911 177.703 52.4116V52.4123Z"
          fill="#052364"
        />
      </g>
      <path
        d="M161.18 45.913L158.705 69.2311H174.879C174.879 69.2311 176.708 50.5671 176.943 45.913H161.181H161.18Z"
        fill="#728686"
      />
      <path
        d="M161.18 45.9131C161.18 45.9131 160.304 45.2508 160.304 45.5807C160.304 45.9106 157.554 69.3407 157.554 69.3407L158.704 69.2306C158.704 69.2306 161.179 45.3646 161.179 45.9125L161.18 45.9131Z"
        fill="#052364"
      />
      <path
        d="M160.304 45.5807L176.914 45.4705L176.942 45.913H161.18C161.18 45.913 160.414 45.2508 160.304 45.5807H160.304Z"
        fill="white"
      />
      <path
        opacity="0.45"
        d="M13.4946 130.262V76.6813C13.4946 76.6813 4.36748 74.1448 4.9565 86.8267C4.9565 86.8267 4.9565 93.4845 2.89583 95.7037C0.835165 97.923 -1.81513 108.068 2.89583 113.141C2.89583 113.141 4.07327 116.946 2.60162 119.799C1.12937 122.653 2.0126 133.432 13.4952 130.262L13.4946 130.262Z"
        fill="#41E388"
      />
      <path
        opacity="0.57"
        d="M12.6305 130.262V76.6813C12.6305 76.6813 21.7576 74.1448 21.1686 86.8267C21.1686 86.8267 21.1686 93.4845 23.2292 95.7037C25.2899 97.923 27.9402 108.068 23.2292 113.141C23.2292 113.141 22.0518 116.946 23.5234 119.799C24.9957 122.653 24.1125 133.432 12.6299 130.262L12.6305 130.262Z"
        fill="#21AA5B"
      />
      <path
        opacity="0.88"
        d="M25.4968 101.613C25.4078 101.409 25.1693 101.317 24.9653 101.404L13.2666 106.483V92.7035L13.3895 92.6393C13.4088 92.6302 13.427 92.623 13.4446 92.6103L21.0831 88.6215C21.2411 88.5392 21.3065 88.3358 21.2284 88.1675C21.1509 87.9986 20.959 87.9314 20.8016 88.0131L13.266 91.948V76.6807H12.6128V91.6738L5.43677 88.1469C5.27696 88.0688 5.08263 88.1336 5.00394 88.2946C4.92524 88.455 4.99122 88.6494 5.15165 88.7281L12.6128 92.3954V106.465L1.26701 101.539C1.06361 101.452 0.825093 101.544 0.735499 101.748C0.647116 101.953 0.740342 102.19 0.944956 102.28L12.6128 107.345V120.252L3.84829 116.183C3.67213 116.1 3.46086 116.178 3.37853 116.355C3.2962 116.532 3.37369 116.743 3.55045 116.825L12.6128 121.032V129.822H13.266V121.075C13.2805 121.061 13.2938 121.047 13.3053 121.03C13.3217 121.026 13.338 121.027 13.3538 121.02L22.6806 116.689C22.858 116.607 22.9349 116.396 22.8526 116.22C22.7702 116.042 22.559 115.965 22.3828 116.048L13.2654 120.28V107.402C13.295 107.38 13.3211 107.354 13.3435 107.324C13.3513 107.321 13.3598 107.322 13.3683 107.319L25.2861 102.145C25.4901 102.056 25.584 101.819 25.4956 101.613H25.4968Z"
        fill="#21AA5B"
      />
      <path
        d="M2.0127 129.674V147.407C2.0127 150.128 3.78036 152.333 5.9615 152.333H20.44C22.6206 152.333 24.3889 150.127 24.3889 147.407V129.674H2.0127Z"
        fill="#9A5401"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
        <path
          d="M23.6515 149.925H9.17297C6.99244 149.925 5.22416 147.719 5.22416 144.999V129.674H2.0127V147.407C2.0127 150.128 3.78036 152.333 5.9615 152.333H20.44C21.8863 152.333 23.1478 151.359 23.8355 149.912C23.7744 149.916 23.7139 149.924 23.6515 149.924V149.925Z"
          fill="#3A6FE6"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
        <path
          d="M24.3889 129.674H2.0127V137.101H24.3889V129.674Z"
          fill="#9A5401"
        />
      </g>
      <path
        opacity="0.45"
        d="M222.227 129.615V76.0337C222.227 76.0337 213.1 73.4973 213.689 86.1791C213.689 86.1791 213.689 92.8369 211.628 95.0562C209.568 97.2754 206.917 107.421 211.628 112.494C211.628 112.494 212.806 116.298 211.334 119.152C209.862 122.005 210.745 132.784 222.228 129.614L222.227 129.615Z"
        fill="#41E388"
      />
      <path
        opacity="0.57"
        d="M221.363 129.615V76.0337C221.363 76.0337 230.49 73.4973 229.901 86.1791C229.901 86.1791 229.901 92.8369 231.962 95.0562C234.022 97.2754 236.673 107.421 231.962 112.494C231.962 112.494 230.784 116.298 232.256 119.152C233.728 122.005 232.845 132.784 221.362 129.614L221.363 129.615Z"
        fill="#21AA5B"
      />
      <path
        opacity="0.88"
        d="M234.23 100.966C234.141 100.761 233.902 100.67 233.698 100.756L222 105.835V92.0559L222.122 91.9917C222.141 91.9827 222.159 91.9754 222.177 91.9627L229.815 87.9739C229.973 87.8916 230.039 87.6882 229.961 87.5199C229.883 87.351 229.691 87.2838 229.534 87.3655L221.998 91.3004V76.0331H221.345V91.0262L214.169 87.4993C214.009 87.4212 213.815 87.486 213.736 87.647C213.658 87.8075 213.724 88.0018 213.884 88.0805L221.345 91.7478V105.817L209.999 100.891C209.796 100.804 209.557 100.896 209.468 101.101C209.379 101.305 209.473 101.543 209.677 101.632L221.345 106.698V119.604L212.581 115.536C212.404 115.453 212.193 115.53 212.111 115.708C212.029 115.884 212.105 116.095 212.283 116.177L221.345 120.385V129.175H221.998V120.428C222.012 120.414 222.026 120.399 222.038 120.383C222.054 120.378 222.07 120.379 222.086 120.372L231.414 116.042C231.591 115.959 231.668 115.749 231.586 115.572C231.503 115.394 231.292 115.317 231.116 115.4L221.998 119.633V106.755C222.028 106.732 222.054 106.706 222.076 106.676C222.084 106.673 222.093 106.674 222.101 106.671L234.018 101.497C234.222 101.408 234.316 101.171 234.228 100.966H234.23Z"
        fill="#21AA5B"
      />
      <path
        d="M210.745 129.026V146.76C210.745 149.48 212.513 151.685 214.694 151.685H229.172C231.353 151.685 233.121 149.48 233.121 146.76V129.026H210.745Z"
        fill="#9A5401"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.24">
        <path
          d="M232.384 149.277H217.905C215.725 149.277 213.957 147.072 213.957 144.352V129.026H210.745V146.76C210.745 149.48 212.513 151.685 214.694 151.685H229.172C230.619 151.685 231.88 150.712 232.568 149.265C232.507 149.268 232.446 149.276 232.384 149.276V149.277Z"
          fill="#3A6FE6"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.4">
        <path
          d="M233.121 129.026H210.745V136.453H233.121V129.026Z"
          fill="#9A5401"
        />
      </g>
      <path
        d="M96.4186 91.0927H96.122C95.2634 91.0927 94.5674 91.7887 94.5674 92.6473V129.918C94.5674 130.777 95.2634 131.473 96.122 131.473H96.4186C97.2772 131.473 97.9732 130.777 97.9732 129.918V92.6473C97.9732 91.7887 97.2772 91.0927 96.4186 91.0927Z"
        fill="#3A6FE6"
      />
      <path
        d="M56.4326 38.2218L96.428 38.8562C96.428 38.8562 98.738 56.9361 97.2785 72.5049C95.819 88.0731 104.576 99.7494 123.063 98.2899C123.063 98.2899 125.01 99.7494 123.55 102.669C122.091 105.588 85.5665 105.445 85.5665 105.445C85.5665 105.445 59.8172 107.533 58.3577 90.9922C56.8981 74.4506 61.3185 42.8601 56.4326 38.2218Z"
        fill="#5388FF"
      />
      <path
        d="M78.7466 105.726C76.3712 105.726 73.9788 105.574 71.5948 105.11C66.1538 104.051 61.7068 101.418 59.0722 97.696C55.1301 92.1261 55.5218 84.918 55.8674 78.5586L55.885 78.2402C56.5709 65.5808 56.2282 52.7724 54.8649 40.1705C54.7596 39.1989 55.4624 38.3266 56.4334 38.2218C57.4051 38.1147 58.2768 38.8193 58.3821 39.7903C59.766 52.5817 60.1147 65.5826 59.4179 78.4321L59.401 78.7511C59.071 84.8169 58.7302 91.0885 61.9604 95.6523C64.0671 98.6283 67.7284 100.754 72.2716 101.638C76.5159 102.464 81.0065 102.184 85.3476 101.914C86.3379 101.854 87.163 102.595 87.223 103.57C87.2835 104.545 86.5425 105.385 85.5673 105.445C83.3238 105.585 81.0434 105.727 78.7479 105.727L78.7466 105.726Z"
        fill="#3A6FE6"
      />
      <path
        d="M78.1335 133.982C77.9797 133.249 78.4537 132.524 79.1868 132.37L94.8845 129.081C95.6176 128.927 96.3429 129.401 96.4966 130.134C96.6504 130.867 96.1764 131.592 95.4433 131.746L79.7456 135.036C79.0125 135.189 78.2872 134.715 78.1335 133.982Z"
        fill="#3A6FE6"
      />
      <path
        d="M78.5544 140.402H79.9697C81.0015 140.402 81.8379 139.566 81.8379 138.534V134.075C81.8379 133.043 81.0015 132.207 79.9697 132.207H78.5544C77.5226 132.207 76.6862 133.043 76.6862 134.075V138.534C76.6862 139.566 77.5226 140.402 78.5544 140.402Z"
        fill="#3A6FE6"
      />
      <path
        d="M113.812 133.982C113.966 133.249 113.492 132.524 112.759 132.37L97.061 129.081C96.3279 128.927 95.6026 129.401 95.4489 130.134C95.2951 130.867 95.7691 131.592 96.5022 131.746L112.2 135.036C112.933 135.189 113.658 134.715 113.812 133.982Z"
        fill="#3A6FE6"
      />
      <path
        d="M113.391 132.207H111.976C110.944 132.207 110.107 133.043 110.107 134.075V138.534C110.107 139.566 110.944 140.402 111.976 140.402H113.391C114.423 140.402 115.259 139.566 115.259 138.534V134.075C115.259 133.043 114.423 132.207 113.391 132.207Z"
        fill="#3A6FE6"
      />
      <path
        d="M96.2638 140.865C97.0126 140.865 97.6253 140.252 97.6253 139.503V123.464C97.6253 122.715 97.0126 122.102 96.2638 122.102C95.515 122.102 94.9023 122.715 94.9023 123.464V139.503C94.9023 140.251 95.515 140.865 96.2638 140.865Z"
        fill="#3A6FE6"
      />
      <path
        d="M97.0628 135.836H95.6475C94.6157 135.836 93.7793 136.672 93.7793 137.704V142.163C93.7793 143.195 94.6157 144.031 95.6475 144.031H97.0628C98.0946 144.031 98.931 143.195 98.931 142.163V137.704C98.931 136.672 98.0946 135.836 97.0628 135.836Z"
        fill="#3A6FE6"
      />
      <path
        d="M105.052 30.932C104.543 30.5997 104.883 29.7685 104.883 29.7685C107.492 25.9329 103.681 22.8861 100.701 21.6826C100.638 21.4768 100.469 21.2777 100.193 21.1239C99.0057 20.4586 98.7872 18.3083 99.3453 15.8039C99.9035 13.3001 97.8186 7.15931 97.8186 7.15931C96.9178 7.12299 96.1284 7.17263 95.4347 7.29188C92.6482 0.833232 85.684 0.985179 82.9441 1.32176C79.3483 0.879845 68.4674 0.742428 69.3179 15.4716C69.3179 15.4716 69.8271 21.1239 67.4516 21.1239C65.0761 21.1239 57.612 24.7815 61.0051 29.7685C61.0051 29.7685 61.3441 30.5997 60.8356 30.932C60.3264 31.2644 56.2548 37.7485 67.1126 38.7455C75.894 39.5525 80.901 39.8146 82.9441 39.182C84.9872 39.8146 89.9936 39.5518 98.7757 38.7455C109.633 37.7479 105.562 31.2644 105.053 30.932H105.052Z"
        fill="#222D33"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.37">
        <path
          d="M97.3754 32.3032C97.0194 32.0326 97.2567 31.3558 97.2567 31.3558C99.0831 28.2339 96.4159 25.7537 94.3298 24.7736C94.2862 24.6059 94.1675 24.4443 93.975 24.319C93.1439 23.7778 92.9907 22.0271 93.3812 19.9888C93.6639 18.5147 92.9798 15.4994 92.5791 13.9394C88.0981 14.3977 83.6003 14.8257 79.1254 14.457C78.6714 14.5387 78.2192 14.6356 77.7676 14.7409C76.2941 15.7476 75.9133 17.8222 75.265 19.4585C75.2505 20.7038 75.1112 22.1215 74.3933 22.9394C73.4319 24.0351 71.1134 24.3323 69.8161 24.8608C69.1314 25.1399 68.4922 25.448 67.8783 25.7773C66.334 27.0353 65.176 29.0142 66.5453 31.3558C66.5453 31.3558 66.7826 32.0326 66.4266 32.3032C66.0707 32.5738 63.2206 37.8514 70.8204 38.6632C76.9667 39.32 80.4717 39.5337 81.9016 39.0185C83.3315 39.5331 86.836 39.3194 92.9828 38.6632C100.583 37.8514 97.7326 32.5738 97.3766 32.3032H97.3754Z"
          fill="#222D33"
        />
      </g>
      <path
        d="M123.577 93.3217C123.47 93.1528 123.354 93.0124 123.233 92.8846C122.796 92.1691 122.069 91.5104 120.885 91.0758C120.882 91.0746 120.877 91.0728 120.874 91.0716C113.824 87.377 93.1069 74.2351 93.1069 74.2351C93.1069 74.2351 79.4426 82.6533 85.7959 85.7401C92.9217 89.2022 105.847 95.949 114.649 98.1247C114.633 98.1677 114.617 98.2112 114.606 98.2494C114.606 98.2494 112.457 97.3649 112.205 107.852C111.953 118.34 109.189 135.804 109.189 135.804L113.848 136.157C113.848 136.157 125.978 97.1119 123.577 93.3211V93.3217Z"
        fill="#20376C"
      />
      <path
        d="M114.042 135.183C114.042 135.183 115.754 139.678 118.537 140.106C121.319 140.534 124.102 139.892 123.888 142.888C123.674 145.885 119.607 144.815 116.61 144.387C113.614 143.959 111.473 140.106 111.26 139.464C111.046 138.822 110.189 144.387 110.189 144.387L108.691 144.6C108.691 144.6 107.621 135.611 109.547 134.755L114.042 135.183V135.183Z"
        fill="#20376C"
      />
      <path
        d="M114.042 135.183C114.042 135.183 116.847 139.99 119.894 140.273C119.894 140.273 120.814 141.69 118.831 141.62C116.847 141.549 112.808 140.132 112.241 135.314L114.042 135.183Z"
        fill="#FFC3BD"
      />
      <path
        d="M114.353 95.5961C114.238 95.4151 114.113 95.2655 113.982 95.1311C113.543 94.4265 112.829 93.7788 111.661 93.3495C111.658 93.3483 111.653 93.3465 111.65 93.3453C107.894 91.3767 96.5108 86.32 86.6942 82.0238L93.7389 74.6135L71.3064 75.3441C70.902 75.1698 70.6817 75.0754 70.6817 75.0754C70.6817 75.0754 61.9904 87.6621 69.434 95.0228C74.4925 100.026 92.1092 103.327 103.784 102.723C103.399 104.129 103.071 106.412 102.982 110.127C102.729 120.615 99.9657 138.079 99.9657 138.079L104.625 138.432C104.625 138.432 116.755 99.3869 114.354 95.5961H114.353Z"
        fill="#20376C"
      />
      <path
        d="M104.62 138.324C104.62 138.324 106.332 142.819 109.115 143.247C111.897 143.675 114.68 143.033 114.466 146.029C114.251 149.026 110.185 147.955 107.189 147.527C104.192 147.099 102.051 143.247 101.838 142.605C101.623 141.962 100.767 147.527 100.767 147.527L99.2692 147.741C99.2692 147.741 98.1989 138.751 100.125 137.895L104.62 138.323V138.324Z"
        fill="#20376C"
      />
      <path
        d="M104.62 138.324C104.62 138.324 107.425 143.131 110.472 143.414C110.472 143.414 111.393 144.831 109.409 144.76C107.425 144.689 103.387 143.272 102.819 138.454L104.62 138.324Z"
        fill="#FFC3BD"
      />
      <path
        d="M98.9193 64.0571C99.5555 58.478 98.1474 47.9343 97.8611 44.7428C97.3725 39.3012 95.646 36.6921 92.868 37.0293C92.868 37.0293 89.6118 36.6854 90.4647 45.6927C91.1651 53.0902 90.9139 63.7041 93.026 67.0034C93.5593 68.0313 94.7477 69.1567 94.7477 69.1567L115.918 69.0023L115.609 64.5208C104.504 63.4892 101.709 63.2192 98.9205 64.0571H98.9193Z"
        fill="#F7EEB4"
      />
      <path
        d="M115.608 64.5201C115.608 64.5201 120.759 64.5958 122.15 66.5663H115.66L115.608 64.5201Z"
        fill="#5388FF"
      />
      <path
        d="M142.881 66.6402H105.745V69.0362H142.881V66.6402Z"
        fill="#A793F7"
      />
      <path
        d="M98.3526 49.0598C97.5299 48.4429 95.8743 46.2182 94.626 44.4547V37.5245L87.7048 36.1049C87.7048 36.1049 86.4626 39.8316 78.1219 35.9276C78.1219 35.9276 69.9585 37.5245 69.4258 38.412C68.8937 39.2994 74.572 59.3528 74.572 59.3528C74.572 59.3528 73.8619 73.5499 70.8454 74.9694C67.8283 76.389 93.7379 74.6147 93.7379 74.6147C93.7379 74.6147 95.1575 62.37 94.6254 59.1754V58.0725C99.6076 56.1395 99.5737 49.9757 98.3526 49.0598Z"
        fill="#F7EEB4"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.76">
        <path
          d="M77.5323 59.2269C74.9443 56.1075 73.3183 52.3524 72.2886 48.4018C72.2771 48.3788 72.2595 48.3649 72.2498 48.3388C71.7389 46.9701 71.0978 45.6135 70.5264 44.2417C72.0513 50.4461 74.5726 59.3522 74.5726 59.3522C74.5726 59.3522 73.8631 73.5493 70.846 74.9689C67.8295 76.3885 93.7391 74.6141 93.7391 74.6141C93.7391 74.6141 94.2543 70.1671 94.5473 65.9332C88.1468 65.7334 81.9393 64.539 77.5329 59.2263L77.5323 59.2269Z"
          fill="#F7EEB4"
        />
      </g>
      <path
        d="M85.9482 41.6398C85.9482 41.6398 89.3135 59.4642 87.07 69.0623H87.9423C87.9423 69.0623 89.5629 51.8608 85.9482 41.6398Z"
        fill="#E6D98A"
      />
      <path
        d="M75.9766 56.3484C75.6563 56.1553 81.4612 59.8383 84.7017 55.7249C84.7017 55.7249 82.5829 60.3371 75.9766 56.3484Z"
        fill="#E6D98A"
      />
      <path
        d="M90.0625 54.8525C90.0625 54.8525 92.4307 58.3424 95.173 57.5948C95.173 57.5948 93.303 57.4701 90.0625 54.8525Z"
        fill="#E6D98A"
      />
      <path
        d="M85.9491 46.3144C85.9491 46.5553 85.7542 46.7509 85.5126 46.7509C85.2711 46.7509 85.0762 46.5553 85.0762 46.3144C85.0762 46.0735 85.2717 45.8779 85.5126 45.8779C85.7536 45.8779 85.9491 46.0735 85.9491 46.3144Z"
        fill="#E6D98A"
      />
      <path
        d="M86.6347 52.4843C86.6347 52.7253 86.4391 52.9208 86.1982 52.9208C85.9573 52.9208 85.7617 52.7253 85.7617 52.4843C85.7617 52.2434 85.9573 52.0479 86.1982 52.0479C86.4391 52.0479 86.6347 52.2434 86.6347 52.4843Z"
        fill="#E6D98A"
      />
      <path
        d="M87.0087 59.34C87.0087 59.581 86.8131 59.7765 86.5722 59.7765C86.3313 59.7765 86.1357 59.581 86.1357 59.34C86.1357 59.0991 86.3313 58.9036 86.5722 58.9036C86.8131 58.9036 87.0087 59.0991 87.0087 59.34Z"
        fill="#E6D98A"
      />
      <path
        d="M86.5097 66.1959C86.5097 66.4368 86.3141 66.6323 86.0732 66.6323C85.8322 66.6323 85.6367 66.4368 85.6367 66.1959C85.6367 65.9549 85.8322 65.7594 86.0732 65.7594C86.3141 65.7594 86.5097 65.9549 86.5097 66.1959Z"
        fill="#E6D98A"
      />
      <path
        d="M81.6483 48.745H77.0366C76.3853 48.745 75.8525 48.2122 75.8525 47.5609C75.8525 46.9095 76.3853 46.3768 77.0366 46.3768H81.6483C82.2997 46.3768 82.8324 46.9095 82.8324 47.5609C82.8324 48.2122 82.2997 48.745 81.6483 48.745Z"
        fill="#E6D98A"
      />
      <path
        d="M94.3628 69.1179C94.3404 69.1179 94.318 69.1131 94.2968 69.1022C94.2242 69.0659 94.1951 68.9775 94.2315 68.9049C94.6256 68.1179 94.5438 60.8208 94.4784 58.0755L94.476 57.975L94.5687 57.9362C99.9134 55.6861 98.8559 49.9575 98.7003 49.242C97.3128 48.6669 94.6213 44.8004 94.4918 44.5153C94.3707 44.2489 94.3749 42.997 94.4191 41.1985C94.424 40.9896 94.4282 40.8364 94.4282 40.7717C94.4282 40.6906 94.4942 40.6246 94.5753 40.6246C94.6564 40.6246 94.7224 40.6906 94.7224 40.7717C94.7224 40.8377 94.7188 40.9932 94.7133 41.2057C94.6958 41.9297 94.6407 44.1339 94.7593 44.393C94.8943 44.689 97.6572 48.587 98.8728 48.9919L98.9485 49.0174L98.9684 49.0949C98.9854 49.1602 100.574 55.6504 94.7745 58.1675C94.7993 59.2686 94.9791 68.0652 94.4942 69.0356C94.4682 69.0871 94.4167 69.1167 94.3622 69.1167L94.3628 69.1179Z"
        fill="#F7EEB4"
      />
      <path
        d="M94.3628 69.1179C94.3404 69.1179 94.318 69.1131 94.2968 69.1022C94.2242 69.0659 94.1951 68.9775 94.2315 68.9049C94.6256 68.1179 94.5438 60.8208 94.4784 58.0755L94.476 57.975L94.5687 57.9362C99.9134 55.6861 98.8559 49.9575 98.7003 49.242C97.3128 48.6669 94.6213 44.8004 94.4918 44.5153C94.3707 44.2489 94.3749 42.997 94.4191 41.1985C94.424 40.9896 94.4282 40.8364 94.4282 40.7717C94.4282 40.6906 94.4942 40.6246 94.5753 40.6246C94.6564 40.6246 94.7224 40.6906 94.7224 40.7717C94.7224 40.8377 94.7188 40.9932 94.7133 41.2057C94.6958 41.9297 94.6407 44.1339 94.7593 44.393C94.8943 44.689 97.6572 48.587 98.8728 48.9919L98.9485 49.0174L98.9684 49.0949C98.9854 49.1602 100.574 55.6504 94.7745 58.1675C94.7993 59.2686 94.9791 68.0652 94.4942 69.0356C94.4682 69.0871 94.4167 69.1167 94.3622 69.1167L94.3628 69.1179Z"
        fill="#E6D98A"
      />
      <path
        d="M69.5052 62.5164C71.4042 57.2322 72.8147 50.0762 73.2682 46.9046C74.0406 41.4963 72.9594 38.5603 70.1778 38.2509C70.1778 38.2509 67.0874 37.1691 65.8512 46.1316C64.836 53.4922 62.1567 63.7659 63.4558 67.4617C63.7391 68.584 64.4607 69.4654 64.4607 69.4654L88.567 69.3111V66.5294C77.9942 62.9777 72.4128 62.3409 69.5052 62.5164Z"
        fill="#F7EEB4"
      />
      <path
        d="M99.7754 69.2251C99.7766 69.1888 99.7821 69.1531 99.7821 69.1161C99.7821 67.1959 97.966 65.6389 95.7255 65.6389C93.485 65.6389 91.6689 67.1959 91.6689 69.1161C91.6689 69.1531 91.6738 69.1888 91.675 69.2251H99.7754Z"
        fill="#A793F7"
      />
      <path
        d="M88.5664 66.5294L92.4698 65.0602C92.4698 65.0602 96.8272 64.9446 97.8116 66.3351C97.8116 66.3351 94.7187 66.2195 94.157 66.4507C93.5946 66.6826 91.9008 68.0804 91.7676 68.8849C91.6344 69.6895 88.567 69.3111 88.567 69.3111C88.567 69.3111 88.7395 66.1444 88.5664 66.5294Z"
        fill="#5388FF"
      />
      <path
        d="M78.3278 34.0363C78.3278 34.0363 75.2295 35.4075 75.2295 36.4045C75.2295 37.4015 80.3933 39.1468 80.3933 39.1468L78.9731 40.1439L86.0734 41.7644C86.0734 41.7644 78.4573 35.4075 78.3278 34.0363Z"
        fill="#E6D98A"
      />
      <path
        d="M87.9432 33.9116C87.9432 33.9116 89.8131 34.5351 89.8131 35.6569C89.8131 36.7786 87.8191 38.7733 87.8191 38.7733L89.0655 38.898L85.9491 41.6403L85.3262 41.1415L87.9438 36.9033V33.9116H87.9432Z"
        fill="#E6D98A"
      />
      <path
        d="M99.6092 17.8525C99.4663 17.2804 98.5256 16.709 98.3955 16.6327C98.7732 15.5733 98.9808 14.4328 98.9808 13.2432C98.9808 7.67265 94.4648 3.15662 88.8942 3.15662C83.3237 3.15662 78.8076 7.67265 78.8076 13.2432C78.8076 14.8444 79.1823 16.3578 79.8464 17.7024C79.5104 17.9875 79.3228 18.16 79.3228 18.16C81.013 25.2295 87.3142 29.8393 87.3142 29.8393L92.3854 30.1469C98.0716 26.3052 99.7618 18.4675 99.608 17.8525H99.6092Z"
        fill="#FFC3BD"
      />
      <path
        d="M88.4425 36.1551C88.4425 36.1551 88.2409 36.1097 87.8819 36.0431V28.651C87.8819 27.2314 81.3155 16.9384 78.8311 20.8424C78.8311 20.8424 79.1737 33.8904 78.1706 35.8318C78.0211 35.8567 77.8716 35.8772 77.7227 35.9057L78.1216 35.9269C78.1216 35.9269 78.3571 36.1273 78.776 36.4039L85.4508 41.2662L88.4425 36.1557V36.1551Z"
        fill="#FFC3BD"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.51">
        <path
          d="M92.3868 30.1469L87.7884 29.9314L87.9434 36.904L85.5068 40.874C85.5068 40.874 85.5068 30.2746 84.8445 29.9804C84.1822 29.6862 80.5755 28.4349 80.5755 28.4349L80.2812 23.7239C80.2812 23.7239 81.3854 27.3307 82.1216 27.404C82.8577 27.4778 92.3874 30.1469 92.3874 30.1469H92.3868Z"
          fill="#FFC3BD"
        />
      </g>
      <path
        d="M95.1896 22.4944C94.7659 22.6191 92.73 23.5235 91.3637 23.311C91.2354 23.2911 91.1343 23.2753 91.0459 23.2614C91.1095 23.3546 92.6301 25.5249 95.7332 23.5623C95.7332 23.5623 95.6134 22.3703 95.1896 22.4944Z"
        fill="#EA312C"
      />
      <path
        d="M91.0068 23.2142C91.0068 23.2142 93.2267 28.3029 95.5967 25.7816C95.5967 25.7816 95.9369 24.9026 95.0325 24.7246C94.1275 24.5467 92.306 24.8887 91.0068 23.2142Z"
        fill="#EA312C"
      />
      <path
        d="M91.5273 23.713C91.5273 23.713 93.4336 24.6465 95.2425 23.6155C95.2425 23.6155 95.3593 24.4128 95.145 25.1132C95.145 25.1132 92.2471 25.3075 91.5273 23.713Z"
        fill="#FCFCFF"
      />
      <path
        d="M90.6463 14.7288C91.8528 15.0515 92.6409 16.0243 92.4061 16.9021C92.1718 17.7798 91.0028 18.2296 89.7963 17.907C88.5898 17.5843 87.8016 16.6115 88.0365 15.7337C88.2714 14.8559 89.4398 14.4061 90.6463 14.7288Z"
        fill="#FCFCFF"
      />
      <path
        d="M92.3259 15.8723C92.5928 16.669 92.3906 17.4554 91.8737 17.6285C91.3567 17.8016 90.7216 17.2962 90.4547 16.4995C90.1877 15.7028 90.3899 14.9165 90.9069 14.7433C91.4239 14.5702 92.0589 15.0757 92.3259 15.8723Z"
        fill="#3A6FE6"
      />
      <path
        d="M92.5608 16.7616C92.5608 16.7616 92.3362 14.1016 89.8638 14.1876C87.3915 14.2735 87.3389 15.7549 87.3389 15.7549C87.3389 15.7549 91.433 15.1053 92.5614 16.7616H92.5608Z"
        fill="#E66762"
      />
      <path
        d="M92.5606 16.7616C92.5606 16.7616 90.934 15.4032 87.6723 15.9952C87.6723 15.9952 86.1613 16.1242 85.749 15.9087C85.749 15.9087 87.2467 15.7561 87.731 15.632C88.2153 15.5079 91.4661 14.664 92.5606 16.7616Z"
        fill="#3A6FE6"
      />
      <path
        d="M96.9238 14.4564C96.2615 15.1459 95.983 16.3185 96.3021 17.0764C96.6211 17.8337 97.4165 17.8888 98.0788 17.1999C98.7411 16.511 99.0196 15.3378 98.7011 14.5799C98.3821 13.8226 97.5867 13.7675 96.9244 14.4564H96.9238Z"
        fill="#FCFCFF"
      />
      <path
        d="M97.5802 15.5866C97.4773 16.4208 97.6728 17.1309 98.0166 17.1733C98.3605 17.2157 98.7231 16.574 98.826 15.7404C98.9289 14.9068 98.7334 14.1961 98.3896 14.1537C98.0457 14.1113 97.6831 14.753 97.5802 15.5866Z"
        fill="#3A6FE6"
      />
      <path
        d="M96.1795 16.9923C96.1795 16.9923 95.7775 14.3989 97.2855 13.6942C98.794 12.9896 99.1252 14.3777 99.1252 14.3777C99.1252 14.3777 96.5251 15.0636 96.1795 16.9923Z"
        fill="#E66762"
      />
      <path
        d="M96.1797 16.9922C96.1797 16.9922 96.8861 15.187 98.9728 14.7118C98.9728 14.7118 99.91 14.354 100.115 14.0187C100.115 14.0187 99.1811 14.3498 98.8639 14.3861C98.5467 14.4225 96.4158 14.6555 96.1797 16.9922Z"
        fill="#3A6FE6"
      />
      <path
        d="M86.6915 14.4982C86.9754 13.9298 87.4506 13.4981 87.9579 13.1991C88.4664 12.8891 89.0385 12.7269 89.613 12.6555C90.1899 12.564 90.7711 12.6645 91.354 12.6343C91.647 12.644 91.943 12.6373 92.2445 12.6282C92.5436 12.6379 92.8499 12.6222 93.1683 12.5822L93.184 12.5096C92.6307 12.1796 92.0272 11.9908 91.4049 11.8915C90.7783 11.8092 90.1378 11.9042 89.5198 12.0701C88.9023 12.225 88.2939 12.4805 87.7757 12.8861C87.2696 13.3026 86.8392 13.8359 86.6279 14.474L86.6927 14.4988L86.6915 14.4982Z"
        fill="#3A6FE6"
      />
      <path
        d="M99.0116 12.4326C98.9983 12.3721 98.9886 12.3128 98.991 12.251C98.9831 12.2256 98.9747 12.2002 98.968 12.1735C98.9589 12.1378 98.965 12.1076 98.962 12.0737C98.4335 11.8878 97.8856 11.7613 97.3329 11.774C96.7039 11.8061 96.0828 11.9296 95.498 12.1984L95.5059 12.2722C95.8177 12.3461 96.1204 12.3945 96.4194 12.4169C96.7179 12.4575 97.0115 12.4962 97.3039 12.518C97.8735 12.6088 98.4553 12.5749 99.0134 12.7214C99.0098 12.6827 98.9995 12.6451 98.9995 12.6064C98.9995 12.5483 99.0067 12.4908 99.0128 12.4326H99.0116Z"
        fill="#3A6FE6"
      />
      <path
        d="M96.8209 19.3629C96.6357 19.1256 96.5019 18.8422 96.3705 18.542C96.106 17.9439 95.8838 17.3101 95.6477 16.6799C95.5714 16.4716 95.4927 16.2634 95.4153 16.0551C95.296 16.0503 95.1755 16.0412 95.0593 16.0279C95.1041 16.2894 95.1507 16.5509 95.2094 16.81C95.359 17.4777 95.5606 18.134 95.833 18.7799C95.9813 19.0941 96.1423 19.4282 96.3899 19.7158C96.4668 19.8084 96.4868 19.8611 96.4783 19.9131C96.465 19.9773 96.3602 20.0754 96.2513 20.1577C96.0043 20.3115 95.6931 20.4247 95.3789 20.4894C95.069 20.5421 94.7167 20.6226 94.4346 20.4253C94.5163 20.5899 94.7076 20.6577 94.8747 20.7104C95.0478 20.7564 95.2282 20.7764 95.4062 20.777C95.7633 20.774 96.1229 20.7225 96.4753 20.5585C96.6454 20.4598 96.8415 20.3611 96.9583 20.102C97.0758 19.8266 96.9553 19.5299 96.8221 19.3629H96.8209Z"
        fill="#FFC3BD"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.51">
        <path
          d="M96.8209 19.3629C96.6357 19.1256 96.5019 18.8422 96.3705 18.542C96.106 17.9439 95.8838 17.3101 95.6477 16.6799C95.5714 16.4716 95.4927 16.2634 95.4153 16.0551C95.296 16.0503 95.1755 16.0412 95.0593 16.0279C95.1041 16.2894 95.1507 16.5509 95.2094 16.81C95.359 17.4777 95.5606 18.134 95.833 18.7799C95.9813 19.0941 96.1423 19.4282 96.3899 19.7158C96.4668 19.8084 96.4868 19.8611 96.4783 19.9131C96.465 19.9773 96.3602 20.0754 96.2513 20.1577C96.0043 20.3115 95.6931 20.4247 95.3789 20.4894C95.069 20.5421 94.7167 20.6226 94.4346 20.4253C94.5163 20.5899 94.7076 20.6577 94.8747 20.7104C95.0478 20.7564 95.2282 20.7764 95.4062 20.777C95.7633 20.774 96.1229 20.7225 96.4753 20.5585C96.6454 20.4598 96.8415 20.3611 96.9583 20.102C97.0758 19.8266 96.9553 19.5299 96.8221 19.3629H96.8209Z"
          fill="#FFC3BD"
        />
      </g>
      <path
        d="M80.7292 18.1951C81.1149 19.8586 80.4247 21.4393 79.1874 21.7262C77.9506 22.0131 76.6351 20.8968 76.2495 19.2333C75.8639 17.5698 76.554 15.9891 77.7914 15.7022C79.0281 15.4153 80.3436 16.5315 80.7292 18.1951Z"
        fill="#FFC3BD"
      />
      <path
        d="M77.7754 15.1471C77.7754 15.1471 80.4705 15.3057 80.7877 21.1717C80.7877 21.1717 82.8489 22.1227 82.3731 16.7326C81.8973 11.3418 82.6903 6.58541 87.4467 7.53704C92.2031 8.48807 100.448 9.1225 100.923 4.36613C100.923 4.36613 100.765 0.402197 97.9111 0.243591C97.9111 0.243591 99.6551 2.62207 97.4352 2.78007C95.2154 2.93868 76.1899 -4.83059 77.7754 15.1471Z"
        fill="black"
      />
      <path
        d="M76.2266 19.1764C76.2266 21.8339 77.2514 23.989 78.5154 23.989C79.7795 23.989 80.8043 21.8345 80.8043 19.1764C80.8043 16.5182 79.7795 14.3643 78.5154 14.3643C77.2514 14.3643 76.2266 16.5188 76.2266 19.1764Z"
        fill="#FCFCFF"
      />
      <path
        opacity="0.58"
        d="M78.2503 22.6137C79.1534 22.6137 79.8854 21.0748 79.8854 19.1764C79.8854 17.2781 79.1534 15.7391 78.2503 15.7391C77.3473 15.7391 76.6152 17.2781 76.6152 19.1764C76.6152 21.0748 77.3473 22.6137 78.2503 22.6137Z"
        fill="#B88DF1"
      />
      <path
        d="M83.4952 28.5711C84.2676 28.5711 85.1164 28.4809 86.0402 28.2987C86.2393 28.2599 86.3725 28.0426 86.3386 27.8138C86.3047 27.5849 86.114 27.4312 85.9173 27.4705C83.0297 28.0377 80.9714 27.6842 79.7982 26.4184C78.6287 25.1562 78.7764 23.3643 78.7782 23.3468C78.7994 23.1161 78.6547 22.9091 78.4543 22.8843C78.2509 22.86 78.0747 23.0247 78.0523 23.2547C78.0439 23.3443 77.8574 25.4661 79.2897 27.0213C80.2389 28.0529 81.6482 28.5705 83.494 28.5705L83.4952 28.5711Z"
        fill="#FCFCFF"
      />
      <path
        d="M85.1826 27.999C85.1826 28.9482 85.8509 29.7176 86.6755 29.7176C87.5 29.7176 88.1683 28.9482 88.1683 27.999C88.1683 27.0498 87.5 26.2803 86.6755 26.2803C85.8509 26.2803 85.1826 27.0498 85.1826 27.999Z"
        fill="#FCFCFF"
      />
      <path
        d="M79.0776 15.7422C79.2531 15.7228 79.3954 15.5575 79.4087 15.3444C79.6811 10.9035 81.0438 7.55943 83.4592 5.40493C88.109 1.25636 95.1682 2.86119 95.2384 2.87814C95.437 2.92535 95.6319 2.77401 95.6713 2.53913C95.7106 2.30485 95.5811 2.07663 95.3813 2.02941C95.0792 1.95798 87.9238 0.333779 83.0125 4.71481C80.4221 7.0261 78.9626 10.5802 78.6738 15.2785C78.6593 15.5164 78.8118 15.7246 79.0146 15.7428C79.0358 15.7446 79.057 15.7446 79.0776 15.7422Z"
        fill="#FCFCFF"
      />
      <path
        d="M206.784 69.1252H28.0779C26.9065 69.1252 25.9482 70.0835 25.9482 71.2549C25.9482 72.4262 26.9065 73.3845 28.0779 73.3845H31.2724V86.6941H35.0275V158.26C35.0275 159.294 35.8732 160.14 36.9072 160.14C37.9412 160.14 38.7869 159.294 38.7869 158.26V86.6941H50.0654V135.209C50.0654 136.169 50.8512 136.955 51.8113 136.955H52.0783C53.0384 136.955 53.8241 136.169 53.8241 135.209V86.6941H182.901V135.209C182.901 136.169 183.687 136.955 184.647 136.955H184.914C185.874 136.955 186.66 136.169 186.66 135.209V86.6941H197.938V158.26C197.938 159.294 198.784 160.14 199.818 160.14C200.852 160.14 201.698 159.294 201.698 158.26V86.6941H205.895V73.3845H206.783C207.954 73.3845 208.912 72.4262 208.912 71.2549C208.912 70.0835 207.954 69.1252 206.783 69.1252H206.784Z"
        fill="#4A5353"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.49">
        <path
          d="M205.897 72.8518H31.2725V78.4702H205.897V72.8518Z"
          fill="#4A5353"
        />
      </g>
    </svg>
  );
};
