import axios, { AxiosRequestConfig } from 'axios';
import { apiClient } from './apiClient';
import { API_ROUTES } from '../utils';
import { addRestQueryParams } from '../utils/helper';

export interface IGetCallsListRequest {
  offset: number;
  limit: number;
  q: string;
  reviewed: boolean | undefined;
}

export interface IGetPresignedUrlRequest {
  name: string;
  language: string;
}
export interface IGetCallsAuditRequest {
  startDate: Date;
  endDate: Date;
}

const getCallsList = async (props: IGetCallsListRequest) => {
  return apiClient
    .get(addRestQueryParams(API_ROUTES.CALLS_LIST, props as any))
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};
const getPresignedUrl = async (
  props: IGetPresignedUrlRequest,
): Promise<any> => {
  return apiClient
    .post(API_ROUTES.PRESIGNED_URL, props)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

const uploadFile = async (
  reqObject: any,
  url: string,
  config?: AxiosRequestConfig,
) => {
  await axios.put(url, reqObject, {
    ...config,
    headers: {
      'Content-Type': reqObject.type,
    },
  });
};

const getCallAuditInsights = async (
  props: IGetCallsAuditRequest,
): Promise<any> => {
  return apiClient
    .post(API_ROUTES.CALL_INSIGHTS, props)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
};

export const callAuditPageService = {
  getCallsList,
  getPresignedUrl,
  uploadFile,
  getCallAuditInsights,
};
