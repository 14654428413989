import React from 'react';
import { IconsProps } from './Icons';
export const DangerIcon = ({
  className,
  fill,
  width,
  height,
  viewBox,
}: IconsProps) => {
  return (
    <svg 
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox={viewBox ? viewBox : '0 0 54 54'}
    width={width ? width : '54'}
    height={height ? height : '54'}
    fill={fill ? fill : 'none'}
    
    >
<ellipse cx="27" cy="27" rx="27" ry="26.5" fill="#FC5A5A"/>
<path d="M25.8347 24.6665H28.168V30.4998H25.8347V24.6665ZM25.8335 31.6665H28.1668V33.9998H25.8335V31.6665Z" fill="white"/>
<path d="M29.0628 17.9001C28.6568 17.136 27.8658 16.6611 27.0001 16.6611C26.1344 16.6611 25.3434 17.136 24.9374 17.9013L16.3764 34.0748C16.1865 34.43 16.0924 34.8285 16.1034 35.2312C16.1145 35.6338 16.2303 36.0266 16.4394 36.3708C16.6456 36.7166 16.9383 37.0026 17.2888 37.2007C17.6393 37.3987 18.0354 37.5019 18.4379 37.5001H35.5623C36.3883 37.5001 37.1361 37.0778 37.5619 36.3708C37.7708 36.0265 37.8864 35.6337 37.8974 35.2311C37.9085 34.8286 37.8146 34.4301 37.6249 34.0748L29.0628 17.9001ZM18.4379 35.1668L27.0001 18.9933L35.5681 35.1668H18.4379Z" fill="white"/>
</svg>
  );
};
