import HeroBanner from '../../../assets/images/LandingPage/login-new.png';
import HeroBannerMobile from '../../../assets/images/LandingPage/hero-banner-mobile.png';
import HeroBannerBg from '../../../assets/images/LandingPage/hero-banner-bg.png';
import OneImg from '../../../assets/images/LandingPage/hero-banner-anim/one.png';
import TwoImg from '../../../assets/images/LandingPage/hero-banner-anim/two.png';
import ThreeImg from '../../../assets/images/LandingPage/hero-banner-anim/three.png';
import FourImg from '../../../assets/images/LandingPage/hero-banner-anim/four.png';
import FiveImg from '../../../assets/images/LandingPage/hero-banner-anim/five.png';
import SixImg from '../../../assets/images/LandingPage/hero-banner-anim/six.png';
import SevenImg from '../../../assets/images/LandingPage/hero-banner-anim/seven.png';

export function HomeHeroBlock() {
  return (
    <section className="py-12 lg:py-20 relative">
      <div className="absolute z-[-1] inset-0">
        <img
          src={HeroBannerBg}
          className="object-cover size-full"
          alt="hero background image"
        />
      </div>
      <div className="container">
        <div className="md:f-ic space-y-10 md:space-y-0">
          <div className="flex-1">
            <div className="space-y-8 text-center md:text-left">
              <h1 className="h1-b text-blue-darkest leading-snug">
                Enhancing Contact Centers Via{' '}
                <span className=" text-pink-500">
                  Conversational Intelligence
                </span>
              </h1>
              <p className="body-l leading-relaxed text-grey-dark-100 xl:max-w-[600px]">
                Witness a transformation in quality assessment. Unleash the
                potential of your Medium and Large Contact Centers with our
                top-notch Quality Assurance Solution.
              </p>
              <a href={undefined} className="lp-btn cursor-pointer">
                Start Free Trial
              </a>
            </div>
          </div>
          <div className="flex-1 inline-flex justify-center">
            <div className="relative">
              <picture>
                <source srcSet={HeroBanner} media="(min-width: 768px)" />
                <source srcSet={HeroBannerMobile} media="(min-width: 320px)" />
                <img
                  className="md:w-[300px] md:h-[320px] lg:w-[340px] lg:h-[360px] xl:w-[400px] xl:h-[410px]"
                  src={HeroBannerMobile}
                  alt="hero image"
                />
              </picture>
              <div className="img-blk img-one">
                <img src={OneImg} className="max-w-[140px] xl:max-w-[200px]" />
              </div>
              <div className="img-blk img-two">
                <img src={TwoImg} className="max-w-[140px] xl:max-w-[156px]" />
              </div>
              <div className="img-blk img-three">
                <img
                  src={ThreeImg}
                  className="max-w-[100px] xl:max-w-[170px]"
                />
              </div>
              <div className="img-blk img-four">
                <img src={FourImg} className="max-w-[140px] xl:max-w-[187px]" />
              </div>
              <div className="img-blk img-five">
                <img src={FiveImg} className="max-w-[140px] xl:max-w-[187px]" />
              </div>
              <div className="img-blk img-six">
                <img src={SixImg} className="max-w-[140px] xl:max-w-[187px]" />
              </div>
              <div className="img-blk img-seven">
                <img
                  src={SevenImg}
                  className=" max-w-[80px] lg:max-w-[120px] xl:max-w-[187px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
