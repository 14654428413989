export const PAGE_URL = {
  LOGIN: '/login',
  HOME: '/',
  WHYUS: '/whyus',
  SKILLDIFYANALYSIS: '/skilldify-analysis',
  SKILLDIFYLEARNING: '/skilldify-learning',
  SKILLDIFYQA: '/skilldify-qa',
  AGENTPERFORMANCE: '/assessing-agent-performance',
  ELEVATINGCUSTOMEREXPERIENCES: '/elevating-customer-experiences',
  EXPANDQA: '/expand-qa',
  INTUITIVEDASHBOARD: '/intuitive-dashboard',
  DASHBOARD: '/dashboard',
  CALL_AUDIT: {
    LIST_PAGE: '/call-audit',
    DETAIL_PAGE: '/call-audit/:audioId',
  },
};

export const API_ROUTES = {
  CALLS_LIST: 'call-audit',
  PRESIGNED_URL: 'presigned-url/upload',
  CALL_INSIGHTS: 'call-audit/insights',
};

export const VALIDATION_MESSAGES = {
  LOGIN: {
    INVALID_DETAILS: "Username or password don't match with an account",
  },
};

export const PAGINATION_CONSTANTS = {
  DEFAULT: {
    PAGE: 0,
    PAGESIZE: 10,
  },
};
