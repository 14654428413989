import React, { useRef } from 'react';

interface IProps {
  src: string;
  setTimeCallBack: (value: number) => void;
}

export function AudioPlayer({ src, setTimeCallBack }: IProps) {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handleTimeUpdate = () => {
    if (audioRef.current?.currentTime)
      setTimeCallBack(audioRef.current.currentTime * 1000);
  };

  return (
    <div>
      <audio
        className='w-full'
        controls
        src={src}
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
      />
    </div>
  );
}
