import React from 'react';
import { UserAvatar, UserAvatar2 } from '../../../assets/images/';
import { BlockQuoteIcon } from '../../../assets/icons/BlockquoteIcon';
import WithStyles from '@mui/material/styles/withStyles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export function HomeTestimonials() {
  return (
    <section className="bg-white py-12 lg:py-24">
      <div className="mb-10 lg:mb-14">
        <div className="relative text-center">
          <h3 className="h3-b text-black-dark capitalize">
            Kind words from online industry leaders
          </h3>
        </div>
      </div>
      <div className="mt-32 container grid grid-cols-1 md:grid-cols-2 gap-[120px] md:gap-14">
        <figure className="flex flex-col items-center p-8 pt-32 text-center rounded-2xl relative shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] bg-white-light">
          <blockquote className="mx-auto max-w-2xl text-grey-dark-200 border border-[#D7E3FF] px-4 py-6 rounded-xl    ">
            <p className="overline-l">
              "I am completely blown away. Your company is truly upstanding and
              is behind its product 100%. Paycash should be nominated for
              service of the year."
            </p>
          </blockquote>
          <figcaption className="flex flex-col justify-center items-center gap-4 absolute -top-10">
            <img
              className=" size-[74px] rounded-full"
              src={UserAvatar}
              alt="profile picture"
            />
            <div className="space-y-0.5 text-center">
              <h5 className="body-b text-blue-light">John Doe</h5>
              <p className="text-grey-dark-200 para-l">Larana Founder</p>
            </div>
          </figcaption>
        </figure>
        <figure className="flex flex-col items-center p-8 pt-32 text-center rounded-2xl relative shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] bg-white-light">
          <blockquote className="mx-auto max-w-2xl text-grey-dark-200 border border-[#D7E3FF] px-4 py-6 rounded-xl    ">
            <p className="overline-l">
              "It's really wonderful. Absolutely wonderful! I strongly recommend
              Paycash to everyone interested in running a successful online
              business!."
            </p>
          </blockquote>
          <figcaption className="flex flex-col justify-center items-center gap-4 absolute -top-10">
            <img
              className=" size-[74px] rounded-full"
              src={UserAvatar2}
              alt="profile picture"
            />
            <div className="space-y-0.5 text-center">
              <h5 className="body-b text-blue-light">Larissa May</h5>
              <p className="text-grey-dark-200 para-l">Fauger Founder</p>
            </div>
          </figcaption>
        </figure>
        {/* <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode
          className=""
          containerClass="testimonial-slider"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass="testimonial-slider-item px-8 py-24 group"
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 1,
            },
            mobile: {
              breakpoint: {
                max: 768,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 768,
              },
              items: 2,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          sliderClass="testimonials-slider-item"
          slidesToSlide={1}
          swipeable
        >
          <figure className="flex flex-col items-center p-8 pt-24 group-[.react-multi-carousel-item--active]:pt-32 text-center rounded-2xl relative shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] bg-white-light">
            <blockquote className="mx-auto max-w-2xl text-grey-dark-200 border border-[#D7E3FF] px-4 py-6 rounded-xl    ">
              <p className="para-l group-[.react-multi-carousel-item--active]:overline-l">
                Shivangi recently delivered a Talent Transformation session
                (Campus to Corporate session along with Soft skills session) at
                NEP India. She is an ingenious trainer. She can quickly
                understand the problem and knows how to make others understand
                through role plays and other activities. She knows her targets
                and can show you post results promptly. She knows her job very
                well. All the best!
              </p>
            </blockquote>
            <figcaption className="flex flex-col justify-center items-center gap-4 absolute translate-y-1/2 bottom-full">
              <img
                className=" size-[74px] rounded-full group-[.react-multi-carousel-item--active]:size-[114px]"
                src={UserAvatar}
                alt="profile picture"
              />
              <div className="space-y-0.5 text-center">
                <h5 className="body-b text-blue-light">Namrata Negi</h5>
                <p className="text-grey-dark-200 para-l">
                  HR Manager at NEP Group, Inc.
                </p>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col items-center p-8 pt-24 group-[.react-multi-carousel-item--active]:pt-32 text-center rounded-2xl relative shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] bg-white-light">
            <blockquote className="mx-auto max-w-2xl text-grey-dark-200 border border-[#D7E3FF] px-4 py-6 rounded-xl    ">
              <p className="para-l group-[.react-multi-carousel-item--active]:overline-l">
                Shivangi recently delivered a Talent Transformation session
                (Campus to Corporate session along with Soft skills session) at
                NEP India. She is an ingenious trainer. She can quickly
                understand the problem and knows how to make others understand
                through role plays and other activities. She knows her targets
                and can show you post results promptly. She knows her job very
                well. All the best!
              </p>
            </blockquote>
            <figcaption className="flex flex-col justify-center items-center gap-4 absolute translate-y-1/2 bottom-full">
              <img
                className=" size-[74px] rounded-full group-[.react-multi-carousel-item--active]:size-[114px]"
                src={UserAvatar}
                alt="profile picture"
              />
              <div className="space-y-0.5 text-center">
                <h5 className="body-b text-blue-light">Namrata Negi</h5>
                <p className="text-grey-dark-200 para-l">
                  HR Manager at NEP Group, Inc.
                </p>
              </div>
            </figcaption>
          </figure>
          <figure className="flex flex-col items-center p-8 pt-24 group-[.react-multi-carousel-item--active]:pt-32 text-center rounded-2xl relative shadow-[1px_8px_33px_rgb(55_102_169_/_10%)] bg-white-light">
            <blockquote className="mx-auto max-w-2xl text-grey-dark-200 border border-[#D7E3FF] px-4 py-6 rounded-xl    ">
              <p className="para-l group-[.react-multi-carousel-item--active]:overline-l">
                Shivangi recently delivered a Talent Transformation session
                (Campus to Corporate session along with Soft skills session) at
                NEP India. She is an ingenious trainer. She can quickly
                understand the problem and knows how to make others understand
                through role plays and other activities. She knows her targets
                and can show you post results promptly. She knows her job very
                well. All the best!
              </p>
            </blockquote>
            <figcaption className="flex flex-col justify-center items-center gap-4 absolute translate-y-1/2 bottom-full">
              <img
                className=" size-[74px] rounded-full group-[.react-multi-carousel-item--active]:size-[114px]"
                src={UserAvatar}
                alt="profile picture"
              />
              <div className="space-y-0.5 text-center">
                <h5 className="body-b text-blue-light">Namrata Negi</h5>
                <p className="text-grey-dark-200 para-l">
                  HR Manager at NEP Group, Inc.
                </p>
              </div>
            </figcaption>
          </figure>
        </Carousel> */}
      </div>
    </section>
  );
}
