import React from 'react';
import { Box } from '@mui/material';
import { CrossIcon } from '../../assets/icons';
import { useGlobalContext } from '../../contexts/useGlobalContext';
import { FileUploader } from '../file-uploader/FileUploader';
import { convertToBytes } from '../../utils/helper';
import { callAuditPageService } from '../../service/callAuditPage';

export function UploadModal() {
  const { closeModal } = useGlobalContext();
  return (
    <>
      <div className="w-[700px] justify-center items-center flex overflow-x-hidden overflow-y-hidden m-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full h-auto">
          <div className=" border border-grey-light rounded-radius-100 py-7 px-5 shadow-lg relative flex flex-col w-full h-full bg-[#fff] outline-none focus:outline-none">
            <div className="flex items-start justify-between">
              <h3 color="text.secondary" className="text-base font-medium m-0">
                Upload Files
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black cursor-pointer"
                onClick={() => {
                  closeModal();
                }}
              >
                <CrossIcon />
              </button>
            </div>
            <Box>
              <div className="md:pt-6">
                <FileUploader
                  maxFiles={10}
                  canUpload={true}
                  maxSize={convertToBytes(4, 'MB')}
                  accept={{
                    'audio/mp3': ['.mp3'],
                  }}
                  uploadFileHandler={{
                    init: async (file: File, language: string) => {
                      return await callAuditPageService.getPresignedUrl({
                        name: file.name,
                        language,
                      });
                    },
                    process: async ({ file, url, config }: any) => {
                      return await callAuditPageService.uploadFile(file, url, {
                        ...config,
                      });
                    },
                    success: () => {},
                  }}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black-dark/50"></div>
    </>
  );
}
